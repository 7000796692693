import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as uuid from 'uuid';
import { RegisterData } from '../../models/models';
import { VarauksetState } from '../../redux/store';
import { registerAsync } from '../../redux/user';
import * as paths from '../../routerPaths';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import {
  isEqualBuilder,
  minLengthBuilder,
  validate,
  ValidationField,
  validEmailBuilder,
} from '../../validators';
import { ContentContainer } from '../ContentContainer';
import './Register.scss';

interface RegisterRouterProps extends RouteComponentProps {
  prefilEmail?: string;
}

interface RegisterStateProps {
  loggedIn: boolean;
}

interface RegisterDispatchProps {
  onRegister(loginCredentials: RegisterData): void;
}

interface RegisterProps extends RegisterStateProps, RegisterDispatchProps, RegisterRouterProps {}

interface RegisterFormState {
  email: ValidationField<string>;
  password1: ValidationField<string>;
  password2: ValidationField<string>;
}

interface RegisterState {
  form: RegisterFormState;
}

export class Register extends React.Component<RegisterProps, RegisterState> {
  constructor(props: RegisterProps) {
    super(props);

    const initEmail = props.prefilEmail || '';
    this.state = {
      form: {
        email: {
          value: initEmail,
          validationResult: validate(initEmail, [validEmailBuilder(true)]),
          touched: false,
        },
        password1: { value: '', validationResult: { valid: false }, touched: false },
        password2: { value: '', validationResult: { valid: false }, touched: false },
      },
    };

    this.handleRegisterSubmit = this.handleRegisterSubmit.bind(this);
    this.handleLoginNavigateClick = this.handleLoginNavigateClick.bind(this);
  }

  componentDidMount() {
    this.redirectIfNeeded(this.props);
  }

  componentWillReceiveProps(newProps: RegisterProps) {
    this.redirectIfNeeded(newProps);
  }

  redirectIfNeeded(props: RegisterProps) {
    if (props.loggedIn) {
      props.history.push(paths.loginUrl);
    }
  }

  handleEmailChange(email: string) {
    this.setState((prevState: RegisterState) => {
      return {
        form: {
          ...prevState.form,
          email: this.createEmailValidated(email),
        },
      };
    });
  }

  handlePassword1Change(password: string) {
    this.setState((prevState: RegisterState) => {
      return {
        form: {
          ...prevState.form,
          password1: this.createPassword1Validated(password),
        },
      };
    });
  }

  handlePassword2Change(password: string) {
    this.setState((prevState: RegisterState) => {
      return {
        form: {
          ...prevState.form,
          password2: this.createPassword2Validated(password),
        },
      };
    });
  }

  createEmailValidated(email: string) {
    return {
      value: email,
      touched: true,
      validationResult: validate(email, [validEmailBuilder(true)]),
    };
  }

  createPassword1Validated(password: string) {
    return {
      value: password,
      touched: true,
      validationResult: validate(password, [minLengthBuilder(6)]),
    };
  }

  createPassword2Validated(password: string) {
    return {
      value: password,
      touched: true,
      validationResult: validate(password, [isEqualBuilder(this.state.form.password1.value)]),
    };
  }

  handleRegisterSubmit(event: React.SyntheticEvent<any>) {
    event.preventDefault();
    this.props.onRegister({
      email: this.state.form.email.value,
      password1: this.state.form.password1.value,
      password2: this.state.form.password1.value,
    });
  }

  handleLoginNavigateClick(event: React.SyntheticEvent<any>) {
    event.preventDefault();
    this.props.history.push(paths.loginUrl);
  }

  render() {
    const form = this.state.form;
    const emailInputClasses = ['form-control'];
    const password1InputClasses = ['form-control'];
    const password2InputClasses = ['form-control'];

    if (form.email.validationResult.valid) {
      emailInputClasses.push('is-valid');
    } else {
      emailInputClasses.push('is-invalid');
    }

    if (form.password1.validationResult.valid) {
      password1InputClasses.push('is-valid');
    } else {
      password1InputClasses.push('is-invalid');
    }

    if (form.password2.validationResult.valid) {
      password2InputClasses.push('is-valid');
    } else {
      password2InputClasses.push('is-invalid');
    }

    const formValid =
      form.email.validationResult.valid &&
      form.password1.validationResult.valid &&
      form.password2.validationResult.valid;

    return (
      <ContentContainer>
        <ScrollToTopOnMount />
        <form id="v-register-form" onSubmit={this.handleRegisterSubmit} noValidate={true}>
          <h2>Rekisteröidy</h2>
          <a href={paths.registerUrl} onClick={this.handleLoginNavigateClick} tabIndex={1}>
            Oletko jo rekisteröitynyt? Kirjaudu sisään.
          </a>

          <hr />
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label htmlFor="register-email">Email</label>
                <input
                  autoFocus={true}
                  tabIndex={2}
                  type="email"
                  className={emailInputClasses.join(' ')}
                  id="register-email"
                  value={this.state.form.email.value}
                  onChange={(e) => this.handleEmailChange(e.target.value)}
                  placeholder="sähkö@posti.fi"
                />
                {form.email.touched &&
                !form.email.validationResult.valid &&
                form.email.validationResult.errorMessages &&
                form.email.validationResult.errorMessages.length ? (
                  <div className="invalid-feedback">
                    <ul>
                      {form.email.validationResult.errorMessages.map((msg) => (
                        <li key={uuid.v4()}>{msg}</li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>

              <div className="form-group">
                <label htmlFor="login-password1">Salasana</label>
                <input
                  tabIndex={2}
                  type="password"
                  className={password1InputClasses.join(' ')}
                  id="login-password1"
                  value={this.state.form.password1.value}
                  onChange={(e) => this.handlePassword1Change(e.target.value)}
                />
                {form.password1.touched &&
                !form.password1.validationResult.valid &&
                form.password1.validationResult.errorMessages &&
                form.password1.validationResult.errorMessages.length ? (
                  <div className="invalid-feedback">
                    <ul>
                      {form.password1.validationResult.errorMessages.map((msg) => (
                        <li key={uuid.v4()}>{msg}</li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>

              <div className="form-group">
                <label htmlFor="login-password2">Salasana uudestaan</label>
                <input
                  tabIndex={3}
                  type="password"
                  className={password2InputClasses.join(' ')}
                  id="login-password2"
                  value={this.state.form.password2.value}
                  onChange={(e) => this.handlePassword2Change(e.target.value)}
                />
                {form.password2.touched &&
                !form.password2.validationResult.valid &&
                form.password2.validationResult.errorMessages &&
                form.password2.validationResult.errorMessages.length ? (
                  <div className="invalid-feedback">
                    <ul>
                      {form.password2.validationResult.errorMessages.map((msg) => (
                        <li key={uuid.v4()}>{msg}</li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-6">
              <p>
                Rekisteröitymällä voit tarkastella kaikkia tekemiäsi varauksia. Voit myös peruuttaa
                tekemäsi varauksen, jos toiminto on sallittu varatulle kohteelle.
              </p>
              <p>
                Jos olet aiemmin varauksia tehdessäsi syöttänyt varaukseen sähköpostiosoitteesi, ja
                rekisteröidyt nyt samalla sähköpostiosoitteella, myös vanhat varaukset yhdistetään
                käyttäjätiliisi.
              </p>
              <p>
                Täytettyäsi rekisteröitymislomakkeen sinun pitää vahvistaa antamasi
                sähköpostiosoite. Saat sähköpostiviestin, jossa on vahvistuslinkki.
              </p>
            </div>
          </div>

          <p className="">
            Rekisteröitymällä hyväksyt{' '}
            <a href={paths.getTermsAbsoluteUrl()} target="_blank" rel="noreferrer">
              käyttöehdot
            </a>
            .
          </p>
          <button
            className="btn btn-primary btn-lg"
            type="submit"
            disabled={!formValid}
            tabIndex={4}
          >
            Rekisteröidy
          </button>
        </form>
      </ContentContainer>
    );
  }
}

const mapStateToProps = (state: VarauksetState): RegisterStateProps => ({
  loggedIn: !!state.token.token,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<VarauksetState, undefined, AnyAction>
): RegisterDispatchProps => ({
  onRegister: (registerData: RegisterData) => dispatch(registerAsync(registerData)),
});

const RegisterConnected = connect(mapStateToProps, mapDispatchToProps)(Register);

export default withRouter((props: RouteComponentProps<any>) => {
  const params = new URLSearchParams(props.location.search);
  return <RegisterConnected {...props} prefilEmail={params.get('email') || undefined} />;
});
