import { faUserShield } from '@fortawesome/free-solid-svg-icons';
import * as joda from 'js-joda';
import * as moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  MyReservations,
  MyReservationsResource,
  Organization,
  RepeatMode,
  Reservation,
  Resource,
} from '../../models/models';
import { fetchMyReservationsAsync } from '../../redux/myReservations';
import { VarauksetState } from '../../redux/store';
import * as paths from '../../routerPaths';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import { isReservationNowPastOrComing, jodaNow, SIMPLE_FI_TIME_FORMATTER } from '../../utils';
import { ContentContainer } from '../ContentContainer';
import FAT from '../FontAwesomeWithTooltip';

interface MyReservationsViewStateProps {
  myReservations?: MyReservations;
}

interface MyReservationsViewDispatchProps {
  onFetchMyReservations(): void;
}

interface MyReservationsViewProps
  extends MyReservationsViewStateProps,
    MyReservationsViewDispatchProps,
    RouteComponentProps {}

interface MyReservationsViewState {
  myReservationsFetchFired: boolean;
}

export class MyReservationsView extends React.Component<
  MyReservationsViewProps,
  MyReservationsViewState
> {
  constructor(props: MyReservationsViewProps) {
    super(props);

    this.state = {
      myReservationsFetchFired: false,
    };

    this.handleReservationClick = this.handleReservationClick.bind(this);
  }

  componentWillMount() {
    this.fetchMyReservationsIfNeeded(this.props);
  }

  componentWillReceiveProps(newProps: MyReservationsViewProps) {
    this.fetchMyReservationsIfNeeded(newProps);
  }

  fetchMyReservationsIfNeeded(props: MyReservationsViewProps) {
    if (!this.state.myReservationsFetchFired) {
      this.setState({ myReservationsFetchFired: true });
      props.onFetchMyReservations();
    }
  }

  handleReservationClick(
    event: React.SyntheticEvent<any>,
    organization: Organization,
    resource: Resource,
    reservation: Reservation
  ) {
    event.preventDefault();
    this.props.history.push(
      paths.getReservationDetailsUrl(organization.slug, resource.id, reservation.id)
    );
  }

  renderReservationContent(reservation: Reservation) {
    let textContent;
    if (!reservation.repeating) {
      textContent = (
        <span>{`${moment
          .utc(reservation.date.toString())
          .format('dddd l')} klo ${reservation.timeRange.start.format(
          SIMPLE_FI_TIME_FORMATTER
        )} - ${reservation.timeRange.end.format(SIMPLE_FI_TIME_FORMATTER)}`}</span>
      );
    } else {
      const repeatModeText =
        reservation.repeatMode === RepeatMode.Biweekly ? 'joka toinen viikko' : 'viikoittain';
      if (!reservation.repeatLastDate) {
        textContent = (
          <span>
            <strong>{repeatModeText}, toistaiseksi</strong>{' '}
            {`${moment
              .utc(reservation.date.toString())
              .format('dddd l')} -> klo ${reservation.timeRange.start.format(
              SIMPLE_FI_TIME_FORMATTER
            )} - ${reservation.timeRange.end.format(SIMPLE_FI_TIME_FORMATTER)}`}
          </span>
        );
      } else {
        textContent = (
          <span>
            <strong>{repeatModeText}</strong>{' '}
            {`${moment.utc(reservation.date.toString()).format('dddd l')} - ${moment
              .utc(reservation.repeatLastDate.toString())
              .format('l')} klo ${reservation.timeRange.start.format(
              SIMPLE_FI_TIME_FORMATTER
            )} - ${reservation.timeRange.end.format(SIMPLE_FI_TIME_FORMATTER)}`}
          </span>
        );
      }
    }

    if (reservation.adminReservation) {
      return (
        <>
          <FAT id="my-reservations-admin" icon={faUserShield}>
            Varattu ylläpitäjän roolissa
          </FAT>{' '}
          {textContent}
        </>
      );
    } else {
      return textContent;
    }
  }

  renderResource(
    organization: Organization,
    resource: Resource,
    reservations: Reservation[],
    now: joda.LocalDateTime
  ) {
    return (
      <div key={resource.id}>
        <h4>
          <Link to={paths.getResourceUrl(organization.slug, resource.id)}>{resource.name}</Link>
        </h4>
        <div className="list-group">
          {reservations.map((r) => {
            const liClassNames = ['list-group-item', 'list-group-item-action'];
            const reservationTimeStatus = isReservationNowPastOrComing(r, now);
            if (reservationTimeStatus === 0) {
              liClassNames.push('list-group-item-primary');
            } else if (reservationTimeStatus === 1) {
              liClassNames.push('list-group-item-success');
            } else {
              liClassNames.push('list-group-item-light');
            }

            return (
              <a
                key={r.id}
                className={liClassNames.join(' ')}
                href={paths.getReservationDetailsUrl(organization.slug, resource.id, r.id)}
                onClick={(e) => this.handleReservationClick(e, organization, resource, r)}
              >
                {this.renderReservationContent(r)}
              </a>
            );
          })}
        </div>
      </div>
    );
  }

  renderOrganization(
    organization: Organization,
    resources: MyReservationsResource[],
    now: joda.LocalDateTime
  ) {
    return (
      <div key={organization.id}>
        <h3>{organization.name}</h3>
        {resources.map((r) => this.renderResource(organization, r.resource, r.reservations, now))}
        <hr />
      </div>
    );
  }

  render() {
    const { myReservations } = this.props;
    const now = jodaNow();
    return (
      <ContentContainer>
        <ScrollToTopOnMount />
        <h2>Omat varaukset</h2>
        <hr />
        <button
          className="btn btn-secondary"
          type="button"
          onClick={this.props.history.goBack}
          tabIndex={1}
        >
          Takaisin
        </button>
        {myReservations && (
          <div style={{ marginTop: '30px' }}>
            {myReservations.organizations.map((org) =>
              this.renderOrganization(org.organization, org.resources, now)
            )}
          </div>
        )}
      </ContentContainer>
    );
  }
}

const mapStateToProps = (state: VarauksetState): MyReservationsViewStateProps => ({
  myReservations: state.myReservations.myReservations,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<VarauksetState, undefined, AnyAction>
): MyReservationsViewDispatchProps => ({
  onFetchMyReservations: () => dispatch(fetchMyReservationsAsync()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyReservationsView));
