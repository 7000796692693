import * as React from 'react';
import { ContentContainer } from '../ContentContainer';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import ReportExport from './ReportExport';

export const Admin: React.FC = (props) => {
  return (
    <ContentContainer>
      <ScrollToTopOnMount />
      <ReportExport />
    </ContentContainer>
  );
};

export default Admin;
