import * as joda from 'js-joda';
import { Action, createAction } from 'redux-actions';
import { CalendarMode, DateRange } from '../models/models';
import { weekRangeForDate, jodaNow, isDateBetween } from '../utils';

// ACTIONS

export enum CalendarAction {
  SET_CALENDAR_MODE = 'varaukset/calendar/SET_CALENDAR_MODE',
  SET_CALENDAR_VISIBLE_DATE_RANGE = 'varaukset/calendar/SET_CALENDAR_DATE_RANGE',
}

export const setCalendarMode = createAction<CalendarMode>(CalendarAction.SET_CALENDAR_MODE);
export const setCalendarVisibleDateRange = createAction<DateRange>(
  CalendarAction.SET_CALENDAR_VISIBLE_DATE_RANGE
);

// REDUCER

export interface CalendarState {
  mode: CalendarMode;
  visibleDateRange: DateRange;
}

const calendarInitialState: CalendarState = {
  mode: CalendarMode.Week,
  visibleDateRange: weekRangeForDate(jodaNow().toLocalDate()),
};

function getVisibleDateRange(
  now: joda.LocalDateTime,
  oldRange: DateRange,
  oldMode: CalendarMode,
  newMode: CalendarMode
): DateRange {
  if (oldMode === newMode) {
    return oldRange;
  } else if (oldMode === CalendarMode.Month && newMode === CalendarMode.Week) {
    const nowDate = now.toLocalDate();
    if (isDateBetween(nowDate, oldRange)) {
      return weekRangeForDate(nowDate);
    } else {
      return weekRangeForDate(oldRange.start);
    }
  } else {
    throw new Error(`Illegal calendar mode change from ${oldMode} to ${newMode}`);
  }
}

export default function calendarReducer(
  state: CalendarState = calendarInitialState,
  action: Action<any>,
  now?: joda.LocalDateTime
): CalendarState {
  const nowDate = now || jodaNow();
  switch (action.type) {
    case CalendarAction.SET_CALENDAR_MODE:
      return {
        ...state,
        visibleDateRange: getVisibleDateRange(
          nowDate,
          state.visibleDateRange,
          state.mode,
          action.payload
        ),
        mode: action.payload,
      };
    case CalendarAction.SET_CALENDAR_VISIBLE_DATE_RANGE:
      return {
        ...state,
        visibleDateRange: action.payload,
      };
    default:
      return state;
  }
}
