import * as React from 'react';
import Hours from './Hours';
import DayHeader from './DayHeader';
import * as joda from 'js-joda';
import { Reservation, TimeRange } from '../../models/models';
import './Day.scss';
import { jodaNow } from '../../utils';

interface DayProps {
  date: joda.LocalDate;
  visibleTimeRange: TimeRange;
  availableTimeRanges?: TimeRange[];
  hourRemHeight: number;
  headerRemHeight: number;
  reservations: Reservation[];
  now?: joda.LocalDateTime;
}

export const Day: React.FC<DayProps> = (props) => {
  const now = props.now || jodaNow();
  const today = now.toLocalDate().equals(props.date);

  return (
    <div>
      <DayHeader date={props.date} remHeight={props.headerRemHeight} />
      <Hours
        className={today ? 'v-day-today' : undefined}
        hoursDate={props.date}
        visibleTimeRange={props.visibleTimeRange}
        availableTimeRanges={props.availableTimeRanges}
        now={now}
        hourRemHeight={props.hourRemHeight}
        reservations={props.reservations}
      />
    </div>
  );
};

export default Day;
