import * as React from 'react';
import { Link } from 'react-router-dom';
import * as paths from '../../routerPaths';

export const MyReservationsLinkSection: React.FC = (props) => {
  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">Omat varaukset</h4>
        <Link to={paths.myReservationsUrl}>Katso kaikki tekemäsi varaukset</Link>
      </div>
    </div>
  );
};

export default MyReservationsLinkSection;
