import { faEyeSlash, faUserShield } from '@fortawesome/free-solid-svg-icons';
import * as joda from 'js-joda';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  EditReservation,
  Organization,
  Reservation,
  ReservationMode,
  Resource,
  Rules,
  User,
} from '../../models/models';
import {
  deleteReservationAndNavigateBackAsync,
  editReservationAndRefetchAsync,
  fetchReservationAsync,
  fetchResourceAsync,
} from '../../redux/reservationDetails';
import { VarauksetState } from '../../redux/store';
import * as paths from '../../routerPaths';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import { formatDuration2, isUserAdmin, jodaNow } from '../../utils';
import {
  squashValidationResults,
  ValidationField,
  ValidationResult,
  validDateRange,
} from '../../validators';
import { ContentContainer } from '../ContentContainer';
import DateInput from '../DateInput';
import FAT from '../FontAwesomeWithTooltip';
import ReservationSummary from '../reserve/ReservationSummary';
import { renderDemoNotification } from '../ResourceDescription';
import './ReservationDetails.scss';

function validateEndDate(
  endDate: joda.LocalDate | undefined,
  oldEndDate: joda.LocalDate | undefined,
  startDate: joda.LocalDate | undefined
): ValidationResult {
  const rangeValidationResult = validDateRange({ start: startDate, end: endDate });
  if (!rangeValidationResult.valid) {
    return rangeValidationResult;
  }
  if (!endDate || !startDate) {
    return { valid: false };
  }
  if (oldEndDate && endDate.isAfter(oldEndDate)) {
    return {
      valid: false,
      errorMessages: [
        'Uuden loppupäivän täytyy olla aiempi kuin alkuperäisen loppupäivän. ' +
          'Jos haluat jatkaa varausta, luo uusi varaus.',
      ],
    };
  }
  const yesterday = jodaNow().minusDays(1).toLocalDate();
  if (endDate.isBefore(yesterday)) {
    return {
      valid: false,
      errorMessages: ['Uusi loppupäivä ei saa olla kaukana menneisyydessä.'],
    };
  }
  return { valid: true };
}

function createEndDateValidated(
  date: joda.LocalDate | undefined,
  reservation: Reservation | undefined
): ValidationField<joda.LocalDate | undefined> {
  if (reservation) {
    return {
      value: date,
      validationResult: validateEndDate(date, reservation.repeatLastDate, reservation.date),
    };
  }
  return { value: date, validationResult: { valid: true } };
}

export function canDeleteReservation(
  rules: Rules,
  userIsOrgAdmin: boolean,
  reservation: Reservation,
  now: joda.LocalDateTime
): ValidationResult {
  if (!userIsOrgAdmin && rules.canDeleteBefore === undefined) {
    return {
      valid: false,
      errorMessages: ['Varausten poistaminen tästä resurssista ei ole sallittu.'],
    };
  }

  const reservationStartDateTime = reservation.date.atTime(reservation.timeRange.start);
  let latestDeleteDateTime = reservationStartDateTime;
  if (!userIsOrgAdmin && rules.canDeleteBefore) {
    latestDeleteDateTime = reservationStartDateTime.minus(rules.canDeleteBefore);
  } else if (userIsOrgAdmin) {
    latestDeleteDateTime = reservation.date.atTime(reservation.timeRange.end);
  }
  if (now.isAfter(latestDeleteDateTime)) {
    if (!userIsOrgAdmin && rules.canDeleteBefore) {
      return {
        valid: false,
        errorMessages: [
          `Varausta ei voi enää poistaa. Tästä resurssista varauksen voi poistaa ${
            rules.canDeleteBefore.toMillis() === 0 ? '' : formatDuration2(rules.canDeleteBefore)
          } ennen varauksen alkua.`,
        ],
      };
    } else {
      return {
        valid: false,
        errorMessages: ['Varausta ei voi enää poistaa.'],
      };
    }
  }

  return { valid: true };
}

function getInitialFormState(reservation: Reservation | undefined) {
  const rawForm: EditForm = {
    repeatLastDate: createEndDateValidated(
      reservation ? reservation.repeatLastDate : undefined,
      reservation
    ),
  };
  return rawForm;
}

interface ReservationDetailsRouterProps extends RouteComponentProps {
  organizationSlug: string;
  resourceId: string;
  reservationId: string;
}

interface ReservationDetailsStateProps {
  organization?: Organization | null;
  resource?: Resource;
  reservation?: Reservation;
  user?: User;
}

interface ReservationDetailsDispatchProps {
  onFetchResource(resourceId: string): void;
  onFetchReservation(reservationId: string): void;
  onDeleteReservation(resource: Resource, reservationId: string): void;
  onEditReservation(reservation: EditReservation): void;
}

interface ReservationDetailsProps
  extends ReservationDetailsRouterProps,
    ReservationDetailsStateProps,
    ReservationDetailsDispatchProps {}

interface EditForm {
  repeatLastDate: ValidationField<joda.LocalDate | undefined>;
}

interface ReservationDetailsState {
  form: EditForm;
  confirmDeleteModalOpen: boolean;
  confirmEditModalOpen: boolean;
}

export class ReservationDetails extends React.Component<
  ReservationDetailsProps,
  ReservationDetailsState
> {
  constructor(props: ReservationDetailsProps) {
    super(props);

    const formState = getInitialFormState(props.reservation);
    this.state = {
      form: formState,
      confirmDeleteModalOpen: false,
      confirmEditModalOpen: false,
    };

    this.handleRepeatLastDateChange = this.handleRepeatLastDateChange.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleOpenDeleteConfirmModal = this.handleOpenDeleteConfirmModal.bind(this);
    this.handleCloseDeleteConfirmModal = this.handleCloseDeleteConfirmModal.bind(this);
    this.handleOpenEditConfirmModal = this.handleOpenEditConfirmModal.bind(this);
    this.handleCloseEditConfirmModal = this.handleCloseEditConfirmModal.bind(this);
    this.handleMyReservationsNavigate = this.handleMyReservationsNavigate.bind(this);
  }

  componentDidMount() {
    this.props.onFetchResource(this.props.resourceId);
    this.props.onFetchReservation(this.props.reservationId);
  }

  componentDidUpdate(prevProps: ReservationDetailsProps) {
    if (!_.isEqual(prevProps, this.props)) {
      if (!this.state.form.repeatLastDate.touched) {
        this.setState((prevState: ReservationDetailsState) => {
          return {
            ...prevState,
            form: getInitialFormState(this.props.reservation),
          };
        });
      }
    }
  }

  handleRepeatLastDateChange(
    date: joda.LocalDate | undefined,
    dateStrValidationResult: ValidationResult
  ) {
    this.setState((prevState: ReservationDetailsState) => {
      const { reservation } = this.props;
      const dateValidationResult = validateEndDate(
        date,
        reservation ? reservation.repeatLastDate : undefined,
        reservation ? reservation.date : undefined
      );
      return {
        ...prevState,
        form: {
          ...prevState.form,
          repeatLastDate: {
            value: date,
            validationResult: squashValidationResults(
              dateStrValidationResult,
              dateValidationResult
            ),
            touched: true,
          },
        },
      };
    });
  }

  handleDeleteClick() {
    if (this.props.resource) {
      this.props.onDeleteReservation(this.props.resource, this.props.reservationId);
    }
  }

  handleEditClick() {
    if (this.state.form.repeatLastDate.value && this.props.reservation) {
      this.props.onEditReservation({
        id: this.props.reservation.id,
        repeatLastDate: this.state.form.repeatLastDate.value,
      });
      this.setState({ confirmEditModalOpen: false });
    }
  }

  handleOpenDeleteConfirmModal() {
    this.setState({ confirmDeleteModalOpen: true });
  }

  handleCloseDeleteConfirmModal() {
    this.setState({ confirmDeleteModalOpen: false });
  }

  handleOpenEditConfirmModal() {
    this.setState({ confirmEditModalOpen: true });
  }

  handleCloseEditConfirmModal() {
    this.setState({ confirmEditModalOpen: false });
  }

  handleMyReservationsNavigate() {
    this.props.history.push(paths.myAccountUrl);
  }

  render() {
    const { resource, reservation, user, organization } = this.props;
    const form = this.state.form;
    let myReservation = false;
    if (reservation && user && reservation.userId === user.id) {
      myReservation = true;
    }
    const admin = isUserAdmin(user, resource && resource.organizationId);

    // Do some sanity checks in case someone edits URLs by hand.
    // Resources and reservations can still be fetched via API without auth. This is just UI level.
    if (resource && reservation) {
      if (resource.id !== reservation.resourceId) {
        this.props.history.goBack();
        return null;
      }
      if (user && !myReservation && !admin) {
        this.props.history.goBack();
        return null;
      }
    }

    let canDeleteValidationResult: ValidationResult = { valid: false };
    if (resource && resource.rules && reservation && organization) {
      canDeleteValidationResult = canDeleteReservation(
        resource.rules,
        admin,
        reservation,
        jodaNow()
      );
    }

    const now = jodaNow();
    let canBeModified = false;
    if (reservation && reservation.repeating) {
      const infinite = !reservation.repeatLastDate;
      const endInFuture =
        reservation.repeatLastDate &&
        reservation.repeatLastDate.atTime(reservation.timeRange.end).isAfter(now);
      canBeModified = infinite || !!endInFuture;
    }

    let tabIndex = 1;

    const undefinedEndDateChanged =
      reservation &&
      reservation.repeatLastDate === undefined &&
      form.repeatLastDate.value !== undefined;
    const explicitEndDateChanged =
      reservation &&
      reservation.repeatLastDate &&
      form.repeatLastDate.value &&
      !reservation.repeatLastDate.isEqual(form.repeatLastDate.value);
    const endDateChanged = undefinedEndDateChanged || explicitEndDateChanged;
    const canBeSaved =
      form.repeatLastDate.touched && form.repeatLastDate.validationResult.valid && endDateChanged;

    return (
      <ContentContainer className="v-reservation-details-container">
        <ScrollToTopOnMount />
        <h2>Varauksen tiedot</h2>
        {resource && resource.reservationMode === ReservationMode.Demo ? (
          <div className="alert alert-info" role="alert">
            {renderDemoNotification()}
          </div>
        ) : null}
        <hr />
        {resource && reservation && (
          <>
            {reservation.adminReservation && (
              <FAT id="reservation-desc-details-admin" icon={faUserShield}>
                Ylläpitäjän tekemä varaus
              </FAT>
            )}
            <ReservationSummary resource={resource} reservation={reservation} />
            <p>
              {`${resource.reservationDescriptionLabel}: ${reservation.description}`}{' '}
              {reservation.descriptionPublic ? (
                ''
              ) : (
                <FAT id="reservation-desc-details-hidden-icon" icon={faEyeSlash}>
                  Tieto piilotettu normaaleilta käyttäjiltä
                </FAT>
              )}
            </p>
            {reservation.totalPricePaid > 0 ? (
              <p>{`Maksettu ${reservation.totalPricePaid.toFixed(2)} €, joka palautetaan${
                myReservation ? '' : ' varaajalle'
              }, jos poistat varauksen.`}</p>
            ) : null}
            <hr />
            <p className="text-danger">
              {canDeleteValidationResult.errorMessages
                ? canDeleteValidationResult.errorMessages.join(', ')
                : null}
            </p>
          </>
        )}
        {canBeModified && (
          <>
            <h4>Muokkaa varausta</h4>
            <label htmlFor="repeatLastDate">Toiston päättymispäivä</label>
            <DateInput
              tabIndex={tabIndex++}
              id="repeatLastDate"
              value={form.repeatLastDate.value}
              valid={
                form.repeatLastDate.touched ? form.repeatLastDate.validationResult.valid : undefined
              }
              onChange={this.handleRepeatLastDateChange}
            />
            {form.repeatLastDate.touched &&
              !form.repeatLastDate.validationResult.valid &&
              form.repeatLastDate.validationResult.errorMessages && (
                <div className="invalid-feedback" style={{ display: 'block' }}>
                  <ul>
                    {form.repeatLastDate.validationResult.errorMessages.map((msg) => (
                      <li key={msg}>{msg}</li>
                    ))}
                  </ul>
                </div>
              )}
          </>
        )}
        <div className="v-reservation-buttons">
          <button
            className="btn btn-secondary"
            type="button"
            onClick={this.props.history.goBack}
            tabIndex={tabIndex++}
          >
            Takaisin
          </button>
          {myReservation && (
            <button
              className="btn btn-secondary d-none d-sm-block"
              type="button"
              onClick={this.handleMyReservationsNavigate}
              tabIndex={tabIndex++}
            >
              Kaikki varaukseni
            </button>
          )}
          <button
            className="btn btn-danger"
            onClick={this.handleOpenDeleteConfirmModal}
            disabled={!canDeleteValidationResult.valid}
            tabIndex={tabIndex++}
          >
            Poista varaus
          </button>
          {canBeModified && (
            <button
              className="btn btn-warning"
              onClick={this.handleOpenEditConfirmModal}
              disabled={!canBeSaved}
              tabIndex={tabIndex++}
            >
              Tallenna muutokset
            </button>
          )}
        </div>
        <Modal
          isOpen={this.state.confirmDeleteModalOpen}
          toggle={this.handleCloseDeleteConfirmModal}
          backdrop="static"
        >
          <ModalHeader toggle={this.handleCloseDeleteConfirmModal}>Poista varaus</ModalHeader>
          <ModalBody>
            <p>Haluatko varmasti poistaa varauksen?</p>
            {this.props.reservation && this.props.reservation.repeating && (
              <div className="alert alert-warning" role="alert">
                Olet poistamassa viikoittain toistuvaa varausta. Tämä poistaa jokaisen yksittäisen
                varauksen, myös menneet varaukset. Jos haluat lopettaa toistuvan varauksen, voit
                muokata sen päättymispäivää, jolloin historiatieto säilyy.
              </div>
            )}
            {!myReservation && (
              <div className="alert alert-warning" role="alert">
                Olet poistamassa toisen käyttäjän tekemää varausta. Ilmoita poistosta varaajalle
                väärinkäsitysten välttämiseksi. Varaajalle ei ilmoiteta automaattisesti varauksen
                poistamisesta.
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.handleCloseDeleteConfirmModal} tabIndex={1}>
              Peruuta
            </Button>
            <Button color="danger" autoFocus={true} onClick={this.handleDeleteClick} tabIndex={2}>
              Poista varaus
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.confirmEditModalOpen}
          toggle={this.handleCloseEditConfirmModal}
          backdrop="static"
        >
          <ModalHeader toggle={this.handleCloseEditConfirmModal}>
            Tallenna varauksen muutokset
          </ModalHeader>
          <ModalBody>
            <p>
              Haluatko varmasti tallentaa muutokset olemassa olevaan varaukseen? Huomioi, että
              varauksen päättymispäivää voi vain aikaistaa. Et siis voi myöhemmin muokata varausta
              takaisin nykyiseen tilaan.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.handleCloseEditConfirmModal} tabIndex={1}>
              Peruuta
            </Button>
            <Button color="warning" autoFocus={true} onClick={this.handleEditClick} tabIndex={2}>
              Tallenna
            </Button>
          </ModalFooter>
        </Modal>
      </ContentContainer>
    );
  }
}

const mapStateToProps = (
  state: VarauksetState,
  ownProps: ReservationDetailsRouterProps
): ReservationDetailsStateProps => ({
  reservation: state.reservationDetails.reservation,
  resource: state.reservationDetails.resource,
  user: state.user.activeUser,
  organization: state.organization.organization,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<VarauksetState, undefined, AnyAction>
): ReservationDetailsDispatchProps => ({
  onDeleteReservation: (resource, reservationId) =>
    dispatch(deleteReservationAndNavigateBackAsync(resource, reservationId)),
  onFetchResource: (resourceId: string) => dispatch(fetchResourceAsync(resourceId)),
  onFetchReservation: (reservationId: string) => dispatch(fetchReservationAsync(reservationId)),
  onEditReservation: (reservation: EditReservation) =>
    dispatch(editReservationAndRefetchAsync(reservation)),
});

const ReservationDetailsConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationDetails);

export default withRouter((props: RouteComponentProps<any> & ReservationDetailsRouterProps) => (
  <ReservationDetailsConnected
    {...props}
    organizationSlug={props.match.params.organizationSlug}
    resourceId={props.match.params.resourceId}
    reservationId={props.match.params.reservationId}
  />
));
