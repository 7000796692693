import * as joda from 'js-joda';
import * as React from 'react';
import { dateString, ValidationResult } from '../validators';

interface DateInputProps {
  tabIndex?: number;
  id?: string;
  valid?: boolean;
  value: joda.LocalDate | undefined;
  onChange: (date: joda.LocalDate | undefined, validationResult: ValidationResult) => void;
}

interface DateInputState {
  rawInput: string;
}

export default class DateInput extends React.Component<DateInputProps, DateInputState> {
  constructor(props: DateInputProps) {
    super(props);
    this.state = {
      rawInput: '',
    };
  }

  handleDateChange(dateStr: string) {
    this.setState({ rawInput: dateStr });
    const validationResult = dateString(dateStr);
    let date = undefined;
    if (validationResult.valid && dateStr) {
      date = joda.LocalDate.parse(dateStr);
    }
    this.props.onChange(date, validationResult);
  }

  render() {
    const props = this.props;
    const inputClasses = ['form-control'];
    if (props.valid) {
      inputClasses.push('is-valid');
    } else if (props.valid === false) {
      inputClasses.push('is-invalid');
    }

    return (
      <input
        tabIndex={props.tabIndex}
        className={inputClasses.join(' ')}
        type="date"
        name="date"
        id={props.id}
        placeholder="VVVV-KK-PP"
        value={props.value ? props.value.toString() : this.state.rawInput}
        onChange={(e) => this.handleDateChange(e.target.value)}
      />
    );
  }
}
