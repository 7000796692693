// DON'T TRY TO BE SMART AND REFACTOR THIS AWAY!
// Helper to break circular dependency.
// Before:
// store.ts depends on reducers, reducers depend on apiClient, apiClient depend on store.ts.
// Now apiClient can get store-reference via storeRegistry and doesn't need a store.ts dependency.

import { Store } from 'redux';

let store: Store<any>;

export function registerStore(s: Store<any>) {
  store = s;
}

export function getStore() {
  return store;
}
