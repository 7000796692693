import { Dispatch } from 'redux';
import { Action, createAction } from 'redux-actions';
import * as api from '../apiClient';
import { EditReservation, Reservation, Resource } from '../models/models';
import { createToast } from '../notification';
import history from './browserHistory';
import { fetchReservationsAsync } from './resource';
import { GetState } from './store';
import { fetchCurrentUserAsync } from './user';
import { startWait, stopWait } from './wait';

// ACTIONS

export enum ReservationDetailsAction {
  RECEIVE_RESOURCE = 'varaukset/reservationDetails/RECEIVE_RESOURCE',
  RECEIVE_RESERVATION = 'varaukset/reservationDetails/RECEIVE_RESERVATION',
}

export const receiveResource = createAction<Resource | undefined>(
  ReservationDetailsAction.RECEIVE_RESOURCE
);

export function fetchResourceAsync(resourceId: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startWait());
    dispatch(receiveResource(undefined));
    const resource = await api.getResource(resourceId);
    dispatch(stopWait());
    dispatch(receiveResource(resource));
  };
}

export const receiveReservation = createAction<Reservation | undefined>(
  ReservationDetailsAction.RECEIVE_RESERVATION
);

export function fetchReservationAsync(reservationId: string, clear: boolean = true) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startWait());
    if (clear) {
      dispatch(receiveReservation(undefined));
    }
    const reservation = await api.getReservation(reservationId);
    dispatch(stopWait());
    dispatch(receiveReservation(reservation));
  };
}

export function deleteReservationAndNavigateBackAsync(resource: Resource, reservationId: string) {
  return async (dispatch: Dispatch<any>, getState: GetState) => {
    const state = getState();
    dispatch(startWait());
    const apiResponse = await api.deleteReservation(reservationId, resource);
    dispatch(stopWait());
    dispatch(fetchCurrentUserAsync());
    if (apiResponse.success) {
      // Refetch calendar reservations. Back navigate can take user back to calendar. In that case
      // something needs to update visible reservations. Pure navigation currently does not fire
      // refetch, because of how calendar components work. MyReservations view refreshes
      // "automatically" on back navigation.
      const range = state.calendar.visibleDateRange;
      dispatch(fetchReservationsAsync(range, resource.id));
      history.goBack();
      const msg = 'Varaus on poistettu.';
      createToast(msg, 'info');
    } else {
      const msg = apiResponse.errorMessages ? apiResponse.errorMessages.join(', ') : 'Virhe';
      createToast(msg, 'error');
    }
  };
}

export function editReservationAndRefetchAsync(reservation: EditReservation) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startWait());
    const response = await api.editReservation(reservation);
    dispatch(stopWait());
    if (response.success) {
      const msg = 'Varaus päivitetty.';
      createToast(msg, 'success');
    } else {
      const msg = response.errorMessages ? response.errorMessages.join(', ') : 'Virhe';
      createToast(msg, 'error');
    }
    dispatch(fetchReservationAsync(reservation.id, false));
  };
}

// REDUCER

export interface ReservationDetailsState {
  reservation?: Reservation;
  resource?: Resource;
}

const reservationDetailsInitialState: ReservationDetailsState = {
  reservation: undefined,
  resource: undefined,
};

export default function reservationDetailsReducer(
  state: ReservationDetailsState = reservationDetailsInitialState,
  action: Action<any>
): ReservationDetailsState {
  switch (action.type) {
    case ReservationDetailsAction.RECEIVE_RESOURCE:
      return {
        ...state,
        resource: action.payload,
      };
    case ReservationDetailsAction.RECEIVE_RESERVATION:
      return {
        ...state,
        reservation: action.payload,
      };

    default:
      return state;
  }
}
