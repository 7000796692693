import * as FA from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Popover, PopoverBody, PopoverHeader } from 'reactstrap';

interface FontAwesomeWithTooltipProps {
  id: string;
  tooltipHeader?: string;
  style?: React.CSSProperties;
}

interface FontAwesomeWithTooltipState {
  clickOpen: boolean;
  hoverOpen: boolean;
}

type FAProps = FontAwesomeWithTooltipProps & FA.Props;

export default class FontAwesomeWithTooltip extends React.Component<
  FAProps,
  FontAwesomeWithTooltipState
> {
  constructor(props: FAProps) {
    super(props);
    this.state = {
      clickOpen: false,
      hoverOpen: false,
    };

    this.handleHoverEnter = this.handleHoverEnter.bind(this);
    this.handleHoverLeave = this.handleHoverLeave.bind(this);
    this.handleClickToggle = this.handleClickToggle.bind(this);
  }

  handleHoverEnter() {
    this.setState({
      hoverOpen: !this.state.clickOpen,
    });
  }

  handleHoverLeave() {
    this.setState({
      hoverOpen: false,
    });
  }

  handleClickToggle() {
    this.setState({
      clickOpen: !this.state.clickOpen,
      hoverOpen: !this.state.clickOpen ? false : this.state.hoverOpen,
    });
  }

  render() {
    const props = this.props;
    const open = this.state.hoverOpen || this.state.clickOpen;
    return (
      <span>
        <span
          id={'help-popover-' + props.id}
          onClick={this.handleClickToggle}
          onMouseEnter={this.handleHoverEnter}
          onMouseLeave={this.handleHoverLeave}
        >
          <FA.FontAwesomeIcon {...props} />
        </span>
        <Popover
          placement={'auto'}
          isOpen={open}
          target={'help-popover-' + props.id}
          toggle={this.handleClickToggle}
        >
          <PopoverHeader>{this.props.tooltipHeader}</PopoverHeader>
          <PopoverBody>{this.props.children}</PopoverBody>
        </Popover>
      </span>
    );
  }
}
