import * as React from 'react';

interface OrganizationNotFoundProps {
  organizationSlug: string;
}

export const OrganizationNotFound: React.FC<OrganizationNotFoundProps> = (props) => {
  return (
    <div>
      <p>{`Ei löydy: ${props.organizationSlug}`}</p>
    </div>
  );
};

export default OrganizationNotFound;
