import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as uuid from 'uuid';
import { SetPasswordData } from '../../models/models';
import { VarauksetState } from '../../redux/store';
import { setPasswordAsync } from '../../redux/user';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import { isEqualBuilder, minLengthBuilder, validate, ValidationField } from '../../validators';
import { ContentContainer } from '../ContentContainer';
import './SetPassword.scss';

interface SetPasswordDispatchProps {
  onSetPassword(setPasswordData: SetPasswordData): void;
}

interface SetPasswordRouterProps {
  uid: string;
  resetToken: string;
}

interface SetPasswordProps extends SetPasswordDispatchProps, SetPasswordRouterProps {}

interface SetPasswordFormState {
  password1: ValidationField<string>;
  password2: ValidationField<string>;
}

interface SetPasswordState {
  form: SetPasswordFormState;
}

export class SetPassword extends React.Component<SetPasswordProps, SetPasswordState> {
  constructor(props: SetPasswordProps) {
    super(props);

    this.state = {
      form: {
        password1: { value: '', validationResult: { valid: false }, touched: false },
        password2: { value: '', validationResult: { valid: false }, touched: false },
      },
    };

    this.handleSetPasswordSubmit = this.handleSetPasswordSubmit.bind(this);
  }

  handlePassword1Change(password: string) {
    this.setState((prevState: SetPasswordState) => {
      return {
        form: {
          ...prevState.form,
          password1: this.createPassword1Validated(password),
        },
      };
    });
  }

  handlePassword2Change(password: string) {
    this.setState((prevState: SetPasswordState) => {
      return {
        form: {
          ...prevState.form,
          password2: this.createPassword2Validated(password),
        },
      };
    });
  }

  createPassword1Validated(password: string) {
    return {
      value: password,
      touched: true,
      validationResult: validate(password, [minLengthBuilder(6)]),
    };
  }

  createPassword2Validated(password: string) {
    return {
      value: password,
      touched: true,
      validationResult: validate(password, [isEqualBuilder(this.state.form.password1.value)]),
    };
  }

  handleSetPasswordSubmit(event: React.SyntheticEvent<any>) {
    event.preventDefault();
    const setPasswordData: SetPasswordData = {
      uid: this.props.uid,
      resetToken: this.props.resetToken,
      newPassword: this.state.form.password1.value,
    };
    this.props.onSetPassword(setPasswordData);
  }

  render() {
    const form = this.state.form;
    const password1InputClasses = ['form-control'];
    const password2InputClasses = ['form-control'];

    if (form.password1.validationResult.valid) {
      password1InputClasses.push('is-valid');
    } else {
      password1InputClasses.push('is-invalid');
    }

    if (form.password2.validationResult.valid) {
      password2InputClasses.push('is-valid');
    } else {
      password2InputClasses.push('is-invalid');
    }

    const formValid =
      form.password1.validationResult.valid && form.password2.validationResult.valid;

    return (
      <ContentContainer style={{ maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }}>
        <ScrollToTopOnMount />
        <form id="v-set-password-form" onSubmit={this.handleSetPasswordSubmit} noValidate={true}>
          <h2>Aseta salasana</h2>

          <hr />

          <div className="form-group">
            <label htmlFor="login-password1">Uusi salasana</label>
            <input
              tabIndex={1}
              autoFocus={true}
              type="password"
              className={password1InputClasses.join(' ')}
              id="login-password1"
              value={this.state.form.password1.value}
              onChange={(e) => this.handlePassword1Change(e.target.value)}
            />
            {form.password1.touched &&
            !form.password1.validationResult.valid &&
            form.password1.validationResult.errorMessages &&
            form.password1.validationResult.errorMessages.length ? (
              <div className="invalid-feedback">
                <ul>
                  {form.password1.validationResult.errorMessages.map((msg) => (
                    <li key={uuid.v4()}>{msg}</li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="login-password2">Uusi salasana uudestaan</label>
            <input
              tabIndex={2}
              type="password"
              className={password2InputClasses.join(' ')}
              id="login-password2"
              value={this.state.form.password2.value}
              onChange={(e) => this.handlePassword2Change(e.target.value)}
            />
            {form.password2.touched &&
            !form.password2.validationResult.valid &&
            form.password2.validationResult.errorMessages &&
            form.password2.validationResult.errorMessages.length ? (
              <div className="invalid-feedback">
                <ul>
                  {form.password2.validationResult.errorMessages.map((msg) => (
                    <li key={uuid.v4()}>{msg}</li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>

          <button
            className="btn btn-primary btn-lg"
            type="submit"
            disabled={!formValid}
            tabIndex={3}
          >
            Aseta salasana
          </button>
        </form>
      </ContentContainer>
    );
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<VarauksetState, undefined, AnyAction>
): SetPasswordDispatchProps => ({
  onSetPassword: (setPasswordData: SetPasswordData) => dispatch(setPasswordAsync(setPasswordData)),
});

const SetPasswordConnected = connect(null, mapDispatchToProps)(SetPassword);

export default withRouter((props: RouteComponentProps<any>) => (
  <SetPasswordConnected uid={props.match.params.uid} resetToken={props.match.params.resetToken} />
));
