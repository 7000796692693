import { FormikErrors } from 'formik';
import * as joda from 'js-joda';
import * as yup from 'yup';
import { Range, Resource, Rules, TimeRange, WeekDay, WeekDayTimeRanges } from '../../models/models';
import { weekDayTimeRangesSorted } from '../../utils';
import { timeString } from '../../validators';

export type FormStateAvailableTime = {
  rowId: string;
  weekday: string;
  timeRange: Range<string>;
};

export type FormStateResource = {
  name: string;
  reservationMode: string; // ReservationMode
  reservationDescriptionLabel: string;
  reservationDescriptionPlaceholder: string;
  showReservationRulesOnUi: boolean;
  showHourPriceOnUi: boolean;
  description?: string;
  orderNumber: number | '';
  price: number;
  pricingMode: string; // PricingMode
  reservationMaxLen?: joda.Duration;
  reservationMinLen?: joda.Duration;
  reservationAllowedDaysToFuture?: number;
  canDeleteBefore?: joda.Duration;
  availableTimes: FormStateAvailableTime[];
};

export function toFormStateResource(resource: Resource): FormStateResource {
  return {
    name: resource.name,
    reservationMode: resource.reservationMode.toString(),
    reservationDescriptionLabel: resource.reservationDescriptionLabel,
    reservationDescriptionPlaceholder: resource.reservationDescriptionPlaceholder,
    showReservationRulesOnUi: resource.showReservationRulesOnUi,
    showHourPriceOnUi: resource.showHourPriceOnUi,
    description: resource.description,
    orderNumber: resource.orderNumber === null ? '' : resource.orderNumber,
    price: resource.price,
    pricingMode: resource.pricingMode.toString(),
    reservationMaxLen: resource.rules?.reservationMaxLen,
    reservationMinLen: resource.rules?.reservationMinLen,
    reservationAllowedDaysToFuture: resource.rules?.reservationAllowedDaysToFuture,
    canDeleteBefore: resource.rules?.canDeleteBefore,
    availableTimes: Object.entries(resource.rules?.weekDayTimeRanges || ({} as WeekDayTimeRanges))
      .map(([weekday, timeRanges]: [string, TimeRange[]], index1) =>
        timeRanges.map((timeRange, index2) => ({
          rowId: `${index1}${index2}`,
          weekday: weekday,
          timeRange: { start: timeRange.start.toString(), end: timeRange.end.toString() },
        }))
      )
      .flat(),
  };
}

export function toResource(
  formStateResource: FormStateResource,
  resourceId: string,
  organizationId: string
): Resource {
  const rules: Rules = {
    reservationMaxLen: formStateResource.reservationMaxLen,
    reservationMinLen: formStateResource.reservationMinLen,
    reservationAllowedDaysToFuture: formStateResource.reservationAllowedDaysToFuture,
    canDeleteBefore: formStateResource.canDeleteBefore,
    weekDayTimeRanges: weekDayTimeRangesSorted(
      formStateResource.availableTimes.reduce((acc, cur) => {
        const weekday = cur.weekday as WeekDay;
        const stringTimeRange = cur.timeRange;
        const timeRange: TimeRange = {
          start: joda.LocalTime.parse(stringTimeRange.start),
          end: joda.LocalTime.parse(stringTimeRange.end),
        };
        if (!acc[weekday]) {
          acc[weekday] = [];
        }
        acc[weekday]!.push(timeRange);
        return acc;
      }, {} as WeekDayTimeRanges)
    ),
  };

  return {
    id: resourceId,
    organizationId: organizationId,
    name: formStateResource.name,
    orderNumber: formStateResource.orderNumber === '' ? null : formStateResource.orderNumber,
    reservationMode: parseInt(formStateResource.reservationMode),
    reservationDescriptionLabel: formStateResource.reservationDescriptionLabel,
    reservationDescriptionPlaceholder: formStateResource.reservationDescriptionPlaceholder,
    showReservationRulesOnUi: formStateResource.showReservationRulesOnUi,
    showHourPriceOnUi: formStateResource.showHourPriceOnUi,
    description: formStateResource.description,
    price: formStateResource.price,
    pricingMode: parseInt(formStateResource.pricingMode),
    rules: rules,
  };
}

const reservationMinLenYup = yup
  .object()
  .test('reservationMinLen-positive', 'Ei saa olla negatiivinen', (value) => {
    if (!value) {
      return true;
    }
    const dur: joda.Duration = value as any;
    return !dur.isNegative();
  })
  .test('reservationMinLen-max', 'Max. 24h', (value) => {
    if (!value) {
      return true;
    }
    const dur: joda.Duration = value as any;
    return dur.toMinutes() <= 24 * 60;
  })
  .required('Pakollinen');

const reservationMaxLenYup = yup
  .object()
  .required('Pakollinen')
  .test('reservationMaxLen-positive', 'Täytyy olla suurempi kuin 0', (value) => {
    if (!value) {
      return true;
    }
    const dur: joda.Duration = value as any;
    return !(dur.isZero() || dur.isNegative());
  })
  .test('reservationMaxLen-max', 'Max. 24h', (value) => {
    if (!value) {
      return true;
    }
    const dur: joda.Duration = value as any;
    return dur.toMinutes() <= 24 * 60;
  })
  .test('reservationMaxLen-gte-min', 'Ei saa olla minimikestoa lyhyempi', (value, context) => {
    const minD = context.parent.reservationMinLen as joda.Duration;
    const maxD = value as unknown as joda.Duration;
    if (maxD === undefined || minD === undefined) {
      return true;
    }
    return maxD.toMillis() >= minD.toMillis();
  });

const reservationAllowedDaysToFutureYup = yup
  .number()
  .min(0, 'Ei voi olla negatiivinen')
  .required('Pakollinen');

const canDeleteBeforeYup = yup
  .object()
  .test('canDeleteBefore-positive', 'Ei saa olla negatiivinen', (value) => {
    if (!value) {
      return true;
    }
    const dur: joda.Duration = value as any;
    return !dur.isNegative();
  })
  .required('Pakollinen');

const availableTimesYup = yup
  .array()
  .of(
    yup.object({
      weekday: yup.number().min(1).max(7).required('Pakollinen'),
      timeRange: yup
        .object({
          start: yup
            .string()
            .test('timeRange-start', 'Virheellinen aika tai muoto', (value) => {
              const r = timeString(value || '');
              return r.valid;
            })
            .required('Pakollinen'),
          end: yup
            .string()
            .test('timeRange-end', 'Virheellinen aika tai muoto', (value) => {
              const r = timeString(value || '');
              return r.valid;
            })
            .test('timeRange-end', 'Loppuaika ei voi olla ennen alkuaikaa', (value, context) => {
              const start = (context.parent.start || '') as string;
              const end = value || '';
              if (!start || !end) {
                // One of the values is empty, so we can't validate.
                // Earlier validation should catch this.
                return true;
              }
              const rStart = timeString(start);
              const rEnd = timeString(end);
              if (!rStart.valid || !rEnd.valid) {
                // One of the values is invalid, so we can't validate.
                // Earlier validation should catch this.
                return true;
              }
              const startTime = joda.LocalTime.parse(start);
              const endTime = joda.LocalTime.parse(end);
              return !endTime.isBefore(startTime);
            })
            .required('Pakollinen'),
        })
        .required('Pakollinen'),
    })
  )
  .required('Pakollinen');

export const formStateResourceValidationYup = yup.object({
  name: yup.string().max(200, 'Max. 200 merkkiä').required('Pakollinen'),
  orderNumber: yup.number(),
  description: yup.string().max(5000, 'Max. 5000 merkkiä'),
  reservationDescriptionLabel: yup.string().max(50, 'Max. 50 merkkiä').required('Pakollinen'),
  reservationDescriptionPlaceholder: yup.string().max(50, 'Max. 50 merkkiä'),
  reservationMinLen: reservationMinLenYup,
  reservationMaxLen: reservationMaxLenYup,
  reservationAllowedDaysToFuture: reservationAllowedDaysToFutureYup,
  canDeleteBefore: canDeleteBeforeYup,
  price: yup.number().min(0, 'Ei voi olla negatiivinen'),
  availableTimes: availableTimesYup,
});

export function formikErrorsToHuman(
  errors: string | string[] | FormikErrors<FormStateAvailableTime>
): string {
  return (
    JSON.stringify(errors)
      .replaceAll('"', '')
      // Handles only availableTimes keys. Update to generic regex if needed.
      .replaceAll('timeRange', '')
      .replaceAll('weekday', '')
      .replaceAll('start', '')
      .replaceAll('end', '')
      .replaceAll(',', ', ')
      .replaceAll('{', '')
      .replaceAll('}', '')
      .replaceAll(':', '')
  );
}
