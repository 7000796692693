import { toast } from 'react-toastify';
import * as uuid from 'uuid';

export enum IdempotencyKeys {
  GeneralApiError = 'generalApiError',
  NetworkApiError = 'networkApiError',
}

export const botErrorMsg =
  'Järjestelmä epäilee sinun olevan robotti. Toiminto on estetty. ' +
  'Jos tämä on virhe, ota yhteyttä ylläpitoon.';

let activeToasts: Set<string> = new Set();
type ToastType = 'success' | 'info' | 'default' | 'warning' | 'error';

export function createToast(
  message: string,
  type: ToastType = 'default',
  idempotencyKey?: string,
  autoClose?: number | false
) {
  const key = idempotencyKey || uuid.v4();
  let defaultAutoClose = 1000;
  defaultAutoClose += message.length * 30;
  if (type === 'error' || type === 'warning') {
    defaultAutoClose += 3000;
  }

  if (!activeToasts.has(key)) {
    activeToasts.add(key);
    toast(message, {
      type,
      autoClose: autoClose !== undefined ? autoClose : defaultAutoClose,
      onClose: () => activeToasts.delete(key),
    });
  }
}
