import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { fetchOrganizationBySlugAsync } from '../../redux/organization';
import { VarauksetState } from '../../redux/store';
import StaticModalNotification from '../StaticModalNotification';

interface CheckingOrganizationOwnProps {
  routeSlug: string;
}

interface CheckingOrganizationProps extends CheckingOrganizationOwnProps {
  onFetchOrg(slug: string): void;
}

class CheckingOrganization extends React.Component<CheckingOrganizationProps, {}> {
  componentDidMount() {
    this.props.onFetchOrg(this.props.routeSlug);
  }
  render() {
    return <StaticModalNotification>{'Tarkastetaan organisaatiota ...'}</StaticModalNotification>;
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<VarauksetState, undefined, AnyAction>,
  ownProps: CheckingOrganizationOwnProps
) => {
  return {
    onFetchOrg: (slug: string) => dispatch(fetchOrganizationBySlugAsync(slug)),
  };
};

export default connect(() => ({}), mapDispatchToProps)(CheckingOrganization as any);
