import * as React from 'react';

interface MetaTagProps {
  name: string;
  content: string;
}

interface MetaTagState {
  tag?: HTMLMetaElement;
}

export default class MetaTag extends React.Component<MetaTagProps, MetaTagState> {
  // Crawler support for dynamically created meta-tags is far from perfect. Google supports them,
  // even though even GoogleBot will sometimes skip executing JS on the page.
  // Since robust server side solution is hard to implement, well use this as a first aid for now.
  componentDidMount() {
    const m = document.createElement('meta');
    m.name = this.props.name;
    m.content = this.props.content;
    document.head.appendChild(m);
    this.setState({ tag: m });
  }

  componentWillUnmount() {
    const tag = this.state.tag;
    if (tag) {
      document.head.removeChild(tag);
    }
  }

  render() {
    return null;
  }
}
