import { useFormik } from 'formik';
import { uniqueId } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { PricingMode, ReservationMode, Resource, WeekDay } from '../../models/models';
import * as paths from '../../routerPaths';
import { classNames } from '../../utils';
import DurationInput from '../DurationInput';
import './EditResourceForm.scss';
import { HalfHourPriceExamples, HourPriceExamples } from './priceExamples';
import {
  formikErrorsToHuman,
  formStateResourceValidationYup,
  toFormStateResource,
  toResource,
} from './utils';

function getNextWeekday(weekday: WeekDay): WeekDay {
  if (weekday === '7') {
    return '1';
  }
  return (parseInt(weekday) + 1).toString() as WeekDay;
}

type EditResourceFormProps = {
  initialResource: Resource;
  onSubmit: (resource: Resource) => void;
};

export const EditResourceForm: React.FC<EditResourceFormProps> = (props) => {
  const history = useHistory();

  const formik = useFormik({
    initialValues: toFormStateResource(props.initialResource),
    validationSchema: formStateResourceValidationYup,
    onSubmit: (values, formikHelpers) => {
      props.onSubmit(
        toResource(values, props.initialResource.id, props.initialResource.organizationId)
      );
      formikHelpers.setSubmitting(false);
    },
  });

  function removeAvailableTime(index: number) {
    const availableTimes = [...formik.values.availableTimes];
    availableTimes.splice(index, 1);
    formik.setFieldValue('availableTimes', availableTimes);
  }

  function addAvailableTime() {
    const availableTimes = [...formik.values.availableTimes];
    const lastAvailableTime = availableTimes[availableTimes.length - 1];
    const nextWeekday = lastAvailableTime
      ? getNextWeekday(lastAvailableTime.weekday as WeekDay)
      : '1';
    const nextStartTime = lastAvailableTime ? lastAvailableTime.timeRange.start : '08:00';
    const nextEndTime = lastAvailableTime ? lastAvailableTime.timeRange.end : '22:00';

    availableTimes.push({
      rowId: uniqueId(),
      weekday: nextWeekday,
      timeRange: { start: nextStartTime, end: nextEndTime },
    });
    formik.setFieldValue('availableTimes', availableTimes);
  }

  const demoWarning = (
    <div className="alert alert-warning">
      Tämä on demo-kalenteri, johon ei voi tehdä muutoksia tällä lomakkeella.
    </div>
  );

  const formHasErrors = formik.errors && Object.keys(formik.errors).length > 0;
  const isDemoResource = props.initialResource.reservationMode === ReservationMode.Demo;

  return (
    <form onSubmit={formik.handleSubmit}>
      <h2 style={{ overflowWrap: 'break-word' }}>Muokkaa kalenteria {formik.values.name}</h2>
      <a href={paths.getAdminInstructionsAbsoluteUrl()} target="_blank" rel="noreferrer">
        Ylläpitäjän ohjeet ja usein kysytyt kysymykset
      </a>
      {isDemoResource && demoWarning}
      <hr />

      <div className="form-group">
        <label htmlFor="resource-name">Kalenterin otsikko</label>
        <input
          id="resource-name"
          type="text"
          className={classNames('form-control input-max-w-md', {
            'is-invalid': !!(formik.touched.name && formik.errors.name),
          })}
          {...formik.getFieldProps('name')}
        />
        {formik.touched.name && formik.errors.name && (
          <div className="small text-danger">{formik.errors.name}</div>
        )}
      </div>

      <div className="form-group">
        <label htmlFor="resource-description">Kuvaus</label>
        <textarea
          className={classNames('form-control', {
            'is-invalid': !!(formik.touched.description && formik.errors.description),
          })}
          id="resource-description"
          rows={3}
          {...formik.getFieldProps('description')}
        />
        {formik.touched.description && formik.errors.description && (
          <div className="small text-danger">{formik.errors.description}</div>
        )}
        <small className="form-text text-muted">
          Teksti näytetään käyttäjille kalenterin kuvaus ja säännöt -osiossa.
        </small>
      </div>

      <div className="form-group">
        <label htmlFor="resource-order">Järjestysnumero</label>
        <input
          id="resource-order"
          type="number"
          className={classNames('form-control input-max-w-sm', {
            'is-invalid': !!(formik.touched.orderNumber && formik.errors.orderNumber),
          })}
          {...formik.getFieldProps('orderNumber')}
        />
        {formik.touched.orderNumber && formik.errors.orderNumber && (
          <div className="small text-danger">{formik.errors.orderNumber}</div>
        )}
        <small className="form-text text-muted">
          Tämä numero määrittää kalenterin järjestyksen listauksessa. Esim. kalenterit, joilla on
          järjestysnumerot &lt;tyhjä&gt;, -1, 2, 5, 99 järjestetään keskenään tähän järjestykseen.
          Jos järjestysnumero on sama kuin toisella kalenterilla tai järjestysnumeroita ei ole
          määritelty, kalenterit järjestetään aakkosjärjestykseen. Järjestysnumero ei näy
          käyttäjille.
        </small>
      </div>

      <div className="form-group">
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="show-rules"
            checked={formik.values.showReservationRulesOnUi} // Workaround for checkbox initial value bug https://github.com/jaredpalmer/formik/issues/1050
            {...formik.getFieldProps('showReservationRulesOnUi')}
          />
          <label className="form-check-label" htmlFor="show-rules">
            Näytä sääntötekstit
          </label>
        </div>
        <small className="form-text text-muted">
          Varaussäännöistä muodostetaan automaattisesti teksti, joka näytetään käyttäjälle
          kalenterin kuvaus ja säännöt -osiossa.
        </small>
      </div>

      <div className="form-group">
        <div className="">
          <div>
            <label htmlFor="inputGroupSelect01">Varaajan tunnistaminen</label>
          </div>
          <div>
            <select
              className="custom-select input-max-w-md"
              id="inputGroupSelect01"
              {...formik.getFieldProps('reservationMode')}
            >
              <option value={ReservationMode.Anonymous}>Tunnistamaton käyttäjä saa varata</option>
              <option value={ReservationMode.EmailConfirmation}>
                Vain tunnistettu käyttäjä saa varata
              </option>
              <option value={ReservationMode.ReadOnly}>Vain ylläpitäjä saa varata</option>
            </select>
          </div>
        </div>
        <small className="form-text text-muted">
          <strong>Tunnistamaton käyttäjä saa varata:</strong> Myös kirjautumaton käyttäjä saa luoda
          varauksen, eikä varaajan tarvitse syöttää sähköpostiosoitettaan varauksen teon yhteydessä.
        </small>
        <small className="form-text text-muted">
          <strong>Vain tunnistettu käyttäjä saa varata:</strong> Varaajan tulee olla kirjautunut tai
          varaajan tulee syöttää sähköpostiosoitteensa varauksen teon yhteydessä. Jälkimmäisessä
          tapauksessa varaajan antamaan sähköpostiosoitteeseen lähetetään varmistusviesti. Jos
          käyttäjä ei vahvista varausta sähköpostiviestissä olevalla linkillä, varaus poistetaan
          automaattisesti n. 15 min kuluttua. Kirjautunut käyttäjä ei saa sähköpostiviestiä.
          Varauksen tekoa ei voi rajoittaa vain tietyille käyttäjille tai sähköpostiosoitteille.
          Kalenteriin pääsyn rajaus tulee tehdä jakamalla kalenterin osoite vain halutuille
          henkilöille (vrt. ovikoodi).
        </small>
        <small className="form-text text-muted">
          <strong>Vain ylläpitäjä saa varata:</strong> Vain tämän organisaation ylläpitäjäksi
          merkitty kirjautunut käyttäjä saa tehdä varauksia. Muut voivat vain katsella kalenteria.
        </small>
        <small className="form-text text-muted">
          Kalenterin tilan muuttaminen ei vaikuta jo tehtyihin varauksiin. Jos kalenterin varaukset
          ovat maksullisia, varaajan tulee aina olla kirjautunut tehdäkseen varauksen riippumatta
          tästä asetuksesta.
        </small>
      </div>

      <hr />

      <div className="form-group">
        <label htmlFor="resource-desc-label">Varauksen lisätiedon otsikko</label>
        <input
          id="resource-desc-label"
          type="text"
          className={classNames('form-control input-max-w-md', {
            'is-invalid': !!(
              formik.touched.reservationDescriptionLabel &&
              formik.errors.reservationDescriptionLabel
            ),
          })}
          {...formik.getFieldProps('reservationDescriptionLabel')}
        />
        {formik.touched.reservationDescriptionLabel &&
          formik.errors.reservationDescriptionLabel && (
            <div className="small text-danger">{formik.errors.reservationDescriptionLabel}</div>
          )}
        <small className="form-text text-muted">
          Varauksen yhteydessä varaajalta pyydettävän lisätiedon otsikko. Esim. "Asunnon numero".
        </small>
      </div>
      <div className="form-group">
        <label htmlFor="resource-desc-placeholder">Varauksen lisätiedon esimerkki</label>
        <input
          id="resource-desc-placeholder"
          type="text"
          className={classNames('form-control input-max-w-md', {
            'is-invalid': !!(
              formik.touched.reservationDescriptionPlaceholder &&
              formik.errors.reservationDescriptionPlaceholder
            ),
          })}
          {...formik.getFieldProps('reservationDescriptionPlaceholder')}
        />
        {formik.touched.reservationDescriptionPlaceholder &&
          formik.errors.reservationDescriptionPlaceholder && (
            <div className="small text-danger">
              {formik.errors.reservationDescriptionPlaceholder}
            </div>
          )}
        <small className="form-text text-muted">
          Varauksen yhteydessä varaajalta pyydettävän lisätiedon esimerkkiteksti. Esim. "Esim. A
          12".
        </small>
      </div>

      <hr />
      <h3>Varaussäännöt</h3>
      <p>
        Varauksen tekoa ja kestoa koskevat säännöt rajoittavat tavallisia käyttäjiä. Säännöt eivät
        rajoita ylläpitäjien varauksia tai mahdollisuutta poistaa varauksia.
      </p>

      <div className="form-group">
        <label htmlFor="min-duration">Varauksen minimikesto</label>
        <DurationInput
          id="min-duration"
          classNames="input-max-w-md"
          showInvalid={!!(formik.touched.reservationMinLen && formik.errors.reservationMinLen)}
          value={formik.values.reservationMinLen}
          onBlur={() => formik.setFieldTouched('reservationMinLen')}
          onChange={(d) => formik.setFieldValue('reservationMinLen', d)}
        />
        {formik.touched.reservationMinLen && formik.errors.reservationMinLen && (
          <div className="small text-danger">{formik.errors.reservationMinLen}</div>
        )}
        <small className="form-text text-muted">Lyhyin sallittu yhden varauksen kesto.</small>
      </div>
      <div className="form-group">
        <label htmlFor="max-duration">Varauksen maksimikesto</label>
        <DurationInput
          id="max-duration"
          classNames="input-max-w-md"
          showInvalid={!!(formik.touched.reservationMaxLen && formik.errors.reservationMaxLen)}
          value={formik.values.reservationMaxLen}
          onBlur={() => formik.setFieldTouched('reservationMaxLen')}
          onChange={(d) => formik.setFieldValue('reservationMaxLen', d)}
        />
        {formik.touched.reservationMaxLen && formik.errors.reservationMaxLen && (
          <div className="small text-danger">{formik.errors.reservationMaxLen}</div>
        )}
        <small className="form-text text-muted">Pisin sallittu yhden varauksen kesto.</small>
      </div>

      <div className="form-group">
        <label htmlFor="days-to-future">Kuinka kauas tulevaisuuteen varauksen saa tehdä</label>
        <div className="input-group input-max-w-sm">
          <input
            id="days-to-future"
            type="number"
            min="0"
            className="form-control"
            {...formik.getFieldProps('reservationAllowedDaysToFuture')}
          />
          <div className="input-group-append">
            <div className="input-group-text">vrk</div>
          </div>
        </div>
        {formik.touched.reservationAllowedDaysToFuture &&
          formik.errors.reservationAllowedDaysToFuture && (
            <div className="small text-danger">{formik.errors.reservationAllowedDaysToFuture}</div>
          )}
        <small className="form-text text-muted">
          Kuinka monta vuorokautta nykyhetkestä eteenpäin varauksen saa korkeintaan tehdä.
        </small>
      </div>
      <div className="form-group">
        <label htmlFor="delete-allowed">Kuinka lähellä varauksen alkua varauksen saa poistaa</label>
        <DurationInput
          id="delete-allowed"
          classNames="input-max-w-lg"
          showInvalid={!!(formik.touched.canDeleteBefore && formik.errors.canDeleteBefore)}
          value={formik.values.canDeleteBefore}
          onBlur={() => formik.setFieldTouched('canDeleteBefore')}
          onChange={(d) => formik.setFieldValue('canDeleteBefore', d)}
          showDays={true}
        />
        {formik.touched.canDeleteBefore && formik.errors.canDeleteBefore && (
          <div className="small text-danger">{formik.errors.canDeleteBefore}</div>
        )}
        <small className="form-text text-muted">
          Kuinka lähellä varauksen alkua oman varauksensa saa vielä poistaa. Esim. jos annettu arvo
          on 2h, varauksen saa poistaa tasan 2h ennen varauksen alkua ja sitä aiemmin (esim. 3h
          ennen varauksen alkua), mutta ei enää myöhemmin (esim. 1h ennen varauksen alkua). Huomaa,
          että maksullisten varausten varausmaksu palautetaan käyttäjälle, jos varaus poistetaan.
        </small>
      </div>

      <hr />
      <h3>Varausmaksu</h3>

      <div className="form-group">
        <label htmlFor="reservation-price">Varauksen tuntihinta</label>
        <div className="input-group input-max-w-sm">
          <input
            id="reservation-price"
            type="number"
            min={0}
            step={0.01}
            className="form-control"
            {...formik.getFieldProps('price')}
          />
          <div className="input-group-append">
            <div className="input-group-text">€</div>
          </div>
        </div>
        {formik.touched.price && formik.errors.price && (
          <div className="small text-danger">{formik.errors.price}</div>
        )}
        <small className="form-text text-muted">
          0 tai tyhjä: varauksesta ei peritä maksua eikä hintaa näytetä käyttäjälle. Ylläpitäjät
          voivat aina tehdä varauksia maksutta. Tuntihinta pyöristetään sentin tarkkuuteen.
        </small>
      </div>

      <div className="form-group">
        <div className="">
          <div>
            <label htmlFor="inputGroupSelect01">Veloitustarkkuus</label>
          </div>
          <div>
            <select
              className="custom-select input-max-w-md"
              id="inputGroupSelect01"
              {...formik.getFieldProps('pricingMode')}
            >
              <option value={PricingMode.ChargeByHour}>Alkavan tunnin perusteella</option>
              <option value={PricingMode.ChargeByHalfHour}>
                Alkavan puolen tunnin perusteella
              </option>
            </select>
          </div>
        </div>
        <small className="form-text text-muted">
          <strong>Alkavan tunnin perusteella:</strong> Esimerkkejä
          <HourPriceExamples price={formik.values.price} />
        </small>
        <small className="form-text text-muted">
          <strong>Alkavan puolen tunnin perusteella:</strong> Esimerkkejä
          <HalfHourPriceExamples price={formik.values.price} />
        </small>
      </div>

      <div className="form-group">
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="show-hour-price"
            checked={formik.values.showHourPriceOnUi} // Workaround for checkbox initial value bug https://github.com/jaredpalmer/formik/issues/1050
            {...formik.getFieldProps('showHourPriceOnUi')}
          />
          <label className="form-check-label" htmlFor="show-hour-price">
            Näytä tuntihinta
          </label>
        </div>
        <small className="form-text text-muted">
          Tuntihinnan näyttäminen voidaan ottaa pois päältä, jos esimerkiksi varaukset on pakotettu
          tasan 3h mittaisiksi ja yhden tunnin hinnan näyttäminen olisi hämmentävää käyttäjälle.
          Käyttäjä näkee aina varauksen kokonaishinnan. Tuntihintaa 0 ei näytetä vaikka tämä asetus
          olisi päällä.
        </small>
      </div>

      <hr />

      <h3>Varattavat ajat</h3>

      {formik.values.availableTimes.map(({ rowId }, index) => (
        <div className="form-row input-max-w-lg mb-4 mb-md-0" key={rowId}>
          <div className="form-group col-md-3 col-12">
            <label
              htmlFor={`time-weekday-${index}`}
              className={index > 0 ? 'd-block d-md-none' : ''}
            >
              Viikonpäivä
            </label>
            <div>
              <select
                className="custom-select"
                id={`time-weekday-${index}`}
                {...formik.getFieldProps(`availableTimes.${index}.weekday`)}
              >
                <option value="1">maanantai</option>
                <option value="2">tiistai</option>
                <option value="3">keskiviikko</option>
                <option value="4">torstai</option>
                <option value="5">perjantai</option>
                <option value="6">lauantai</option>
                <option value="7">sunnuntai</option>
              </select>
            </div>{' '}
          </div>
          <div className="form-group col-md-3 col-6">
            <label htmlFor={`time-start-${index}`} className={index > 0 ? 'd-block d-md-none' : ''}>
              Alkuaika
            </label>
            <input
              type="time"
              className="form-control"
              id={`time-start-${index}`}
              placeholder="TT:MM"
              {...formik.getFieldProps(`availableTimes.${index}.timeRange.start`)}
            />
          </div>
          <div className="form-group col-md-3 col-6">
            <label htmlFor={`time-end-${index}`} className={index > 0 ? 'd-block d-md-none' : ''}>
              Loppuaika
            </label>
            <input
              type="time"
              className="form-control"
              id={`time-end-${index}`}
              placeholder="TT:MM"
              {...formik.getFieldProps(`availableTimes.${index}.timeRange.end`)}
            />
          </div>
          <div
            className="form-group col-md-3 col-12"
            style={{ display: 'flex', alignItems: 'end' }}
          >
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => removeAvailableTime(index)}
            >
              Poista
            </button>
          </div>
          {formik.touched.availableTimes &&
            formik.touched.availableTimes[index] &&
            formik.errors.availableTimes &&
            formik.errors.availableTimes[index] && (
              <div className="small text-danger col-12 mb-2" style={{ marginTop: '-14px' }}>
                {formikErrorsToHuman(formik.errors.availableTimes[index])}
              </div>
            )}
        </div>
      ))}
      <button className="btn btn-secondary" type="button" onClick={addAvailableTime}>
        Lisää
      </button>

      <hr />
      <p>
        Näet vielä yhteenvedon tehdyistä muutoksista ja voit joko peruuttaa tai hyväksyä ne.
        Muutokset astuvat voimaan heti tallennuksen jälkeen.
      </p>
      <p>
        Muutokset eivät vaikuta jo tehtyihin varauksiin. Varattavien aikojen ulkopuolelle jäävät
        olemassa olevat varaukset eivät poistu ja ne näkyvät edelleen kalenterissa.
      </p>
      <hr />
      {formHasErrors && formik.touched && (
        <div className="alert alert-danger" role="alert">
          Lomakkeella on virheitä.
        </div>
      )}
      {isDemoResource && demoWarning}

      <div className="v-reservation-buttons">
        <button className="btn btn-secondary" type="button" onClick={history.goBack}>
          Takaisin
        </button>
        <button
          className="btn btn-success"
          type="submit"
          disabled={formik.isSubmitting || !formik.dirty || formHasErrors || isDemoResource}
        >
          Jatka muutosten esikatseluun
        </button>
      </div>
    </form>
  );
};

export default EditResourceForm;
