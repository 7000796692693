import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as api from '../../apiClient';
import { resourceToEditResource } from '../../models/modelConverters';
import { Resource } from '../../models/models';
import { createToast } from '../../notification';
import { startWait, stopWait } from '../../redux/wait';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import { ContentContainer } from '../ContentContainer';
import EditResourceForm from './EditResourceForm';
import ResourceDiffSummary from './ResourceDiffSummary';

type EditResourceRouterProps = {
  organizationSlug: string;
  resourceId: string;
};

type EditResourceProps = RouteComponentProps<EditResourceRouterProps> & {};

export const EditResource: React.FC<EditResourceProps> = (props) => {
  const dispatch = useDispatch();
  const [resource, setResource] = useState<Resource>();
  const resourceId = props.match.params.resourceId;
  const [modalOpen, setModalOpen] = useState(false);
  const [modifiedResource, setModifiedResource] = useState<Resource>();
  const [isSaving, setIsSaving] = useState(false);

  function toggleModal() {
    if (!isSaving) {
      setModalOpen(!modalOpen);
    }
  }

  useEffect(() => {
    async function fetchResource(resourceId: string) {
      dispatch(startWait());
      const resource = await api.getResource(resourceId);
      setResource(resource);
      dispatch(stopWait());
    }

    fetchResource(resourceId).catch(console.error);
  }, [dispatch, resourceId]);

  function handlePreSubmit(resource: Resource) {
    setModifiedResource(resource);
    setModalOpen(true);
  }

  async function handleSave() {
    if (modifiedResource) {
      setIsSaving(true);
      const editedResource = resourceToEditResource(modifiedResource);
      dispatch(startWait());
      const apiResponse = await api.editResource(editedResource);
      dispatch(stopWait());
      if (!apiResponse.success) {
        const msg = apiResponse.errorMessages ? apiResponse.errorMessages.join(', ') : 'Virhe';
        createToast(msg, 'error');
      } else {
        createToast(`Tallennettu: ${modifiedResource.name}`, 'success');
        setResource(modifiedResource);
        setModifiedResource(undefined);
      }
      setModalOpen(false);
      setIsSaving(false);
    }
  }

  return (
    <ContentContainer>
      <ScrollToTopOnMount />
      {resource ? (
        <EditResourceForm
          initialResource={resource}
          onSubmit={handlePreSubmit}
          key={JSON.stringify(resource)}
        />
      ) : (
        <div>
          <h2>Muokkaa kalenteria</h2>
          <hr />
        </div>
      )}
      <Modal isOpen={modalOpen}>
        <ModalHeader toggle={toggleModal}>Vahvista muutokset</ModalHeader>
        <ModalBody>
          {resource && modifiedResource && (
            <ResourceDiffSummary existingResource={resource} modifiedResource={modifiedResource} />
          )}
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-secondary" disabled={isSaving} onClick={toggleModal}>
            Peruuta
          </button>
          <button className="btn btn-success" disabled={isSaving} onClick={handleSave}>
            Ota muutokset käyttöön
          </button>
        </ModalFooter>
      </Modal>
    </ContentContainer>
  );
};

export default withRouter(EditResource);
