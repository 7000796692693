import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as uuid from 'uuid';
import { VarauksetState } from '../../redux/store';
import { requestPasswordResetLinkAsync } from '../../redux/user';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import { validate, ValidationField, validEmailBuilder } from '../../validators';
import { ContentContainer } from '../ContentContainer';
import './NewPassword.scss';

interface NewPasswordDispatchProps {
  onRequestSendResetLink(email: string): void;
}

interface NewPasswordProps extends NewPasswordDispatchProps {}

interface NewPasswordFormState {
  email: ValidationField<string>;
}

interface NewPasswordState {
  form: NewPasswordFormState;
}

export class NewPassword extends React.Component<NewPasswordProps, NewPasswordState> {
  constructor(props: NewPasswordProps) {
    super(props);

    this.state = {
      form: {
        email: { value: '', validationResult: { valid: false }, touched: false },
      },
    };

    this.handleNewPasswordSubmit = this.handleNewPasswordSubmit.bind(this);
  }

  handleEmailChange(email: string) {
    this.setState((prevState: NewPasswordState) => {
      return {
        form: {
          ...prevState.form,
          email: this.createEmailValidated(email),
        },
      };
    });
  }

  createEmailValidated(email: string) {
    return {
      value: email,
      touched: true,
      validationResult: validate(email, [validEmailBuilder(true)]),
    };
  }

  handleNewPasswordSubmit(event: React.SyntheticEvent<any>) {
    event.preventDefault();
    this.props.onRequestSendResetLink(this.state.form.email.value);
  }

  render() {
    const form = this.state.form;
    const emailInputClasses = ['form-control'];

    if (form.email.validationResult.valid) {
      emailInputClasses.push('is-valid');
    } else {
      emailInputClasses.push('is-invalid');
    }

    const formValid = form.email.validationResult.valid;

    return (
      <ContentContainer style={{ maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }}>
        <ScrollToTopOnMount />
        <form id="v-new-password-form" onSubmit={this.handleNewPasswordSubmit} noValidate={true}>
          <h2>Tilaa salasanan vaihtolinkki</h2>

          <hr />

          <div className="form-group">
            <label htmlFor="login-email">Email</label>
            <input
              autoFocus={true}
              tabIndex={1}
              type="email"
              className={emailInputClasses.join(' ')}
              id="new-password-email"
              value={this.state.form.email.value}
              onChange={(e) => this.handleEmailChange(e.target.value)}
              placeholder="sähkö@posti.fi"
            />
            {form.email.touched &&
            !form.email.validationResult.valid &&
            form.email.validationResult.errorMessages &&
            form.email.validationResult.errorMessages.length ? (
              <div className="invalid-feedback">
                <ul>
                  {form.email.validationResult.errorMessages.map((msg) => (
                    <li key={uuid.v4()}>{msg}</li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
          <p className="form-text text-muted">
            Anna sähköpostiosoite, joka liittyy Varaukset.fi-tiliisi. Jos annettuun
            sähköpostiosoitteeseen liittyy käyttäjätili, lähetämme sähköpostiosoitteesen linkin,
            jota seuraamalla voi asettaa tilille uuden salasanan.
          </p>

          <button
            className="btn btn-primary btn-lg"
            type="submit"
            disabled={!formValid}
            tabIndex={4}
          >
            Tilaa salasanan vaihtolinkki
          </button>
        </form>
      </ContentContainer>
    );
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<VarauksetState, undefined, AnyAction>
): NewPasswordDispatchProps => ({
  onRequestSendResetLink: (email: string) => dispatch(requestPasswordResetLinkAsync(email)),
});

export default connect(null, mapDispatchToProps)(NewPassword);
