import * as joda from 'js-joda';
import * as uuid from 'uuid';
import { durationToStr, strToDuration, weekDayTimeRangesSorted } from '../utils';
import {
  AccountTransactionDto,
  AvailableTimeDto,
  CreateReservationDto,
  EditResourceDto,
  MyReservationsOrganizationDto,
  OrganizationDto,
  ReservationDto,
  ResourceDto,
  UserDto,
  WeekDayNum,
} from './dtos';
import {
  AccountTransaction,
  AccountTransactionType,
  CreateReservation,
  EditResource,
  MyReservations,
  Organization,
  PricingMode,
  RepeatMode,
  Reservation,
  ReservationMode,
  ReservationStatus,
  Resource,
  Rules,
  TimeRange,
  User,
  UserLevel,
  WeekDayTimeRanges,
} from './models';

export function dtoToOrganization(dto: OrganizationDto): Organization {
  return {
    address: dto.address,
    city: dto.city,
    description: dto.description,
    id: dto.id.toString(),
    name: dto.name,
    postalCode: dto.postal_code,
    slug: dto.slug,
    timeZone: dto.time_zone,
  };
}

export function dtosToOrganizations(dtos: OrganizationDto[]): Organization[] {
  return dtos.map((d) => dtoToOrganization(d));
}

function resourceDtoToRules(dto: ResourceDto): Rules {
  const rules: Rules = {
    reservationMinLen: strToDuration(dto.reservation_min_duration),
    reservationMaxLen: strToDuration(dto.reservation_max_duration),
    reservationAllowedDaysToFuture: dto.reservation_allowed_days_to_future,
    weekDayTimeRanges: {},
  };
  if (dto.reservation_delete_allowed_before_start) {
    rules.canDeleteBefore = strToDuration(dto.reservation_delete_allowed_before_start);
  }
  if (dto.available_times && dto.available_times.length > 0) {
    const availableTimes: {
      1?: TimeRange[];
      2?: TimeRange[];
      3?: TimeRange[];
      4?: TimeRange[];
      5?: TimeRange[];
      6?: TimeRange[];
      7?: TimeRange[];
    } = {};
    for (let availableTimeDto of dto.available_times) {
      const timeRange: TimeRange = {
        start: joda.LocalTime.parse(availableTimeDto.start_time),
        end: joda.LocalTime.parse(availableTimeDto.end_time),
      };
      const weekday = availableTimeDto.weekday;
      if (weekday in availableTimes) {
        (availableTimes[weekday] as TimeRange[]).push(timeRange);
      } else {
        availableTimes[weekday] = [timeRange];
      }
    }
    rules.weekDayTimeRanges = availableTimes;
  }

  rules.weekDayTimeRanges = weekDayTimeRangesSorted(rules.weekDayTimeRanges);
  return rules;
}

export function dtoToResource(dto: ResourceDto): Resource {
  function dtoReservationModeToEnum(s: string): ReservationMode {
    switch (s) {
      case 'anonymous':
        return ReservationMode.Anonymous;
      case 'email_confirmation':
        return ReservationMode.EmailConfirmation;
      case 'read_only':
        return ReservationMode.ReadOnly;
      case 'demo':
        return ReservationMode.Demo;
      case 'login_required':
        return ReservationMode.LoginRequired;
      default:
        throw new Error(`Unknown mode ${s}`);
    }
  }

  function dtoPricingModeToEnum(s?: string): PricingMode {
    switch (s) {
      case undefined:
      case 'hour':
        return PricingMode.ChargeByHour;
      case 'half_hour':
        return PricingMode.ChargeByHalfHour;
      default:
        throw new Error(`Unknown mode ${s}`);
    }
  }

  return {
    description: dto.description,
    id: dto.id.toString(),
    name: dto.name,
    reservationMode: dtoReservationModeToEnum(dto.reservation_mode),
    reservationDescriptionLabel: dto.reservation_description_label,
    reservationDescriptionPlaceholder: dto.reservation_description_placeholder,
    showReservationRulesOnUi: dto.show_reservation_rules_on_ui,
    showHourPriceOnUi: dto.show_hour_price_on_ui,
    organizationId: dto.organization.toString(),
    orderNumber: dto.order_number,
    rules: resourceDtoToRules(dto),
    price: dto.price,
    pricingMode: dtoPricingModeToEnum(dto.pricing_mode),
  };
}

export function dtosToResources(dtos: ResourceDto[]): Resource[] {
  return dtos.map((d) => dtoToResource(d));
}

export function resourceToEditResource(resource: Resource): EditResource {
  if (resource.rules === undefined) {
    throw new Error('Resource rules are undefined');
  }
  return {
    id: resource.id,
    organizationId: resource.organizationId,
    orderNumber: resource.orderNumber,
    name: resource.name,
    description: resource.description || '',
    reservationDescriptionLabel: resource.reservationDescriptionLabel,
    reservationDescriptionPlaceholder: resource.reservationDescriptionPlaceholder,
    showReservationRulesOnUi: resource.showReservationRulesOnUi,
    showHourPriceOnUi: resource.showHourPriceOnUi,
    reservationMode: resource.reservationMode,
    pricingMode: resource.pricingMode,
    price: resource.price,
    rules: resource.rules,
  };
}

export function resourceToDto(resource: EditResource): EditResourceDto {
  function reservationModeToDtoString(
    r: ReservationMode
  ): 'anonymous' | 'email_confirmation' | 'read_only' | 'demo' | 'login_required' {
    switch (r) {
      case ReservationMode.Anonymous:
        return 'anonymous';
      case ReservationMode.EmailConfirmation:
        return 'email_confirmation';
      case ReservationMode.ReadOnly:
        return 'read_only';
      case ReservationMode.LoginRequired:
        return 'login_required';
      default:
        throw new Error(`Unknown or illegal mode for edit ${r}`);
    }
  }

  function pricingModeToDtoString(p: PricingMode): 'hour' | 'half_hour' {
    switch (p) {
      case PricingMode.ChargeByHour:
        return 'hour';
      case PricingMode.ChargeByHalfHour:
        return 'half_hour';
      default:
        throw new Error(`Unknown pricing mode ${p}`);
    }
  }

  function weekdayTimeRangesToAvailableTimes(w: WeekDayTimeRanges): AvailableTimeDto[] {
    const availableTimes: AvailableTimeDto[] = [];
    for (const [weekDay, timeRanges] of Object.entries(w)) {
      for (const timeRange of timeRanges) {
        availableTimes.push({
          weekday: parseInt(weekDay) as WeekDayNum,
          start_time: timeRange.start.toString(),
          end_time: timeRange.end.toString(),
        });
      }
    }
    return availableTimes;
  }

  // map resource to dto
  return {
    description: resource.description || '',
    name: resource.name,
    reservation_mode: reservationModeToDtoString(resource.reservationMode),
    reservation_description_label: resource.reservationDescriptionLabel,
    reservation_description_placeholder: resource.reservationDescriptionPlaceholder,
    show_reservation_rules_on_ui: resource.showReservationRulesOnUi,
    show_hour_price_on_ui: resource.showHourPriceOnUi,
    order_number: resource.orderNumber,
    organization: parseInt(resource.organizationId),
    reservation_min_duration: resource.rules.reservationMinLen
      ? durationToStr(resource.rules.reservationMinLen)
      : '',
    reservation_max_duration: resource.rules.reservationMaxLen
      ? durationToStr(resource.rules.reservationMaxLen)
      : '',
    reservation_allowed_days_to_future: resource.rules.reservationAllowedDaysToFuture || null,
    reservation_delete_allowed_before_start: resource.rules.canDeleteBefore
      ? durationToStr(resource.rules.canDeleteBefore)
      : null,
    available_times: weekdayTimeRangesToAvailableTimes(resource.rules.weekDayTimeRanges),
    price: resource.price.toFixed(2),
    pricing_mode: pricingModeToDtoString(resource.pricingMode),
  };
}

export function dtoToReservation(dto: ReservationDto): Reservation {
  function dtoStateToStatus(s: string): ReservationStatus {
    switch (s) {
      case 'unconfirmed':
        return ReservationStatus.Unconfirmed;
      default:
        return ReservationStatus.Confirmed;
    }
  }

  function dtoRepeatModeToEnum(s?: 'weekly' | 'biweekly'): RepeatMode | undefined {
    switch (s) {
      case undefined:
      case null:
        return undefined;
      case 'weekly':
        return RepeatMode.Weekly;
      case 'biweekly':
        return RepeatMode.Biweekly;
      default:
        throw new Error(`Unknown repeat mode ${s}`);
    }
  }

  return {
    date: joda.LocalDate.parse(dto.date),
    repeatLastDate: dto.repeat_last_date ? joda.LocalDate.parse(dto.repeat_last_date) : undefined,
    repeatMode: dtoRepeatModeToEnum(dto.repeat_mode),
    repeating: !!dto.repeat,
    timeRange: {
      start: joda.LocalTime.parse(dto.start_time),
      end: joda.LocalTime.parse(dto.end_time),
    },
    description: dto.description,
    descriptionPublic: dto.description_public,
    // Instances of repeating reservations don't have unique db ids.
    id: dto.id ? dto.id.toString() : uuid.v4(),
    status: dtoStateToStatus(dto.state),
    resourceId: dto.resource.toString(),
    userId: dto.user ? dto.user.toString() : undefined,
    totalPricePaid: dto.total_price_paid,
    adminReservation: dto.admin_reservation,
  };
}

export function dtosToReservations(dtos: ReservationDto[]): Reservation[] {
  return dtos.map((d) => dtoToReservation(d));
}

export function reservationToDto(r: CreateReservation): CreateReservationDto {
  function repeatModeToDtoString(r: RepeatMode | undefined): 'weekly' | 'biweekly' | undefined {
    switch (r) {
      case undefined:
      case null:
        return undefined;
      case RepeatMode.Weekly:
        return 'weekly';
      case RepeatMode.Biweekly:
        return 'biweekly';
      default:
        throw new Error(`Unknown repeat mode ${r}`);
    }
  }

  return {
    date: r.date.toString(),
    description: r.description,
    description_public: r.descriptionPublic,
    email: r.email,
    start_time: r.timeRange.start.toString(),
    end_time: r.timeRange.end.toString(),
    repeat: r.repeating,
    repeat_last_date: r.repeatLastDate ? r.repeatLastDate.toString() : undefined,
    repeat_mode: repeatModeToDtoString(r.repeatMode),
    resource: parseInt(r.resourceId, 10),
    user: r.userId ? parseInt(r.userId, 10) : null,
    admin_reservation: r.adminReservation,
    total_price: r.totalPrice,
  };
}

export function dtoToUser(dto: UserDto): User {
  function dtoLevelToUserLevel(s: string): UserLevel {
    switch (s) {
      case 'user':
        return UserLevel.User;
      case 'superuser':
        return UserLevel.Superuser;
      default:
        throw Error('Unknown user level: ' + s);
    }
  }

  return {
    id: dto.pk.toString(),
    username: dto.username,
    email: dto.email,
    firstName: dto.first_name,
    lastName: dto.last_name,
    balance: dto.balance,
    profileUpdated: joda.ZonedDateTime.parse(dto.profile_updated_datetime),
    bookmarkedOrganizations: dto.bookmarked_organizations.map((o) => dtoToOrganization(o)),
    managedOrganizations: dto.managed_organizations.map((o) => dtoToOrganization(o)),
    level: dtoLevelToUserLevel(dto.level),
  };
}

export function dtosToMyReservations(dtos: MyReservationsOrganizationDto[]): MyReservations {
  const organizations = dtos.map((org) => ({
    organization: dtoToOrganization(org.organization),
    resources: org.resources.map((r) => ({
      resource: dtoToResource(r.resource),
      reservations: dtosToReservations(r.reservations),
    })),
  }));
  return {
    organizations,
  };
}

export function dtoToAccountTransaction(dto: AccountTransactionDto): AccountTransaction {
  function dtoTypeToEnum(s: string): AccountTransactionType {
    switch (s) {
      case 'admin_action':
        return AccountTransactionType.AdminAction;
      case 'credit_card':
        return AccountTransactionType.CreditCard;
      case 'reservation':
        return AccountTransactionType.Reservation;
      case 'reservation_cancel':
        return AccountTransactionType.ReservationCancel;
      default:
        throw new Error(`Unknown type ${s}`);
    }
  }

  return {
    amount: dto.amount,
    datetime: joda.ZonedDateTime.parse(dto.transaction_datetime),
    description: dto.description,
    type: dtoTypeToEnum(dto.type),
  };
}

export function dtosToAccountTransactions(dtos: AccountTransactionDto[]): AccountTransaction[] {
  return dtos.map((dto) => dtoToAccountTransaction(dto));
}
