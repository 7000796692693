// TODO: All local storage usage is not in this file

const visitedOrganizationKey = 'visitedOrg';

export type VisitedOrganization = {
  slug: string;
  name: string;
};

// This can take in an object that contains other fields that we don't want to serialize and save
// to local storage. E.g. input could be Organization type.
function organizationToVisitedOrganization(o: VisitedOrganization): VisitedOrganization {
  return { slug: o.slug, name: o.name };
}

export function setVisitedOrganization(o: VisitedOrganization) {
  localStorage.setItem(
    visitedOrganizationKey,
    JSON.stringify(organizationToVisitedOrganization(o))
  );
}

export function getVisitedOrganization(): VisitedOrganization | undefined {
  const serialized = localStorage.getItem(visitedOrganizationKey);
  if (serialized === null) {
    return undefined;
  }
  return JSON.parse(serialized) as VisitedOrganization;
}
