import diff from 'deep-diff';
import * as React from 'react';
import { Resource, Rules } from '../../models/models';

function kindToHuman(kind: 'N' | 'D' | 'E' | 'A') {
  switch (kind) {
    case 'E':
    case 'A': // added to array
    case 'N': // new
    case 'D': // deleted
      return 'Muokattu'; //  For now, the same for all
  }
}

function pathToHuman(path: any[] | undefined) {
  if (!path) {
    return '';
  }

  const keyTranslations: Omit<
    { [key in keyof Resource]: string } & { [key in keyof Rules]: string },
    'rules'
  > = {
    name: 'Nimi',
    description: 'Kuvaus',
    orderNumber: 'Järjestysnumero',
    reservationMode: 'Varaajan tunnistaminen',
    reservationDescriptionLabel: 'Varauksen lisätiedon otsikko',
    reservationDescriptionPlaceholder: 'Varauksen lisätiedon esimerkki',
    showReservationRulesOnUi: 'Näytä sääntötekstit',
    showHourPriceOnUi: 'Näytä tuntihinta',
    price: 'Hinta',
    pricingMode: 'Veloitustarkkuus',
    id: 'ID',
    reservationMaxLen: 'Varauksen maksimikesto',
    reservationMinLen: 'Varauksen minimikesto',
    reservationAllowedDaysToFuture: 'Kuinka kauas tulevaisuuteen varauksen saa tehdä',
    weekDayTimeRanges: 'Varattavat ajat',
    canDeleteBefore: 'Kuinka lähellä varauksen alkua varauksen saa poistaa',
    organizationId: 'OrganisaatioID',
  };

  const pathString = path.join('.').toLocaleLowerCase();
  for (const [key, translation] of Object.entries(keyTranslations)) {
    if (pathString.split('.').includes(key.toLocaleLowerCase())) {
      return translation;
    }
  }
  return pathString;
}

const ResourceDiffSummary: React.FC<{ existingResource: Resource; modifiedResource: Resource }> = (
  props
) => {
  const diffs = diff(props.existingResource, props.modifiedResource);
  if (!diffs) {
    return (
      <>
        <div>Ei muutoksia.</div>
        <small className="form-text text-muted">
          Esimerkiksi vapaiden aikojen määrittelyjärjestys on voinut vaihtua niin, että itse ajat
          ovat silti pysyneet samoina.
        </small>
      </>
    );
  }

  const uniqueDiffs: typeof diffs = [];
  diffs.forEach((diff) => {
    if (
      !uniqueDiffs.some(
        (d) =>
          kindToHuman(d.kind) === kindToHuman(diff.kind) &&
          pathToHuman(d.path) === pathToHuman(diff.path)
      )
    ) {
      uniqueDiffs.push(diff);
    }
  });

  return (
    <ul>
      {uniqueDiffs.map((diff) => (
        <li key={diff.kind + diff.path?.toString()}>
          {kindToHuman(diff.kind)}: {pathToHuman(diff.path)}
        </li>
      ))}
    </ul>
  );
};

export default ResourceDiffSummary;
