import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AccountTransaction, AccountTransactionType } from '../../models/models';
import { fetchMyAccountTransactionsAsync } from '../../redux/myAccountTransactions';
import { VarauksetState } from '../../redux/store';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import { jodaNow, SIMPLE_FI_DATETIME_FORMATTER } from '../../utils';
import { ContentContainer } from '../ContentContainer';

function accountTransactionDatetimeSorter(a: AccountTransaction, b: AccountTransaction): number {
  if (a.datetime.isAfter(b.datetime)) {
    return -1;
  }
  return 1;
}

interface MyAccountTransactionsStateProps {
  accountTransactions: AccountTransaction[];
}

interface MyAccountTransactionsDispatchProps {
  onFetchAccountTransactions(year: number): void;
}

interface MyAccountTransactionsProps
  extends MyAccountTransactionsStateProps,
    MyAccountTransactionsDispatchProps,
    RouteComponentProps {}

interface MyAccountTransactionsState {
  years: number[];
  selectedYear: number;
}

export class MyAccountTransactions extends React.Component<
  MyAccountTransactionsProps,
  MyAccountTransactionsState
> {
  constructor(props: MyAccountTransactionsProps) {
    super(props);
    const currentYear = jodaNow().year();
    const years: number[] = [];
    // 2018 is the earliest year any user can have transactions.
    for (let y = 2018; y <= currentYear; ++y) {
      years.push(y);
    }

    this.state = {
      selectedYear: currentYear,
      years,
    };
  }

  componentWillMount() {
    this.handleYearChange(this.state.selectedYear);
  }

  humanizeType(type: AccountTransactionType): string {
    switch (type) {
      case AccountTransactionType.AdminAction:
        return 'Ylläpitäjän toimi';
      case AccountTransactionType.CreditCard:
        return 'Lataus luottokortilla';
      case AccountTransactionType.Reservation:
        return 'Varaus';
      case AccountTransactionType.ReservationCancel:
        return 'Varauksen peruutus';

      default:
        throw Error('Unknown type ' + type);
    }
  }

  handleYearChange(year: number) {
    this.setState({
      selectedYear: year,
    });
    this.props.onFetchAccountTransactions(year);
  }

  render() {
    return (
      <ContentContainer>
        <ScrollToTopOnMount />
        <h2>Tapahtumat</h2>
        <hr />
        <button
          className="btn btn-secondary"
          type="button"
          onClick={this.props.history.goBack}
          tabIndex={1}
        >
          Takaisin
        </button>
        <p>Tässä näet kaikki tilisi saldoon vaikuttaneet tapahtumat valitulta vuodelta.</p>
        <div>
          <label htmlFor="exampleFormControlSelect1">Vuosi</label>
          <select
            className="form-control"
            id="exampleFormControlSelect1"
            value={this.state.selectedYear}
            onChange={(e) => this.handleYearChange(parseInt(e.target.value, 10))}
          >
            {this.state.years.map((y) => (
              <option key={y}>{y}</option>
            ))}
          </select>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Aika</th>
              <th scope="col">Tapahtuma</th>
              <th scope="col">Summa</th>
              <th scope="col">Kuvaus</th>
            </tr>
          </thead>
          <tbody>
            {this.props.accountTransactions.sort(accountTransactionDatetimeSorter).map((t) => (
              <tr key={t.datetime.toString()}>
                <td>{t.datetime.format(SIMPLE_FI_DATETIME_FORMATTER)}</td>
                <td>{this.humanizeType(t.type)}</td>
                <td>{`${t.amount.toFixed(2)} €`}</td>
                <td>{t.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContentContainer>
    );
  }
}

const mapStateToProps = (state: VarauksetState): MyAccountTransactionsStateProps => ({
  accountTransactions: state.myAccountTransactions.myAccountTransactions,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<VarauksetState, undefined, AnyAction>
): MyAccountTransactionsDispatchProps => ({
  onFetchAccountTransactions: (year: number) => dispatch(fetchMyAccountTransactionsAsync(year)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyAccountTransactions));
