import { Action, createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { startWait, stopWait } from './wait';
import * as api from '../apiClient';
import { AccountTransaction } from '../models/models';

// ACTIONS

export enum MyAccountTransactionsAction {
  RECEIVE_MY_TRANSACTIONS = 'varaukset/myAccountTransactions/RECEIVE_MY_TRANSACTIONS',
}

export const receiveMyTransactions = createAction<AccountTransaction[]>(
  MyAccountTransactionsAction.RECEIVE_MY_TRANSACTIONS
);

export function fetchMyAccountTransactionsAsync(year: number) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startWait());
    const apiDataResponse = await api.getMyAccountTransactions(year);
    dispatch(stopWait());
    if (apiDataResponse.success && apiDataResponse.data) {
      dispatch(receiveMyTransactions(apiDataResponse.data));
    } else {
      dispatch(receiveMyTransactions([]));
    }
  };
}

// REDUCER

export interface MyAccountTransactionsState {
  myAccountTransactions: AccountTransaction[];
}

const myAccountTransactionsInitialState: MyAccountTransactionsState = {
  myAccountTransactions: [],
};

export default function myAccountTransactionsReducer(
  state: MyAccountTransactionsState = myAccountTransactionsInitialState,
  action: Action<any>
): MyAccountTransactionsState {
  switch (action.type) {
    case MyAccountTransactionsAction.RECEIVE_MY_TRANSACTIONS:
      return {
        ...state,
        myAccountTransactions: action.payload,
      };

    default:
      return state;
  }
}
