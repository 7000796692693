import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { DateRange, LoadState, Organization, Resource } from '../models/models';
import { changeSelectedResourceAsync, fetchResourcesAsync } from '../redux/resource';
import { VarauksetState } from '../redux/store';
import './ResourceSelection.scss';

interface ResourceSelectionComponentProps {
  style?: React.CSSProperties;
}

interface ResourceSelectionStateProps {
  organization?: Organization | null;
  resources: Resource[];
  reservationsFetched: boolean;
  dateRange: DateRange;
}

interface ResourceSelectionDispatchProps {
  onSelectionChange(
    organization: Organization,
    resourceId: string | null,
    dateRange: DateRange,
    autoNavigate?: boolean
  ): void;
  onFetchResources(organizationId: string): void;
}

interface ResourceSelectionRouterProps {
  selectedResourceId: string;
}

interface ResourceSelectionProps
  extends ResourceSelectionComponentProps,
    ResourceSelectionStateProps,
    ResourceSelectionDispatchProps,
    ResourceSelectionRouterProps {}

export class ResourceSelection extends React.Component<ResourceSelectionProps> {
  constructor(props: ResourceSelectionProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.propsReceived = this.propsReceived.bind(this);

    this.propsReceived(props);
  }

  componentDidMount() {
    if (this.props.organization) {
      this.props.onFetchResources(this.props.organization.id);
    }
  }

  componentDidUpdate() {
    this.propsReceived(this.props);
  }

  propsReceived(newProps: ResourceSelectionProps) {
    if (
      newProps.organization &&
      newProps.selectedResourceId &&
      newProps.resources &&
      newProps.resources.length
    ) {
      if (newProps.resources.find((r) => r.id === newProps.selectedResourceId) === undefined) {
        this.props.onSelectionChange(newProps.organization, null, newProps.dateRange, true);
      }
    }

    // If no resource is selected, auto select first as the initial selection.
    // If reservations are not fetched for current selection. Re-select resource to init
    // fetch. (Most likely user came straight to this URL)

    if (newProps.organization && newProps.resources && newProps.resources.length) {
      if (!newProps.reservationsFetched && newProps.selectedResourceId) {
        this.props.onSelectionChange(
          newProps.organization,
          newProps.selectedResourceId,
          this.props.dateRange,
          true
        );
      } else if (!newProps.selectedResourceId) {
        this.props.onSelectionChange(
          newProps.organization,
          newProps.resources[0].id,
          this.props.dateRange,
          true
        );
      }
    }
  }

  handleClick(resourceId: string) {
    if (this.props.organization) {
      this.props.onSelectionChange(this.props.organization, resourceId, this.props.dateRange);
    }
  }

  render() {
    const renderResources = this.props.resources ? this.props.resources : [];
    return (
      <div style={this.props.style} className="v-resource-selection-container">
        <Nav pills={true} className="flex-wrap">
          {renderResources.map((r) => (
            <NavItem key={r.id}>
              <NavLink
                href="#"
                active={this.props.selectedResourceId === r.id}
                onClick={() => this.handleClick(r.id)}
              >
                {r.name}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </div>
    );
  }
}

const mapStateToProps = (state: VarauksetState): ResourceSelectionStateProps => ({
  organization: state.organization.organization,
  resources: state.resource.resources,
  dateRange: state.calendar.visibleDateRange,
  reservationsFetched: state.resource.reservationsFetchState !== LoadState.Unknown,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<VarauksetState, undefined, AnyAction>
): ResourceSelectionDispatchProps => ({
  onSelectionChange: (
    organization: Organization,
    resourceId: string | null,
    dateRange: DateRange,
    autoNavigate?: boolean
  ) => dispatch(changeSelectedResourceAsync(organization, resourceId, dateRange, autoNavigate)),
  onFetchResources: (organizationId: string) => dispatch(fetchResourcesAsync(organizationId)),
});

const ResourceSelectionConnected = connect(mapStateToProps, mapDispatchToProps)(ResourceSelection);

export default withRouter((props: RouteComponentProps<any> & ResourceSelectionComponentProps) => (
  <ResourceSelectionConnected selectedResourceId={props.match.params.resourceId} />
));
