import * as joda from 'js-joda';

export interface Range<T> {
  start: T;
  end: T;
}

export interface TimeRange extends Range<joda.LocalTime> {}
export interface OpenTimeRange extends Range<joda.LocalTime | undefined> {}
export interface DateRange extends Range<joda.LocalDate> {}
export interface OpenDateRange extends Range<joda.LocalDate | undefined> {}

export interface NullableDateAndTimeRange {
  date: joda.LocalDate | undefined;
  timeRange: OpenTimeRange;
}

export enum LoadState {
  Unknown,
  InProgress,
  Finished,
}

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface RegisterData {
  email: string;
  password1: string;
  password2: string;
}

export interface SetPasswordData {
  uid: string;
  resetToken: string;
  newPassword: string;
}

export enum UserLevel {
  User,
  Superuser,
}

export interface User {
  id: string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  balance: number;
  profileUpdated: joda.ZonedDateTime;
  bookmarkedOrganizations: Organization[];
  managedOrganizations: Organization[];
  level: UserLevel;
}

export interface Organization {
  id: string;
  slug: string;
  name: string;
  address?: string;
  postalCode?: string;
  city?: string;
  description?: string;
  timeZone: string;
}

export enum ReservationStatus {
  // Pre-reservation means client side only premiliminary reservation.
  // I.e. user can only see their own pre-prereservations.
  // Also, there can only be 0 or 1 pre-reservations at any given time for a user.
  // Pre-reservation is created/moved any time user clicks on the calendar.
  PreReservation,
  // Unconfirmed reservation is a reservation that is synced to server but is not yet e.g. paid.
  // Paid reservation slots have to be "reserved" before payment so that it can be guaranteed that
  // the reservation can be made when the payment has been made.
  Unconfirmed,
  Confirmed,
}

export enum RepeatMode {
  Weekly = 'weekly',
  Biweekly = 'biweekly',
}

export interface ReservationDatetimeRange {
  timeRange: TimeRange;
  date: joda.LocalDate;
  repeatLastDate?: joda.LocalDate;
  repeating: boolean;
  repeatMode?: RepeatMode;
}

export interface CreateReservation extends ReservationDatetimeRange {
  userId?: string;
  description: string;
  descriptionPublic: boolean;
  resourceId: string;
  email?: string;
  adminReservation: boolean;
  // Server calculates the final price, but checks that it matches to what client reports.
  // This way we get an error if for some reason client and server calculate different prices.
  // Error is better than charging user wrong price. Yet, client can not be the source of truth
  // for charge amount.
  totalPrice: number;
}

export interface Reservation extends ReservationDatetimeRange {
  id: string;
  userId?: string;
  description: string;
  descriptionPublic: boolean;
  resourceId: string;
  totalPricePaid: number;
  status: ReservationStatus;
  adminReservation: boolean;
}

export interface EditReservation {
  id: string;
  repeatLastDate: joda.LocalDate;
}

export enum CalendarMode {
  Week = 1,
  Month = 2,
}

// Keys are ISO day of week numbers, i.e. 1 == monday, ..., 7 == sunday
export type WeekDay = '1' | '2' | '3' | '4' | '5' | '6' | '7';
export type WeekDayTimeRanges = {
  [key in WeekDay]?: TimeRange[];
};

export interface Rules {
  weekDayTimeRanges: WeekDayTimeRanges;
  reservationMaxLen?: joda.Duration;
  reservationMinLen?: joda.Duration;
  reservationAllowedDaysToFuture?: number;
  canDeleteBefore?: joda.Duration;
}

export enum ReservationMode {
  Anonymous,
  EmailConfirmation,
  ReadOnly,
  Demo,
  LoginRequired,
}

export enum PricingMode {
  ChargeByHour,
  ChargeByHalfHour,
}

export interface ResourcePricing {
  price: number;
  pricingMode: PricingMode;
}

export interface Resource extends ResourcePricing {
  id: string;
  organizationId: string;
  orderNumber: number | null;
  name: string;
  reservationMode: ReservationMode;
  reservationDescriptionLabel: string;
  reservationDescriptionPlaceholder: string;
  showReservationRulesOnUi: boolean;
  showHourPriceOnUi: boolean;
  description?: string;
  rules?: Rules;
}

export interface EditResource extends ResourcePricing {
  id: string;
  organizationId: string;
  orderNumber: number | null;
  name: string;
  reservationMode: ReservationMode;
  reservationDescriptionLabel: string;
  reservationDescriptionPlaceholder: string;
  showReservationRulesOnUi: boolean;
  showHourPriceOnUi: boolean;
  description: string;
  rules: Rules;
}

export interface MyReservationsResource {
  resource: Resource;
  reservations: Reservation[];
}

export interface MyReservations {
  organizations: {
    organization: Organization;
    resources: MyReservationsResource[];
  }[];
}

export enum AccountTransactionType {
  AdminAction,
  CreditCard,
  Reservation,
  ReservationCancel,
}

export interface AccountTransaction {
  datetime: joda.ZonedDateTime;
  amount: number;
  type: AccountTransactionType;
  description: string;
}

export interface StripePaymentIntent {
  id: string;
  clientSecret: string;
}

export enum ReportType {
  Payments,
  Reservations,
}
