import 'moment/locale/fi';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import Admin from './components/admin/Admin';
import EditResource from './components/admin/EditResource';
import ConfirmEmail from './components/auth/ConfirmEmail';
import ConfirmReservation from './components/auth/ConfirmReservation';
import Login from './components/auth/Login';
import NewPassword from './components/auth/NewPassword';
import Register from './components/auth/Register';
import SetPassword from './components/auth/SetPassword';
import FullPageWaitIndicator from './components/FullPageWaitIndicator';
import LoadMoney from './components/myAccount/LoadMoney';
import MyAccount from './components/myAccount/MyAccount';
import MyAccountTransactions from './components/myAccount/MyAccountTransactions';
import MyReservationsView from './components/myAccount/MyReservationsView';
import ReservationDetails from './components/ReservationDetails/ReservationDetails';
import CreateReservation from './components/reserve/create/CreateReservation';
import LoggedInProtectedRoute from './components/routeComponents/LoggedInProtectedRoute';
import OrgRoute from './components/routeComponents/OrganizationRoute';
import ResourceModeRoute from './components/routeComponents/ResourceModeRoute';
import VarauksetFooter from './components/VarauksetFooter';
import VarauksetNavbar from './components/VarauksetNavbar';
import backgroundImg from './images/calendar-bg-medium.jpg';
import MetaTag from './MetaTag';
import ResourceCalendar from './ResourceCalendar';
import * as paths from './routerPaths';

export const App: React.FC = () => {
  const backgroundWrapperStyle = {
    minHeight: '100vh',
    background: `url(${backgroundImg}) #d3f2e3 no-repeat center center`,
    backgroundSize: 'cover',
  };

  return (
    <div>
      <div style={backgroundWrapperStyle}>
        <MetaTag name="robots" content="noindex" />
        <VarauksetNavbar />
        <div className="container v-container">
          <ToastContainer />
          <div style={{ paddingBottom: '60px' }}>
            <Switch>
              <Route
                exact={true}
                path={'/error-test'}
                component={() => {
                  throw Error('test error');
                }}
              />
              <Route exact={true} path={paths.newPasswordUrl} component={NewPassword} />
              <Route exact={true} path={paths.setPasswordTemplate} component={SetPassword} />
              <Route
                exact={true}
                path={paths.confirmReservationTemplate}
                component={ConfirmReservation}
              />
              <Route exact={true} path={paths.confirmEmailTemplate} component={ConfirmEmail} />
              <Route
                exact={true}
                path={paths.loginUrl}
                render={() => <Login redirectAfterLoginUrl={paths.myAccountUrl} />}
              />
              <Route exact={true} path={paths.registerUrl} component={Register} />
              <LoggedInProtectedRoute
                exact={true}
                path={paths.myAccountUrl}
                component={MyAccount}
              />
              {/* TODO: Fix as any */}
              <LoggedInProtectedRoute
                exact={true}
                path={paths.myReservationsUrl}
                component={MyReservationsView as any}
              />
              <LoggedInProtectedRoute
                exact={true}
                path={paths.myAccountTransactionsUrl}
                component={MyAccountTransactions as any}
              />
              <LoggedInProtectedRoute
                exact={true}
                path={paths.loadMoneyUrl}
                component={LoadMoney}
              />
              {/* TODO: Manager user protected route */}
              <LoggedInProtectedRoute exact={true} path={paths.adminUrl} component={Admin} />
              <LoggedInProtectedRoute
                exact={true}
                path={paths.resourceEditTemplate}
                component={EditResource}
              />
              <OrgRoute
                exact={true}
                path={paths.organizationMainTemplate}
                component={ResourceCalendar}
              />
              <OrgRoute exact={true} path={paths.resourceTemplate} component={ResourceCalendar} />
              <OrgRoute
                exact={true}
                path={paths.reserveResourceTemplate}
                render={(props) => (
                  <ResourceModeRoute
                    exact={true}
                    path={paths.reserveResourceTemplate}
                    component={CreateReservation as any}
                  />
                )}
              />
              <OrgRoute
                exact={true}
                path={paths.reservationDetailsTemplate}
                render={(props) => (
                  <LoggedInProtectedRoute
                    exact={true}
                    path={paths.reservationDetailsTemplate}
                    component={ReservationDetails as any}
                  />
                )}
              />
              <Redirect to={paths.myAccountUrl} />
            </Switch>
          </div>
        </div>
      </div>
      <VarauksetFooter />
      <FullPageWaitIndicator />
    </div>
  );
};

export default App;
