import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as joda from 'js-joda';
import { noop } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import {
  Reservation as ReservationModel,
  ReservationStatus,
  Resource,
  User,
} from '../../models/models';
import { VarauksetState } from '../../redux/store';
import * as paths from '../../routerPaths';
import { isUserAdmin, jodaNow, SIMPLE_FI_TIME_FORMATTER } from '../../utils';
import './ReservationComponent.scss';

function isMyReservation(reservation: { userId?: string; email?: string }, user?: User) {
  if (user) {
    if (reservation.userId) {
      return reservation.userId === user.id;
    } else if (reservation.email) {
      return reservation.email === user.email;
    }
  }
  return false;
}

interface ReservationComponentProps {
  now?: joda.LocalDateTime;
  dayStartTime: joda.LocalTime;
  hourRemHeight: number;
  reservation: ReservationModel;
}

interface ReservationStateProps {
  selectedResource?: Resource;
  user?: User;
}

interface ReservationRouterProps extends RouteComponentProps {
  organizationSlug: string;
  selectedResourceId?: string;
}

interface ReservationProps
  extends ReservationComponentProps,
    ReservationStateProps,
    ReservationRouterProps {}

export const ReservationComponent: React.FC<ReservationProps> = (props) => {
  const now = props.now || jodaNow();
  const hoursFromTop =
    joda.Duration.between(props.dayStartTime, props.reservation.timeRange.start).toMinutes() /
    joda.LocalTime.MINUTES_PER_HOUR;
  const hoursLength =
    joda.Duration.between(
      props.reservation.timeRange.start,
      props.reservation.timeRange.end
    ).toMinutes() / joda.LocalTime.MINUTES_PER_HOUR;

  const myReservation = isMyReservation(props.reservation, props.user);
  const isAdminUser = isUserAdmin(props.user, props.organizationSlug);
  const classNames = ['v-reservation'];
  if (myReservation) {
    classNames.push('v-reservation-primary');
  }
  if (props.reservation.date.atTime(props.reservation.timeRange.end).isBefore(now)) {
    classNames.push('v-reservation-past');
  }
  if (props.reservation.status === ReservationStatus.Unconfirmed) {
    classNames.push('v-reservation-unconfirmed');
  }
  if (props.reservation.status === ReservationStatus.PreReservation) {
    classNames.push('v-reservation-pre');
  }
  if (!isAdminUser && !myReservation) {
    classNames.push('no-hover');
  }

  function handleReservationDetailsClick() {
    if (props.selectedResource) {
      props.history.push(
        paths.getReservationDetailsUrl(
          props.organizationSlug,
          props.selectedResource.id,
          props.reservation.id
        )
      );
    }
  }

  function handlePreReservationClick() {
    if (props.selectedResource) {
      props.history.push(
        paths.getReserveResourceUrl(props.organizationSlug, props.selectedResource.id)
      );
    }
  }

  const viewableDetails =
    props.reservation.status !== ReservationStatus.PreReservation && (myReservation || isAdminUser);

  let descriptionClass = 'v-desc-hidden';
  if (props.reservation.descriptionPublic) {
    descriptionClass = 'v-desc-visible';
  } else if (myReservation || isAdminUser) {
    descriptionClass = 'v-desc-visible-hidden';
  }

  return (
    <div
      onClick={viewableDetails ? handleReservationDetailsClick : noop}
      className={classNames.join(' ')}
      style={{
        position: 'absolute',
        height: `${hoursLength * props.hourRemHeight - 0.125}rem`,
        top: `${hoursFromTop * props.hourRemHeight}rem`,
        left: 0,
      }}
    >
      {props.reservation.status === ReservationStatus.PreReservation ? (
        <div
          onClick={handlePreReservationClick}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <FontAwesomeIcon icon={faPlus} size="2x" />
        </div>
      ) : (
        <div>
          <div className="d-none d-md-block" style={{ fontWeight: 'bold' }}>
            {`${props.reservation.timeRange.start.format(
              SIMPLE_FI_TIME_FORMATTER
            )} - ${props.reservation.timeRange.end.format(SIMPLE_FI_TIME_FORMATTER)}`}
          </div>
          <div className={descriptionClass}>{props.reservation.description}</div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (
  state: VarauksetState,
  ownProps: ReservationRouterProps & ReservationComponentProps
): ReservationStateProps => ({
  selectedResource:
    state.resource.resources &&
    state.resource.resources.find((r) => r.id === ownProps.selectedResourceId),
  user: state.user.activeUser,
});

const ReservationConnected = connect(mapStateToProps)(ReservationComponent);

export default withRouter((props: ReservationComponentProps & RouteComponentProps<any>) => {
  return (
    <ReservationConnected
      {...props}
      selectedResourceId={props.match.params.resourceId}
      organizationSlug={props.match.params.organizationSlug}
    />
  );
});
