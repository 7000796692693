import * as joda from 'js-joda';
import moment from 'moment';
import * as momentTz from 'moment-timezone';
import {
  DateRange,
  PricingMode,
  RepeatMode,
  ReservationDatetimeRange,
  ResourcePricing,
  Rules,
  TimeRange,
  User,
  UserLevel,
  WeekDay,
  WeekDayTimeRanges,
} from './models/models';

export function prodEnv() {
  return process.env.REACT_APP_ENV === 'production';
}

export function devEnv() {
  return process.env.REACT_APP_ENV === 'development';
}

export function stagingEnv() {
  return process.env.REACT_APP_ENV === 'staging';
}

export const SIMPLE_FI_TIME_FORMATTER = joda.DateTimeFormatter.ofPattern('HH.mm');
export const SIMPLE_FI_DATE_FORMATTER = joda.DateTimeFormatter.ofPattern('dd.MM.yyyy');
export const SIMPLE_FI_DATETIME_FORMATTER = joda.DateTimeFormatter.ofPattern('dd.MM.yyyy HH.mm');
export const SIMPLE_ISO_TIME_FORMATTER = joda.DateTimeFormatter.ofPattern('HH:mm');
export const SIMPLE_ISO_DATE_FORMATTER = joda.DateTimeFormatter.ofPattern('yyyy-MM-dd');

export function timeRangeToString(tr: TimeRange): string {
  return `'${tr.start.format(SIMPLE_ISO_TIME_FORMATTER)}->${tr.end.format(
    SIMPLE_ISO_TIME_FORMATTER
  )}'`;
}

export function timeRangeListToString(t: TimeRange[]): string {
  return `[${t.map((o) => timeRangeToString(o)).join(', ')}]`;
}

export function getDefaultResourceAvailableTimeRange(): TimeRange {
  return {
    start: joda.LocalTime.of(8),
    end: joda.LocalTime.of(22),
  };
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function dayOfWeekNumberISO(dayOfWeek: joda.DayOfWeek): 1 | 2 | 3 | 4 | 5 | 6 | 7 {
  switch (dayOfWeek) {
    case joda.DayOfWeek.MONDAY:
      return 1;
    case joda.DayOfWeek.TUESDAY:
      return 2;
    case joda.DayOfWeek.WEDNESDAY:
      return 3;
    case joda.DayOfWeek.THURSDAY:
      return 4;
    case joda.DayOfWeek.FRIDAY:
      return 5;
    case joda.DayOfWeek.SATURDAY:
      return 6;
    case joda.DayOfWeek.SUNDAY:
      return 7;
    default:
      throw Error();
  }
}

export function weekRangeForDate(date: joda.LocalDate): DateRange {
  const daysFromMonday =
    dayOfWeekNumberISO(date.dayOfWeek()) - dayOfWeekNumberISO(joda.DayOfWeek.MONDAY);
  const daysToSunday =
    dayOfWeekNumberISO(joda.DayOfWeek.SUNDAY) - dayOfWeekNumberISO(date.dayOfWeek());
  return {
    start: date.minusDays(daysFromMonday),
    end: date.plusDays(daysToSunday),
  };
}

export function isTimeBetween(
  time: joda.LocalTime,
  timeRange: TimeRange,
  granularity?: momentTz.unitOfTime.StartOf,
  inclusivity?: '()' | '[)' | '(]' | '[]'
) {
  // Use moment to get inclusivity parameter from library.
  // To use moment we need to introduce date part to times.
  // Use always current date; when it is the same for all times, it doesn't matter.
  const nowDate = jodaNow().toLocalDate();
  const mTime = moment(nowDate.atTime(time).toString());
  const mStart = moment(nowDate.atTime(timeRange.start).toString());
  const mEnd = moment(nowDate.atTime(timeRange.end).toString());
  return mTime.isBetween(mStart, mEnd, granularity, inclusivity || '[]');
}

// Inclusive
export function isDateBetween(date: joda.LocalDate, dateRange: DateRange) {
  return !date.isAfter(dateRange.end) && !date.isBefore(dateRange.start);
}

export function timeRangesIntersect(tr1: TimeRange, tr2: TimeRange) {
  return (
    isTimeBetween(tr1.start, tr2, undefined, '[)') ||
    isTimeBetween(tr1.end, tr2, undefined, '(]') ||
    isTimeBetween(tr2.start, tr1, undefined, '[)') ||
    isTimeBetween(tr2.end, tr1, undefined, '(]')
  );
}

export function checkDateRangeValid(dateRange: DateRange): void {
  if (dateRange.start.isAfter(dateRange.end)) {
    throw new Error('Illegal date range, start > end.');
  }
}

export function atleastOneTrue(funcs: Function[]) {
  for (let func of funcs) {
    if (func()) {
      return true;
    }
  }
  return false;
}

export function intersectionTimeRange(tr1: TimeRange, tr2: TimeRange): TimeRange | null {
  const raw: TimeRange = {
    start: getMaxTime(tr1.start, tr2.start),
    end: getMinTime(tr1.end, tr2.end),
  };
  if (raw.end.isBefore(raw.start)) {
    return null;
  }
  return raw;
}

export function timeRangeMinusTimeRanges(tr: TimeRange, trs: TimeRange[]): TimeRange[] {
  if (trs.length === 0) {
    return [tr];
  }
  let disabledTimeRanges = xorTimeRange(tr, trs[0]);
  if (trs.length > 1) {
    for (let i = 1; i < trs.length; ++i) {
      const availableTimeRange = trs[i];
      let newDisabled: TimeRange[] = [];
      for (const existingDisabled of disabledTimeRanges.slice()) {
        const newPartialDisabled = xorTimeRange(existingDisabled, availableTimeRange);
        newDisabled = newDisabled.concat(
          newPartialDisabled.filter((npd) => !equalTimeRanges(npd, availableTimeRange))
        );
      }
      disabledTimeRanges = newDisabled;
    }
  }
  return disabledTimeRanges;
}

export function equalTimeRanges(tr1: TimeRange, tr2: TimeRange): boolean {
  return tr1.start.equals(tr2.start) && tr1.end.equals(tr2.end);
}

export function xorTimeRange(tr1: TimeRange, tr2: TimeRange): TimeRange[] {
  if (!timeRangesIntersect(tr1, tr2)) {
    // I---I
    //       I---I
    return [tr1, tr2];
  }
  let result: TimeRange[] = [];
  if (tr1.start.isBefore(tr2.start)) {
    // I-
    //    I-
    if (tr1.end.isBefore(tr2.end)) {
      // I--------I
      //    I-------I
      result = [
        { start: tr1.start, end: tr2.start },
        { start: tr1.end, end: tr2.end },
      ];
    } else {
      // I--------I
      //    I----I
      result = [
        { start: tr1.start, end: tr2.start },
        { start: tr2.end, end: tr1.end },
      ];
    }
  } else {
    //    I-
    // I-
    if (tr2.end.isBefore(tr1.end)) {
      //    I-----I
      // I------I
      result = [
        { start: tr2.start, end: tr1.start },
        { start: tr2.end, end: tr1.end },
      ];
    } else {
      //    I-----I
      // I----------I
      result = [
        { start: tr2.start, end: tr1.start },
        { start: tr1.end, end: tr2.end },
      ];
    }
  }
  return result.filter((tr) => tr.start.isBefore(tr.end));
}

export function getVisibleTimes(
  date: joda.LocalDate | undefined,
  rules?: Rules,
  defaultTimeRange?: TimeRange
): TimeRange[] {
  if (date === undefined) {
    return [];
  }
  if (!defaultTimeRange) {
    defaultTimeRange = getDefaultResourceAvailableTimeRange();
  }
  let weekDayTimeRanges: TimeRange[] = [];
  if (rules && rules.weekDayTimeRanges) {
    const isoDayOfWeek = dayOfWeekNumberISO(date.dayOfWeek());
    if (rules.weekDayTimeRanges[isoDayOfWeek]) {
      weekDayTimeRanges = rules.weekDayTimeRanges[isoDayOfWeek] as TimeRange[];
    }
  } else {
    weekDayTimeRanges = [defaultTimeRange];
  }
  return weekDayTimeRanges;
}

export function reservationDefaultLength(rules?: Rules): joda.Duration {
  let reservationLength = joda.Duration.ofHours(1);
  if (rules) {
    if (
      rules.reservationMinLen &&
      rules.reservationMinLen.toMillis() > reservationLength.toMillis()
    ) {
      reservationLength = rules.reservationMinLen;
    } else if (
      rules.reservationMaxLen &&
      rules.reservationMaxLen.toMillis() < reservationLength.toMillis()
    ) {
      reservationLength = rules.reservationMaxLen;
    }
  }
  return reservationLength;
}

export function formatDuration(duration: joda.Duration, f?: string): string {
  const d = momentTz.duration(duration.toMillis(), 'milliseconds');
  let formatStr = f;
  const asMinutes = d.asMinutes();
  if (!formatStr) {
    if (asMinutes === 60) {
      formatStr = '[tunnin]';
    } else if (asMinutes < 24 * 60 && asMinutes % 60 === 0) {
      formatStr = 'H [tunnin]';
    } else if (asMinutes < 60) {
      formatStr = 'm [minuutin]';
    } else if (asMinutes > 60 && asMinutes < 24 * 60) {
      formatStr = 'H [tunnin] m [minuutin]';
    } else {
      return `${d.asHours()} tunnin`;
    }
  }
  return momentTz.utc(d.asMilliseconds()).format(formatStr);
}

export function formatDuration2(duration: joda.Duration, f?: string): string {
  const d = momentTz.duration(duration.toMillis(), 'milliseconds');
  let formatStr = f;
  const asMinutes = d.asMinutes();
  if (!formatStr) {
    if (asMinutes === 60) {
      formatStr = '[tunti]';
    } else if (asMinutes < 24 * 60 && asMinutes % 60 === 0) {
      formatStr = 'H [tuntia]';
    } else if (asMinutes < 60) {
      formatStr = 'm [minuuttia]';
    } else if (asMinutes > 60 && asMinutes < 24 * 60) {
      formatStr = 'H [tuntia] m [minuuttia]';
    } else if (asMinutes < 2 * 24 * 60) {
      return `${d.asHours()} tuntia`;
    } else {
      return `${d.asDays()} vuorokautta`;
    }
  }
  return momentTz.utc(d.asMilliseconds()).format(formatStr);
}

// Returns: -1 if reservation in past, 0 if on going, 1 if in the future.
// For repeating: -1 if all instances in the past, 1 if all instances in the future, else 0.
export function isReservationNowPastOrComing(
  reservation: ReservationDatetimeRange,
  now: joda.LocalDateTime
) {
  const reservationStart = reservation.date.atTime(reservation.timeRange.start);
  if (now.isBefore(reservationStart)) {
    return 1;
  }

  if (reservation.repeating) {
    if (!reservation.repeatLastDate) {
      return 0;
    } else {
      const repeatingReservationEnd = reservation.repeatLastDate.atTime(reservation.timeRange.end);
      if (now.isBefore(repeatingReservationEnd)) {
        return 0;
      }
      return -1;
    }
  }

  const reservationEnd = reservation.date.atTime(reservation.timeRange.end);

  if (now.isAfter(reservationEnd)) {
    return -1;
  } else {
    return 0;
  }
}

export function getMaxTime(t1: joda.LocalTime, t2: joda.LocalTime): joda.LocalTime {
  if (t1.isAfter(t2)) {
    return t1;
  }
  return t2;
}

export function getMinTime(t1: joda.LocalTime, t2: joda.LocalTime): joda.LocalTime {
  if (t1.isBefore(t2)) {
    return t1;
  }
  return t2;
}

export function timeFloor(t: joda.LocalTime): joda.LocalTime {
  return t.withMinute(0).withSecond(0).withNano(0);
}

export function timeCeil(t: joda.LocalTime): joda.LocalTime {
  const floor = timeFloor(t);
  if (floor.equals(t)) {
    return t;
  }
  return floor.plusHours(1);
}

export function jodaNow(): joda.LocalDateTime {
  // This is the timezone of the target organization, not the person making reservation.
  // TODO: Add support for other timezones.
  const m = momentTz.utc().tz('Europe/Helsinki');
  const s = m.format('YYYY-MM-DDTHH:mm:ss.SSS');
  const j = joda.LocalDateTime.parse(s);
  return j;
}

// Parse string formats of e.g. "02:00:00".
// Joda doesn't have own parser for this, so we use moment to convert.
export function strToDuration(s: string): joda.Duration {
  return joda.Duration.ofMillis(momentTz.duration(s).asMilliseconds());
}

function secondsToDaysHoursMinutesSeconds(seconds: number): {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
} {
  const days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;
  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;
  return { days, hours, minutes, seconds };
}

// Convert joda.Duration to format "DD HH:mm:ss" that is used by our server.
export function durationToStr(duration: joda.Duration): string {
  function toTwoDigits(n: number): string {
    return n.toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }
  const { days, hours, minutes, seconds } = secondsToDaysHoursMinutesSeconds(duration.seconds());
  const d = days || '';
  const h = toTwoDigits(hours);
  const m = toTwoDigits(minutes);
  const s = toTwoDigits(seconds);
  return `${d} ${h}:${m}:${s}`.trim();
}

export function concatArrays<T>(arrays: Array<Array<T> | null | undefined>): T[] {
  let result: T[] = [];
  for (let array of arrays) {
    if (array && array.length) {
      result = result.concat(array);
    }
  }
  return result;
}

export function getReservationTotalPrice(resource: ResourcePricing, timeRange: TimeRange): number {
  if (resource.price > 0) {
    const end = timeRange.end;
    const start = timeRange.start;
    const duration = joda.Duration.between(start, end);
    const rawHours = duration.toMinutes() / joda.LocalTime.MINUTES_PER_HOUR;
    let chargedHours = 0;
    if (resource.pricingMode === PricingMode.ChargeByHour) {
      chargedHours = Math.ceil(rawHours);
    } else if (resource.pricingMode === PricingMode.ChargeByHalfHour) {
      chargedHours = Math.ceil(rawHours * 2) / 2;
    } else {
      throw Error(`Unknown pricing mode ${resource.pricingMode}`);
    }
    return +(chargedHours * resource.price).toFixed(2);
  }
  return 0;
}

export function getDecimalPlaces(n: number) {
  // Make sure it is a number and use the builtin number -> string.
  var s = '' + +n;
  // Pull out the fraction and the exponent.
  var match = /(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/.exec(s);
  // NaN or Infinity or integer.
  // We arbitrarily decide that Infinity is integral.
  if (!match) {
    return 0;
  }
  // Count the number of digits in the fraction and subtract the
  // exponent to simulate moving the decimal point left by exponent places.
  // 1.234e+2 has 1 fraction digit and '234'.length -  2 == 1
  // 1.234e-2 has 5 fraction digit and '234'.length - -2 == 5
  return Math.max(
    0, // lower limit.
    // @ts-ignore
    // tslint:disable-next-line:triple-equals
    (match[1] === '0' ? 0 : (match[1] || '').length) - (match[2] || 0) // fraction length
  ); // exponent
}

export function replaceAll(s: string, find: string, replace: string) {
  return s.split(find).join(replace);
}

export function stringToFloat(s: string, decimalSeparator: string = ',') {
  let str = replaceAll(s, ',', '.');
  str = replaceAll(str, ' ', '');
  // @ts-ignore
  const c1 = str - 0;
  const c2 = parseFloat(str);

  if (isNaN(c1) || isNaN(c2) || c1 !== c2) {
    return undefined;
  }
  return c1;
}

export function getFilenameFromHeaders(headersDict: any): string {
  let filename = '';
  let disposition = '';
  if ('content-disposition' in headersDict) {
    disposition = headersDict['content-disposition'];
  }
  if (disposition && disposition.indexOf('attachment') !== -1) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
}

export function reservationActiveOnDate(
  reservation: ReservationDatetimeRange,
  date: joda.LocalDate
) {
  if (!reservation.repeating) {
    return reservation.date.equals(date);
  }

  let correctWeek = true;
  if (reservation.repeatMode === RepeatMode.Biweekly) {
    correctWeek =
      isEvenWeekFrom2000FirstMonday(reservation.date) === isEvenWeekFrom2000FirstMonday(date);
  }
  if (!correctWeek) {
    return false;
  }

  if (!reservation.repeatLastDate) {
    return !reservation.date.isAfter(date) && reservation.date.dayOfWeek().equals(date.dayOfWeek());
  } else {
    return (
      isDateBetween(date, { start: reservation.date, end: reservation.repeatLastDate }) &&
      reservation.date.dayOfWeek().equals(date.dayOfWeek())
    );
  }
}

export function isUserAdmin(user: User | undefined, orgIdOrSlug?: string): boolean {
  if (!user) {
    return false;
  }
  if (user.level === UserLevel.Superuser) {
    return true;
  }
  if (orgIdOrSlug) {
    return (
      user.managedOrganizations.findIndex((o) => o.id === orgIdOrSlug || o.slug === orgIdOrSlug) >
      -1
    );
  } else {
    return user.managedOrganizations.length > 0;
  }
}

const firstMondayOf2000 = joda.LocalDate.parse('2000-01-03');

export function isEvenWeekFrom2000FirstMonday(date: joda.LocalDate): boolean {
  const daysBetween = joda.ChronoUnit.DAYS.between(firstMondayOf2000, date);
  return Math.floor(daysBetween / 7) % 2 === 0;
}

export function classNames(
  ...args: Array<
    | false
    | undefined
    | null
    | string
    | Array<string>
    | { [key: string]: boolean | undefined | null }
  >
): string {
  var classes: string[] = [];

  for (var i = 0; i < args.length; i++) {
    var arg = args[i];
    if (!arg) continue;

    if (typeof arg === 'string') {
      classes.push(arg);
    } else if (Array.isArray(arg)) {
      classes.push(...arg);
    } else if (typeof arg === 'object') {
      if (arg.toString === Object.prototype.toString) {
        for (var [key, value] of Object.entries(arg)) {
          if (value) {
            classes.push(key);
          }
        }
      } else {
        classes.push(arg.toString());
      }
    }
  }

  return classes.join(' ');
}

export function formatPrice(value: number | undefined, emptyValue: string = '') {
  if (value === undefined) {
    return emptyValue;
  }
  return `${value.toFixed(2)} €`;
}

export function weekDayTimeRangesSorted(weekDayTimeRanges: WeekDayTimeRanges): WeekDayTimeRanges {
  const result: WeekDayTimeRanges = {};
  Object.entries(weekDayTimeRanges).forEach(([weekday, timeRanges]) => {
    result[weekday as WeekDay] = timeRanges.sort((a, b) => a.start.compareTo(b.start));
  });
  return result;
}
