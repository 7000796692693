import * as _ from 'lodash';
import * as React from 'react';
import { requiredString, validate, ValidationField } from '../../../validators';

function getMyDescriptionKey(resourceId: string) {
  return `my-description-${resourceId}`;
}

interface DescriptionComponentProps {
  resourceId?: string;
  label: string;
  placeholder: string;
  onDescriptionChange: (d: string) => void;
  onValidityChange: (v: boolean) => void;
  onDescriptionPublicChange: (p: boolean) => void;
}

interface DescriptionProps extends DescriptionComponentProps {}

interface DescriptionState {
  userHasTouchedForm: boolean;
  description: ValidationField<string>;
  descriptionPublic: boolean;
}

export default class DescriptionField extends React.Component<DescriptionProps, DescriptionState> {
  constructor(props: DescriptionProps) {
    super(props);

    this.state = {
      description: { value: '', validationResult: { valid: false } },
      descriptionPublic: false,
      userHasTouchedForm: false,
    };

    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleDescriptionPublicChange = this.handleDescriptionPublicChange.bind(this);
  }

  componentDidMount() {
    this.setInitialState();
    this.communicateChangesIfNeeded();
  }

  componentDidUpdate(prevProps: DescriptionProps, prevState: DescriptionState) {
    if (!_.isEqual(prevProps, this.props)) {
      if (!this.state.userHasTouchedForm) {
        this.setInitialState();
      }
    }
    if (!_.isEqual(prevState, this.state)) {
      this.communicateChangesIfNeeded();
    }
  }

  setInitialState() {
    let description = '';
    if (this.props.resourceId) {
      description = localStorage.getItem(getMyDescriptionKey(this.props.resourceId)) || '';
    }
    this.setState({
      userHasTouchedForm: false,
      description: this.createDescriptionValidated(description),
      descriptionPublic: true,
    });
  }

  isValid() {
    return this.state.description.validationResult.valid;
  }

  communicateChangesIfNeeded() {
    this.props.onDescriptionChange(this.state.description.value);
    this.props.onDescriptionPublicChange(this.state.descriptionPublic);
    this.props.onValidityChange(this.isValid());
  }

  communicateUserTouchedIfNeeded() {
    if (!this.state.userHasTouchedForm) {
      this.setState({ userHasTouchedForm: true });
    }
  }

  createDescriptionValidated(description: string) {
    return {
      value: description,
      validationResult: validate(description, [requiredString]),
    };
  }

  handleDescriptionChange(description: string) {
    const validated = this.createDescriptionValidated(description);
    this.setState({
      description: validated,
    });
    if (validated.validationResult.valid && this.props.resourceId) {
      localStorage.setItem(getMyDescriptionKey(this.props.resourceId), description);
    }
    this.communicateChangesIfNeeded();
    this.communicateUserTouchedIfNeeded();
  }

  handleDescriptionPublicChange(checked: boolean) {
    this.setState({ descriptionPublic: checked });
    this.communicateChangesIfNeeded();
    this.communicateUserTouchedIfNeeded();
  }

  render() {
    const { description } = this.state;
    const descriptionInputClasses = ['form-control'];

    if (this.state.description.validationResult.valid) {
      descriptionInputClasses.push('is-valid');
    } else {
      descriptionInputClasses.push('is-invalid');
    }

    return (
      <>
        <div className="v-reservation-description-container">
          <label htmlFor="reservation-description">{this.props.label}</label>
          <input
            autoFocus={true}
            tabIndex={4}
            type="text"
            className={descriptionInputClasses.join(' ')}
            id="reservation-description"
            value={this.state.description.value}
            onChange={(e) => this.handleDescriptionChange(e.target.value)}
            placeholder={this.props.placeholder}
          />
          {!description.validationResult.valid &&
          description.validationResult.errorMessages &&
          description.validationResult.errorMessages.length ? (
            <div className="invalid-feedback">
              <ul>
                {description.validationResult.errorMessages.map((msg) => (
                  <li key={msg}>{msg}</li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
        <div className="form-check">
          <input
            tabIndex={5}
            id="description-check"
            className="form-check-input"
            type="checkbox"
            checked={this.state.descriptionPublic}
            onChange={(e) => this.handleDescriptionPublicChange(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="description-check">
            Kentän tieto näkyvillä muille kalenterin käyttäjille
          </label>
        </div>
      </>
    );
  }
}
