import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as joda from 'js-joda';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import * as api from '../../apiClient';
import { User } from '../../models/models';
import { createToast } from '../../notification';
import { VarauksetState } from '../../redux/store';
import { receiveUser } from '../../redux/user';
import * as paths from '../../routerPaths';
import * as routerPaths from '../../routerPaths';
import './MyBalance.scss';

function useInterval(callback: () => Promise<void>, delay: number | null) {
  const savedCallback = useRef<() => Promise<void>>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return () => {};
  }, [delay]);
}

interface MyBalanceProps extends RouteComponentProps {}

const MyBalance: React.FC<MyBalanceProps> = (props) => {
  const dispatch = useDispatch();
  const reduxUser = useSelector((state: VarauksetState) => state.user.activeUser);
  const [componentUser, setComponentUser] = useState<User>();
  const user = componentUser || reduxUser;

  const params = new URLSearchParams(props.location.search);
  const loadAfterStr = params.get('loadAfter');
  let pollUntilLoadAfterVisible: joda.ZonedDateTime | undefined = undefined;
  if (loadAfterStr) {
    pollUntilLoadAfterVisible = joda.ZonedDateTime.parse(loadAfterStr);
  }

  let balanceUpToDate = !!user;
  if (user && pollUntilLoadAfterVisible) {
    if (user.profileUpdated.isAfter(pollUntilLoadAfterVisible)) {
      balanceUpToDate = true;
    } else {
      balanceUpToDate = false;
    }
  }

  const pollInterval = 1000;

  const [polls, setPolls] = useState(0);

  const pollUpdateSuccess = pollUntilLoadAfterVisible && balanceUpToDate;
  const pollUpdateFailed = polls > (10 * 1000) / pollInterval;
  if ((pollUpdateFailed || pollUpdateSuccess) && pollUntilLoadAfterVisible) {
    if (pollUpdateFailed) {
      createToast(
        'Uuden saldon lataamisessa näyttää kestävän pitempään kuin normaalisti. Kokeile ladata sivu uudelleen.'
      );
    }
    props.history.replace(routerPaths.myAccountUrl);
  }

  const interval = balanceUpToDate || pollUpdateSuccess || pollUpdateFailed ? null : pollInterval;

  async function fetchUser() {
    const userResult = await api.getUser();
    setPolls(polls + 1);
    if (userResult.data) {
      setComponentUser(userResult.data);
      dispatch(receiveUser(userResult.data));
    }
  }

  useInterval(fetchUser, interval);

  function handleLoadMoneyNavigate() {
    props.history.push(paths.loadMoneyUrl);
  }

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">
          Tilin saldo:{' '}
          <span className="v-current-balance">
            {balanceUpToDate ? (
              <span>
                <span>{user ? user.balance.toFixed(2) : '?'}</span> €
              </span>
            ) : (
              <FontAwesomeIcon icon={faSpinner} size="2x" pulse={true} />
            )}
          </span>
        </h4>
        <button className="btn btn-primary" onClick={handleLoadMoneyNavigate}>
          Lataa arvoa
        </button>
        <br />
        <br />
        <div>
          <Link to={paths.myAccountTransactionsUrl}>Katso tilisi tapahtumat</Link>
        </div>
      </div>
    </div>
  );
};

export default withRouter(MyBalance);
