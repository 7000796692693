import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface PaymentTermsModalProps {
  isOpen: boolean;
  onClose(): void;
}

const PaymentInfoModal: React.FC<PaymentTermsModalProps> = (props) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.onClose}>
      <ModalHeader toggle={props.onClose}>{'Tietoa maksamisesta ja tietoturvasta'}</ModalHeader>
      <ModalBody>
        <p>
          Varaukset.fi:ssä toimivat kaikki yleiset suomalaiset maksukortit (credit, debit ja
          electron). Joissain vanhoissa korteissa täytyy kytkeä nettimaksut päälle verkkopankista.
        </p>
        <p>
          Käytämme maksujen toteuttamiseen{' '}
          <a href="https://stripe.com" target="_blank" rel="noreferrer">
            Stripe
          </a>
          -palvelua, joka on PCI-sertifioitu ja kansainvälisesti luotettu maksunvälityspalvelu. Me
          Varaukset.fi:ssä näemme maksukortista ainoastaan neljä viimeistä numeroa sekä
          voimassaoloajan.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.onClose} tabIndex={1}>
          Sulje
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PaymentInfoModal;
