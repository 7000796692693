import * as joda from 'js-joda';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as uuid from 'uuid';
import {
  Reservation,
  ReservationMode,
  ReservationStatus,
  Resource,
  TimeRange,
  User,
} from '../../models/models';
import { setPreReservation } from '../../redux/resource';
import { VarauksetState } from '../../redux/store';
import {
  isUserAdmin,
  jodaNow,
  reservationDefaultLength,
  timeRangeMinusTimeRanges,
  timeRangesIntersect,
  timeRangeToString,
} from '../../utils';
import DisabledTime from './DisabledTime';
import Hour from './Hour';
import NowMarker from './NowMarker';
import ReservationComponent from './ReservationComponent';

interface HoursComponentProps {
  visibleTimeRange: TimeRange;
  availableTimeRanges?: TimeRange[];
  reservations: Reservation[];
  hoursDate: joda.LocalDate;
  hourRemHeight: number;
  now?: joda.LocalDateTime;
  style?: React.CSSProperties;
  className?: string;
}

interface HoursStateProps {
  resource: Resource | undefined;
  user: User | undefined;
}

interface HoursDispatchProps {
  onCreatePreReservation(r: Reservation): void;
  onDeletePreReservation(): void;
}

interface HoursRouterProps {
  resourceId: string;
}

interface HoursProps
  extends HoursComponentProps,
    HoursStateProps,
    HoursDispatchProps,
    HoursRouterProps {}

export function isHourEnabled(
  hourDate: joda.LocalDate,
  hourStartTime: joda.LocalTime,
  now: joda.LocalDateTime,
  admin: boolean = false
): boolean {
  const hourPast = hourDate.atTime(hourStartTime.plusHours(1)).isBefore(now);
  if (hourPast && !admin) {
    return false;
  } else {
    return true;
  }
}

export function getDisabledTimes(
  visibleTimeRange: TimeRange,
  availableTimeRanges?: TimeRange[]
): TimeRange[] {
  if (!availableTimeRanges) {
    return [visibleTimeRange];
  }
  return timeRangeMinusTimeRanges(visibleTimeRange, availableTimeRanges);
}

export const Hours: React.FC<HoursProps> = (props) => {
  const now = props.now || jodaNow();

  function createPreReservation(date: joda.LocalDate, clickedTimeRange: TimeRange) {
    const disabledTimes = getDisabledTimes(props.visibleTimeRange, props.availableTimeRanges);
    // Add out-of-day-range times to disabled list to help avoid auto-selecting those times.
    if (props.visibleTimeRange.start.hour() > 0) {
      disabledTimes.push({
        start: props.visibleTimeRange.start.minusHours(1),
        end: props.visibleTimeRange.start,
      });
    }
    if (props.visibleTimeRange.end.isBefore(joda.LocalTime.parse('23:00'))) {
      disabledTimes.push({
        start: props.visibleTimeRange.end,
        end: props.visibleTimeRange.end.plusHours(1),
      });
    }

    function reservationRangeOk(tr: TimeRange): boolean {
      for (const disabledTimeRange of disabledTimes) {
        if (timeRangesIntersect(disabledTimeRange, tr)) {
          return false;
        }
      }
      return true;
    }

    const preReservationDuration = reservationDefaultLength(
      props.resource ? props.resource.rules : undefined
    );
    const defaultTimeRange = {
      start: clickedTimeRange.start,
      end: clickedTimeRange.start.plus(preReservationDuration),
    };
    let finalTimeRange = defaultTimeRange;

    // Try first with larger step. This is done so that we don't end up with akward
    // 10.01-11.01 type range when e.g. 10.30-11.30 could've also been fine.
    for (const step of [30, 15, 5]) {
      let matchFound = false;
      if (!reservationRangeOk(defaultTimeRange)) {
        const minEnd = clickedTimeRange.start.plusMinutes(step);
        let potentialTimeRange = {
          start: minEnd.minusMinutes(preReservationDuration.toMinutes()),
          end: minEnd,
        };
        while (timeRangesIntersect(potentialTimeRange, clickedTimeRange)) {
          if (reservationRangeOk(potentialTimeRange)) {
            finalTimeRange = potentialTimeRange;
            matchFound = true;
            break;
          } else {
            potentialTimeRange = {
              start: potentialTimeRange.start.plusMinutes(step),
              end: potentialTimeRange.end.plusMinutes(step),
            };
          }
        }
        if (matchFound) {
          break;
        }
      }
    }

    // If time rolled over -> over midnight
    if (finalTimeRange.end.isBefore(finalTimeRange.start)) {
      finalTimeRange.end = joda.LocalTime.parse('23:59');
    }

    props.onCreatePreReservation({
      resourceId: props.resourceId,
      date,
      repeating: false,
      timeRange: finalTimeRange,
      id: uuid.v4(),
      status: ReservationStatus.PreReservation,
      description: '',
      descriptionPublic: false,
      totalPricePaid: 0,
      adminReservation: false,
    });
  }

  function deletePreReservation() {
    props.onDeletePreReservation();
  }

  function getStartHour() {
    return props.visibleTimeRange.start.hour();
  }

  function getEndHour() {
    // Special handling for end of day.
    if (props.visibleTimeRange.end.hour() === 23 && props.visibleTimeRange.end.minute() === 59) {
      return 24;
    }
    return props.visibleTimeRange.end.hour();
  }

  const admin = isUserAdmin(props.user, props.resource ? props.resource.organizationId : undefined);
  const readOnly =
    !admin && props.resource && props.resource.reservationMode === ReservationMode.ReadOnly;

  const hourElements = [];
  const startHour = getStartHour();
  const endHour = getEndHour();
  for (let i = startHour; i < endHour; ++i) {
    const thisHour = joda.LocalTime.ofSecondOfDay(i * joda.LocalTime.SECONDS_PER_HOUR);
    const hourEnabled = isHourEnabled(props.hoursDate, thisHour, now, admin);

    let handler = (minute: number) => deletePreReservation();
    if (hourEnabled) {
      handler = (minute: number) => {
        const clickedTimeRangeStart = thisHour.withMinute(minute);
        // TODO: We just know that this is the inner implementation of hour-component - 30min ranges
        // It would be better if Hour-component told the range.
        const clickedTimeRange = {
          start: clickedTimeRangeStart,
          end: clickedTimeRangeStart.plusMinutes(30),
        };
        createPreReservation(props.hoursDate, clickedTimeRange);
      };
    }

    hourElements.push(
      <Hour
        key={i.toString()}
        remHeight={props.hourRemHeight}
        readOnly={readOnly}
        enabled={hourEnabled}
        onPress={handler}
      />
    );
  }

  const disabledTimes: TimeRange[] = getDisabledTimes(
    props.visibleTimeRange,
    props.availableTimeRanges
  );
  const activeHours = now.toLocalDate().equals(props.hoursDate);

  // TODO: Does not handle multiday reservations correctly
  return (
    <div style={{ ...props.style, position: 'relative' }} className={props.className}>
      {hourElements}

      {disabledTimes.map((dt) => (
        <DisabledTime
          key={timeRangeToString(dt)}
          dayStartTime={props.visibleTimeRange.start}
          hourRemHeight={props.hourRemHeight}
          timeRange={dt}
          onClick={deletePreReservation}
        />
      ))}

      {props.reservations.map((r) => (
        <ReservationComponent
          key={r.id}
          dayStartTime={props.visibleTimeRange.start}
          now={now}
          hourRemHeight={props.hourRemHeight}
          reservation={r}
        />
      ))}

      {activeHours ? (
        <NowMarker
          visibleTimeRange={props.visibleTimeRange}
          now={now}
          hourRemHeight={props.hourRemHeight}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (
  state: VarauksetState,
  ownProps: HoursRouterProps & HoursComponentProps
): HoursStateProps => ({
  resource: state.resource.resources
    ? state.resource.resources.find((r) => r.id === ownProps.resourceId)
    : undefined,
  user: state.user.activeUser,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<VarauksetState, undefined, AnyAction>
): HoursDispatchProps => ({
  onCreatePreReservation: (r: Reservation) => dispatch(setPreReservation(r)),
  onDeletePreReservation: () => dispatch(setPreReservation(null)),
});

const HoursConnected = connect(mapStateToProps, mapDispatchToProps)(Hours);

export default withRouter((props: HoursComponentProps & RouteComponentProps<any>) => (
  <HoursConnected {...props} resourceId={props.match.params.resourceId} />
));
