import { devEnv, stagingEnv } from './utils';

export const loginRegisterUrl = '/kirjaudu';
export const loginUrl = '/kirjaudu';
export const registerUrl = '/rekisteroidy';
export const visitedOrganizationsUrl = '/organisaationi';
export const myAccountUrl = '/omat-tiedot';
export const myAccountPollForLoadTemplate = `/omat-tiedot?loadAfter=:loadAfter`;
export const newPasswordUrl = '/uusi-salasana';
export const setPasswordTemplate = '/aseta-salasana/:uid/:resetToken';
export const organizationMainTemplate = '/:organizationSlug';
export const resourceTemplate = '/:organizationSlug/:resourceId';
export const resourceEditTemplate = '/:organizationSlug/:resourceId/muokkaa';
export const reserveResourceTemplate = '/:organizationSlug/:resourceId/varaa';
export const reservationDetailsTemplate = '/:organizationSlug/:resourceId/varaus/:reservationId';
export const confirmReservationTemplate = '/vahvista/varaus/:confirmationToken';
export const confirmEmailTemplate = '/vahvista/email/:confirmationToken';
export const myAccountTransactionsUrl = '/omat-tiedot/maksutapahtumat';
export const myReservationsUrl = '/omat-tiedot/varaukset';
export const stripeChargeReturnUrl = '/omat-tiedot/maksutapahtumat/viimeisin-lataus';
export const loadMoneyUrl = '/omat-tiedot/lataa-arvoa';
export const adminUrl = '/admin';

export function getOrganizationMainUrl(organizationSlug: string) {
  return organizationMainTemplate.replace(':organizationSlug', organizationSlug);
}

export function getResourceUrl(organizationSlug: string, resourceId: string) {
  return resourceTemplate
    .replace(':organizationSlug', organizationSlug)
    .replace(':resourceId', resourceId);
}

export function getResourceEditUrl(organizationSlug: string, resourceId: string) {
  return resourceEditTemplate
    .replace(':organizationSlug', organizationSlug)
    .replace(':resourceId', resourceId);
}

export function getReserveResourceUrl(organizationSlug: string, resourceId: string) {
  return reserveResourceTemplate
    .replace(':organizationSlug', organizationSlug)
    .replace(':resourceId', resourceId);
}

export function getReservationDetailsUrl(
  organizationSlug: string,
  resourceId: string,
  reservationId: string
) {
  return reservationDetailsTemplate
    .replace(':organizationSlug', organizationSlug)
    .replace(':resourceId', resourceId)
    .replace(':reservationId', reservationId);
}

export function getMyAccountPollForLoad(loadDoneAfter: Date) {
  return myAccountPollForLoadTemplate.replace(':loadAfter', loadDoneAfter.toISOString());
}

let staticPagesBaseUrl = 'https://varaukset.fi';
if (devEnv()) {
  staticPagesBaseUrl = 'http://127.0.0.1:5000';
} else if (stagingEnv()) {
  staticPagesBaseUrl = 'https://staging.varaukset.fi';
}

export function getTermsAbsoluteUrl() {
  return staticPagesBaseUrl + '/terms';
}

export function getPrivacyPolicyAbsoluteUrl() {
  return staticPagesBaseUrl + '/privacy';
}

export function getAdminInstructionsAbsoluteUrl() {
  return staticPagesBaseUrl + '/yllapitajan-toiminnot';
}
