import { Action, createAction } from 'redux-actions';
import { Organization } from '../models/models';
import { Dispatch } from 'redux';
import { startWait, stopWait } from './wait';
import * as api from '../apiClient';
import { VarauksetState } from './store';
import { addBookmarkedOrganizationAsync } from './user';

// ACTIONS

export enum OrganizationAction {
  RECEIVE_ORGANIZATION = 'varaukset/organization/RECEIVE_ORGANIZATION',
  RECEIVE_ORGANIZATIONS = 'varaukset/organization/RECEIVE_ORGANIZATIONS',
  SET_VISITED_ORGANIZATION = 'varaukset/organization/SET_VISITED_ORGANIZATION',
}

export const receiveOrganization = createAction<Organization | null>(
  OrganizationAction.RECEIVE_ORGANIZATION
);

export function fetchOrganizationBySlugAsync(slug: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startWait());
    const org = await api.getOrganizationBySlug(slug);
    dispatch(stopWait());
    if (org) {
      dispatch(receiveOrganization(org));
    } else {
      dispatch(receiveOrganization(null));
    }
  };
}

export const receiveOrganizations = createAction<Organization[]>(
  OrganizationAction.RECEIVE_ORGANIZATIONS
);

export function fetchOrganizationsAsync() {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startWait());
    const orgs = await api.getOrganizations();
    dispatch(stopWait());
    dispatch(receiveOrganizations(orgs));
  };
}

export const setVisitedOrganization = createAction<Organization>(
  OrganizationAction.SET_VISITED_ORGANIZATION
);

export function setVisitedOrganizationAsync(org: Organization) {
  return async (dispatch: Dispatch<any>, getState: () => VarauksetState) => {
    const visitedOrg = getState().organization.lastVisitedOrganization;
    if (!visitedOrg || org.id !== visitedOrg.id) {
      dispatch(setVisitedOrganization(org));
    }
    dispatch(addBookmarkedOrganizationAsync(org));
  };
}

export function removeVisitedOrganizationAsync(org: Organization) {
  return async (dispatch: Dispatch<any>, getState: () => VarauksetState) => {
    const user = getState().user.activeUser;
    if (user) {
      await api.setMyOrganizations(user.bookmarkedOrganizations.filter((o) => o.id !== org.id));
    }
  };
}

// REDUCER

export interface OrganizationState {
  organization?: Organization | null;
  organizations: Organization[];
  lastVisitedOrganization?: Organization;
}

const organizationInitialState: OrganizationState = {
  organization: undefined,
  organizations: [],
  lastVisitedOrganization: undefined,
};

export default function organizationReducer(
  state: OrganizationState = organizationInitialState,
  action: Action<any>
): OrganizationState {
  switch (action.type) {
    case OrganizationAction.RECEIVE_ORGANIZATION:
      return { ...state, organization: action.payload };
    case OrganizationAction.RECEIVE_ORGANIZATIONS:
      return { ...state, organizations: action.payload };
    case OrganizationAction.SET_VISITED_ORGANIZATION:
      return { ...state, lastVisitedOrganization: action.payload };

    default:
      return state;
  }
}
