import { faQuestionCircle, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import * as React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { ReservationMode, Resource, User } from '../../../models/models';
import * as paths from '../../../routerPaths';
import { isUserAdmin } from '../../../utils';
import FAT from '../../FontAwesomeWithTooltip';

export enum ReservationCreationMode {
  Admin = 'admin',
  Normal = 'normal',
}

function getMyReservationModeKey(resourceId: string) {
  return `my-reservation-mode-${resourceId}`;
}

function saveToLocalStorage(resourceId: string, m: ReservationCreationMode) {
  localStorage.setItem(getMyReservationModeKey(resourceId), m.toString());
}

function getFromLocalStorage(
  resourceId: string,
  defaultMode: ReservationCreationMode
): ReservationCreationMode {
  const s = localStorage.getItem(getMyReservationModeKey(resourceId));
  if (s) {
    if (s === ReservationCreationMode.Admin) {
      return ReservationCreationMode.Admin;
    } else if (s === ReservationCreationMode.Normal) {
      return ReservationCreationMode.Normal;
    }
  }
  return defaultMode;
}

interface AdminSelectorProps {
  user: User | undefined;
  resource: Resource | undefined;
  selected: ReservationCreationMode;
  onChange(mode: ReservationCreationMode): void;
}

interface AdminSelectorState {
  touched: boolean;
  lastReportedMode?: ReservationCreationMode;
}

export default class AdminSelector extends React.Component<AdminSelectorProps, AdminSelectorState> {
  constructor(props: AdminSelectorProps) {
    super(props);
    this.state = { touched: false };
  }

  componentDidMount() {
    this.setInitialSelection();
  }

  componentDidUpdate(prevProps: AdminSelectorProps) {
    if (
      !_.isEqual(this.props.user, prevProps.user) ||
      !_.isEqual(this.props.resource, prevProps.resource)
    ) {
      this.setInitialSelection();
    }
  }

  setInitialSelection() {
    if (!this.state.touched) {
      if (
        isUserAdmin(
          this.props.user,
          this.props.resource ? this.props.resource.organizationId : undefined
        )
      ) {
        let initialMode = ReservationCreationMode.Normal;
        if (
          this.props.resource &&
          this.props.resource.reservationMode === ReservationMode.ReadOnly
        ) {
          initialMode = ReservationCreationMode.Admin;
        } else if (this.props.resource) {
          initialMode = getFromLocalStorage(this.props.resource.id, initialMode);
        }
        this.reportChangeIfNeeded(initialMode);
      }
    }
  }

  handleUserChange(newMode: ReservationCreationMode) {
    this.setState({ touched: true });
    if (this.props.resource) {
      saveToLocalStorage(this.props.resource.id, newMode);
    }
    this.reportChangeIfNeeded(newMode);
  }

  reportChangeIfNeeded(newMode: ReservationCreationMode) {
    if (this.state.lastReportedMode !== newMode) {
      this.setState({ lastReportedMode: newMode });
      this.props.onChange(newMode);
    }
  }

  showSelector() {
    const props = this.props;
    const isCurrentUserAdmin = isUserAdmin(
      props.user,
      props.resource ? props.resource.organizationId : undefined
    );
    return isCurrentUserAdmin;
  }

  render() {
    if (this.showSelector()) {
      return (
        <div className="float-right text-right">
          <div className="mb-2">
            <a href={paths.getAdminInstructionsAbsoluteUrl()} target="_blank" rel="noreferrer">
              Ylläpitäjän ohjeet ja usein kysytyt kysymykset
            </a>
          </div>
          <FAT
            id="reservation-admin-selector"
            icon={faQuestionCircle}
            style={{ marginRight: '3px' }}
          >
            <div>
              Ylläpitäjän varaukset eroavat normaaleista varauksista seuraavasti:{' '}
              <ul>
                <li>varaus voi olla toistuva</li>
                <li>mahdollista varausmaksua ei veloiteta</li>
                <li>aikarajoituksia ei tarvitse noudattaa</li>
              </ul>
            </div>
          </FAT>
          <label>Varaustapa</label>{' '}
          <ButtonGroup>
            <Button
              color="success"
              disabled={
                this.props.resource &&
                this.props.resource.reservationMode === ReservationMode.ReadOnly
              }
              onClick={() => this.handleUserChange(ReservationCreationMode.Normal)}
              active={this.props.selected === ReservationCreationMode.Normal}
            >
              Normaali
            </Button>
            <Button
              color="success"
              onClick={() => this.handleUserChange(ReservationCreationMode.Admin)}
              active={this.props.selected === ReservationCreationMode.Admin}
            >
              <span>
                <FontAwesomeIcon icon={faUserShield} /> Ylläpitäjä
              </span>
            </Button>
          </ButtonGroup>
        </div>
      );
    }
    return null;
  }
}
