import * as React from 'react';
import * as joda from 'js-joda';
import './HourMarkers.scss';
import { SIMPLE_FI_TIME_FORMATTER } from '../../utils';

interface HourMarkersProps {
  start: joda.LocalTime;
  end: joda.LocalTime;
  hourRemHeight: number;
  headerRemHeight: number;
}

export const HourMarkers: React.FC<HourMarkersProps> = (props) => {
  function getStartHour() {
    return props.start.hour();
  }

  function getEndHour() {
    // Special handling for end of day.
    if (props.end.hour() === 23 && props.end.minute() === 59) {
      return 24;
    }
    return props.end.hour();
  }

  const hours = getEndHour() - getStartHour();
  const hourElements = [];
  for (let i = 0; i < hours; ++i) {
    hourElements.push(
      <div key={i.toString()} style={{ textAlign: 'right', fontSize: '0.7rem' }}>
        <div
          style={{
            height: `${props.hourRemHeight / 2}rem`,
          }}
          className="v-hour-marker-cell"
        >
          {props.start.plusHours(i).format(SIMPLE_FI_TIME_FORMATTER)}
        </div>
        <div style={{ height: `${props.hourRemHeight / 2}rem` }} />
      </div>
    );
  }

  return (
    <div>
      <div style={{ height: `${props.headerRemHeight}rem` }} />
      {hourElements}
    </div>
  );
};

export default HourMarkers;
