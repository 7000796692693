import { Dispatch } from 'redux';
import { Action, createAction } from 'redux-actions';
import * as api from '../apiClient';
import {
  CreateReservation,
  DateRange,
  LoadState,
  Organization,
  Reservation,
  Resource,
} from '../models/models';
import { createToast } from '../notification';
import * as paths from '../routerPaths';
import history from './browserHistory';
import { fetchCurrentUserAsync } from './user';
import { startWait, stopWait } from './wait';

// ACTIONS

export enum ResourceAction {
  START_RESOURCES_FETCH = 'varaukset/resource/START_RESOURCES_FETCH',
  RECEIVE_RESOURCES = 'varaukset/resource/RECEIVE_RESOURCES',
  START_RESERVATIONS_FETCH = 'varaukset/resource/START_RESERVATIONS_FETCH',
  RECEIVE_RESERVATIONS = 'varaukset/resource/RECEIVE_RESERVATIONS',
  SET_PRE_RESERVATION = 'varaukset/resource/SET_PRE_RESERVATION',
}

export const startResourcesFetch = createAction(ResourceAction.START_RESOURCES_FETCH);
export const receiveResources = createAction<Resource[]>(ResourceAction.RECEIVE_RESOURCES);

export function fetchResourcesAsync(organizationId: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startResourcesFetch());
    dispatch(startWait());
    const resources = await api.getResources(organizationId);
    dispatch(stopWait());
    dispatch(receiveResources(resources));
  };
}

export const startReservationsFetch = createAction(ResourceAction.START_RESERVATIONS_FETCH);
export const receiveReservations = createAction<Reservation[]>(ResourceAction.RECEIVE_RESERVATIONS);

export function fetchReservationsAsync(range: DateRange, resourceId: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startReservationsFetch());
    dispatch(startWait());
    const reservations = await api.getReservations(range, resourceId);
    dispatch(stopWait());
    dispatch(receiveReservations(reservations));
  };
}

// Preliminary reservation slot that is created when user clicks on the calendar.
// This is purely client side.
export const setPreReservation = createAction<Reservation | null>(
  ResourceAction.SET_PRE_RESERVATION
);

export function createReservationReFetchAllAndNavigateAsync(
  reservation: CreateReservation,
  organizationSlug: string,
  resource: Resource,
  range: DateRange
) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startWait());
    const apiResponse = await api.createReservation(reservation, resource);
    dispatch(stopWait());
    dispatch(fetchReservationsAsync(range, resource.id));
    dispatch(fetchCurrentUserAsync());
    if (!apiResponse.success) {
      const msg = apiResponse.errorMessages ? apiResponse.errorMessages.join(', ') : 'Virhe';
      createToast(msg, 'error');
    } else {
      createToast(`${resource.name} varattu!`, 'success');
      history.push(paths.getResourceUrl(organizationSlug, resource.id));
    }
  };
}

export function changeSelectedResourceAsync(
  organization: Organization,
  resourceId: string | null,
  dateRange: DateRange,
  autoNavigate: boolean = false
) {
  return async (dispatch: Dispatch<any>) => {
    let newPath;
    if (resourceId === null) {
      newPath = paths.getOrganizationMainUrl(organization.slug);
    } else {
      newPath = paths.getResourceUrl(organization.slug, resourceId);
    }

    if (autoNavigate) {
      history.replace(newPath);
    } else {
      history.push(newPath);
    }

    // Clear calendar from other resources reservations and possible pre-reservation.
    dispatch(receiveReservations([]));
    dispatch(setPreReservation(null));
    if (resourceId) {
      dispatch(fetchReservationsAsync(dateRange, resourceId));
    }
  };
}

// REDUCER

export interface ResourceState {
  reservations: Reservation[];
  reservationsFetchState: LoadState;
  preReservation: Reservation | null;
  resources: Resource[];
  resourcesFetchState: LoadState;
}

const resourceInitialState: ResourceState = {
  reservations: [],
  reservationsFetchState: LoadState.Unknown,
  preReservation: null,
  resources: [],
  resourcesFetchState: LoadState.Unknown,
};

export default function resourceReducer(
  state: ResourceState = resourceInitialState,
  action: Action<any>
): ResourceState {
  switch (action.type) {
    case ResourceAction.START_RESERVATIONS_FETCH:
      return {
        ...state,
        reservationsFetchState: LoadState.InProgress,
      };
    case ResourceAction.RECEIVE_RESERVATIONS:
      return {
        ...state,
        reservations: action.payload,
        reservationsFetchState: LoadState.Finished,
      };
    case ResourceAction.SET_PRE_RESERVATION:
      return {
        ...state,
        preReservation: action.payload,
      };
    case ResourceAction.START_RESOURCES_FETCH:
      return {
        ...state,
        resourcesFetchState: LoadState.InProgress,
      };
    case ResourceAction.RECEIVE_RESOURCES:
      return {
        ...state,
        resources: action.payload,
        resourcesFetchState: LoadState.Finished,
      };
    default:
      return state;
  }
}
