import { Dispatch } from 'redux';
import { Action, createAction } from 'redux-actions';
import * as api from '../apiClient';
import {
  LoadState,
  LoginCredentials,
  Organization,
  RegisterData,
  SetPasswordData,
  User,
} from '../models/models';
import { createToast } from '../notification';
import * as paths from '../routerPaths';
import history from './browserHistory';
import { GetState } from './store';
import { receiveToken, TokenAction } from './token';
import { clearLocalUser, UserAction } from './userSharedActions';
import { startWait, stopWait } from './wait';

// ACTIONS

export const receiveUser = createAction<User>(UserAction.RECEIVE_USER);

export function fetchCurrentUserAsync() {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startWait());
    const apiDataResponse = await api.getUser();
    dispatch(stopWait());
    if (apiDataResponse.success && apiDataResponse.data) {
      dispatch(receiveUser(apiDataResponse.data));
    } else {
      dispatch(clearLocalUser());
    }
  };
}

export const startLogin = createAction(UserAction.START_LOGIN);

export function loginAsync(loginCredentials: LoginCredentials) {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(startWait());
    const apiResponse = await api.login(loginCredentials);
    dispatch(stopWait());
    if (apiResponse.success && apiResponse.data) {
      dispatch(receiveToken(apiResponse.data));
    } else {
      const msg = apiResponse.errorMessages ? apiResponse.errorMessages.join(', ') : 'Virhe';
      createToast(msg, 'error');
    }
  };
}

export const logout = createAction(UserAction.LOGOUT);

export function logoutAsync() {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(logout());
    dispatch(startWait());
    const apiResponse = await api.logout();
    dispatch(stopWait());
    if (apiResponse.success) {
      history.push('/');
    } else {
      const msg = apiResponse.errorMessages ? apiResponse.errorMessages.join(', ') : 'Virhe';
      createToast(msg, 'error');
    }
  };
}

export function registerAsync(registerData: RegisterData) {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    dispatch(startWait());
    const apiResponse = await api.register(registerData);
    dispatch(stopWait());
    if (apiResponse.success) {
      history.push(paths.loginUrl);
      const msg =
        'Rekisteröityminen vastaanotettu! Saat sähköpostiisi vahvistuslinkin. ' +
        'Kun olet vahvistanut sähköpostiosoitteesi, voit kirjautua sisään.';
      createToast(msg, 'info', undefined, false);
    } else {
      const msg = apiResponse.errorMessages ? apiResponse.errorMessages.join(', ') : 'Virhe';
      createToast(msg, 'error');
    }
  };
}

export function requestPasswordResetLinkAsync(email: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startWait());
    const result = await api.requestPasswordResetLink(email);
    dispatch(stopWait());
    if (result) {
      const msg = 'Salasanan vaihtopyyntö vastaanotettu.';
      createToast(msg, 'info');
    } else {
      const msg = 'Salasanan vaihtopyyntö epäonnistui.';
      createToast(msg, 'error');
    }
  };
}

export function setPasswordAsync(setPasswordData: SetPasswordData) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startWait());
    const result = await api.setPassword(setPasswordData);
    dispatch(stopWait());
    if (result.success) {
      const msg = 'Salasana vaihdettu!';
      createToast(msg, 'success');
      history.push(paths.loginUrl);
    } else {
      const msg = result.errorMessages ? result.errorMessages.join(', ') : 'Virhe';
      createToast(msg, 'error');
    }
  };
}

export const setBookmarkedOrganizations = createAction<Organization[]>(
  UserAction.SET_USER_BOOKMARKED_ORGANIZATIONS
);

export function addBookmarkedOrganizationAsync(org: Organization) {
  return async (dispatch: Dispatch<any>, getState: GetState) => {
    const user = getState().user.activeUser;
    if (user && !user.bookmarkedOrganizations.some((o) => o.id === org.id)) {
      const newOrgs = user.bookmarkedOrganizations.concat(org);
      dispatch(setBookmarkedOrganizations(newOrgs));
      await api.setMyOrganizations(newOrgs);
    }
  };
}

export function removeBookmarkedOrganizationAsync(org: Organization) {
  return async (dispatch: Dispatch<any>, getState: GetState) => {
    const user = getState().user.activeUser;
    if (user && user.bookmarkedOrganizations.some((o) => o.id === org.id)) {
      const newOrgs = user.bookmarkedOrganizations.filter((o) => o.id !== org.id);
      dispatch(setBookmarkedOrganizations(newOrgs));
      await api.setMyOrganizations(newOrgs);
    }
  };
}

// REDUCER

export interface UserState {
  activeUser?: User;
  loginLoadState: LoadState;
}

const userInitialState: UserState = {
  activeUser: undefined,
  loginLoadState: LoadState.Unknown,
};

export default function userReducer(
  state: UserState = userInitialState,
  action: Action<any>
): UserState {
  switch (action.type) {
    case UserAction.START_LOGIN:
      return { ...state, loginLoadState: LoadState.InProgress };
    case TokenAction.RECEIVE_TOKEN:
      return { ...state, loginLoadState: LoadState.Finished };
    case UserAction.RECEIVE_USER:
      return { ...state, activeUser: action.payload };
    case UserAction.SET_USER_BOOKMARKED_ORGANIZATIONS:
      return state.activeUser
        ? { ...state, activeUser: { ...state.activeUser, bookmarkedOrganizations: action.payload } }
        : state;
    case UserAction.CLEAR_LOCAL_USER:
      return { ...state, activeUser: undefined };
    case UserAction.LOGOUT:
      return userInitialState;

    default:
      return state;
  }
}
