import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as joda from 'js-joda';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import Calendar from './components/calendar/Calendar';
import { ResourceDescription } from './components/ResourceDescription';
import ResourceSelection from './components/ResourceSelection';
import { ReservationMode, Resource } from './models/models';
import { VarauksetState } from './redux/store';
import './ResourceCalendar.scss';
import * as paths from './routerPaths';
import { ScrollToTopOnMount } from './ScrollToTopOnMount';
import { jodaNow } from './utils';

interface ResourceCalendarConnectedProps {
  selectedResourceId: string | null;
  onReserveClick(): void;
}

interface ResourceCalendarProps extends ResourceCalendarConnectedProps {
  resource: Resource;
}

const tickerIntervalMS = 1 * 60 * 1000;
interface ResourceCalendarState {
  ticker?: number;
  now: joda.LocalDateTime;
}

export class ResourceCalendar extends React.Component<
  ResourceCalendarProps,
  ResourceCalendarState
> {
  constructor(props: ResourceCalendarProps) {
    super(props);
    this.state = {
      ticker: undefined,
      now: jodaNow(),
    };
  }

  componentDidMount() {
    const ticker = window.setInterval(() => this.setState({ now: jodaNow() }), tickerIntervalMS);
    this.setState({ ticker });

    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    if (this.state.ticker !== undefined) {
      window.clearInterval(this.state.ticker);
    }

    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
  }

  handleScroll() {
    const btnMinMarginFromWindowBottom = 20;
    const btnMarginCalendarBottom = 7;
    const btnMarginCalendarRight = 12;

    const calendar = document.getElementsByClassName(
      'v-resource-calendar-container'
    )[0] as HTMLElement;
    const btn = document.getElementsByClassName('v-reserve-btn')[0] as HTMLElement;
    if (btn) {
      const roomUnderCalendar =
        window.innerHeight + window.pageYOffset - calendar.clientHeight - calendar.offsetTop;
      if (
        roomUnderCalendar <
        btnMarginCalendarBottom + btn.clientHeight + btnMinMarginFromWindowBottom
      ) {
        btn.style.position = 'fixed';
        btn.style.bottom = `${btnMinMarginFromWindowBottom}px`;
        btn.style.right = `${btnMarginCalendarRight + calendar.offsetLeft}px`;
      } else {
        btn.style.position = 'absolute';
        btn.style.bottom = `-${btnMarginCalendarBottom + btn.clientHeight}px`;
        btn.style.right = `${btnMarginCalendarRight}px`;
      }
    }
  }

  render() {
    return (
      <div className="v-resource-calendar-container" style={{ position: 'relative' }}>
        <ScrollToTopOnMount />
        <ResourceSelection />
        <ResourceDescription resource={this.props.resource} />
        <Calendar now={this.state.now} />

        {this.props.resource && this.props.resource.reservationMode !== ReservationMode.ReadOnly ? (
          <button
            type="button"
            className="btn btn-success v-reserve-btn"
            onClick={this.props.onReserveClick}
          >
            <FontAwesomeIcon icon={faPlus} /> Varaa
          </button>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: VarauksetState, ownProps: ResourceCalendarConnectedProps) => ({
  resource: state.resource.resources.find((r) => r.id === ownProps.selectedResourceId),
});

const ResourceCalendarConnected = connect(mapStateToProps)(
  ResourceCalendar as any
) as any as React.FC<ResourceCalendarConnectedProps>;

export default withRouter((props: RouteComponentProps<any>) => (
  <ResourceCalendarConnected
    selectedResourceId={props.match.params.resourceId}
    onReserveClick={() =>
      props.history.push(
        paths.getReserveResourceUrl(
          props.match.params.organizationSlug,
          props.match.params.resourceId
        )
      )
    }
  />
));
