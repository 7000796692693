import _ from 'lodash';
import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { PricingMode, ReservationMode, Resource } from '../models/models';
import { formatDuration, formatDuration2 } from '../utils';
import './ResourceDescription.scss';

function renderRules(resource: Resource) {
  const rules = resource.rules;
  const ruleStrings = [];

  if (rules) {
    if (resource.price > 0 && resource.showHourPriceOnUi) {
      if (resource.pricingMode === PricingMode.ChargeByHour) {
        ruleStrings.push(`Varaus maksaa ${resource.price.toFixed(2)} € alkavalta tunnilta.`);
      } else if (resource.pricingMode === PricingMode.ChargeByHalfHour) {
        ruleStrings.push(
          `Varaus maksaa ${resource.price.toFixed(
            2
          )} € tunnilta. Veloitus alkavan puolen tunnin tarkkuudella.`
        );
      } else {
        throw Error(`Unknown pricing mode ${resource.pricingMode}`);
      }
    }

    if (resource.reservationMode === ReservationMode.ReadOnly) {
      ruleStrings.push('Tähän kalenteriin ei voi tehdä varauksia Varaukset.fi:ssä.');
    } else if (resource.showReservationRulesOnUi) {
      if (
        rules.reservationMaxLen &&
        rules.reservationMinLen &&
        rules.reservationMaxLen.toMillis() === rules.reservationMinLen.toMillis()
      ) {
        ruleStrings.push(`${_.capitalize(formatDuration(rules.reservationMaxLen))} varaukset.`);
      } else {
        if (rules.reservationMaxLen) {
          ruleStrings.push(`Enintään ${formatDuration(rules.reservationMaxLen)} varaus.`);
        }
        if (rules.reservationMinLen) {
          ruleStrings.push(`Vähintään ${formatDuration(rules.reservationMinLen)} varaus.`);
        }
      }

      if (rules.reservationAllowedDaysToFuture) {
        ruleStrings.push(
          `Varauksen saa tehdä enintään ${rules.reservationAllowedDaysToFuture} vuorokauden päähän.`
        );
      }

      if (rules.canDeleteBefore) {
        ruleStrings.push(
          `Varauksen peruutus viimeistään ${
            rules.canDeleteBefore.toMillis() > 0 ? formatDuration2(rules.canDeleteBefore) : ''
          } ennen varauksen alkua.`
        );
      }
    }

    if (resource.reservationMode === ReservationMode.EmailConfirmation) {
      ruleStrings.push(
        'Varauksen voi tehdä vain sisäänkirjautuneena tai antamalla toimivan sähköpostiosoitteen.'
      );
    }
  }

  if (ruleStrings.length > 0) {
    return (
      <ul>
        {ruleStrings.map((r) => (
          <li key={r}>{r}</li>
        ))}
      </ul>
    );
  }
  return null;
}

export function renderDemoNotification() {
  const demoNotification = 'Tämä kalenteri on demo-tilassa.';
  const demoDescription =
    'Voit huoletta kokeilla varauksien tekemistä; tehtyjä varauksia ei tallenneta. ' +
    'Tekemäsi varaukset eivät koskaan näy muille ja ne katoavat kun lataat sivun selaimessasi ' +
    'uudestaan.';
  return (
    <span>
      <span className="text-danger font-weight-bold">{demoNotification}</span> {demoDescription}
    </span>
  );
}

function renderDescriptionAndRules(resource: Resource) {
  if (!resource) {
    return null;
  }
  const demoNotification =
    resource.reservationMode === ReservationMode.Demo ? <p>{renderDemoNotification()}</p> : null;
  const rules = renderRules(resource);
  const desc = resource.description ? (
    <p style={{ whiteSpace: 'pre-line' }}>{resource.description}</p>
  ) : null;
  if (demoNotification || rules || desc) {
    return (
      <div>
        <div>
          {demoNotification}
          {desc}
          {rules}
        </div>
      </div>
    );
  }
  return null;
}

interface ResourceDescriptionProps {
  resource: Resource;
}

interface ResourceDescriptionState {
  modalOpen: boolean;
}

export class ResourceDescription extends React.Component<
  ResourceDescriptionProps,
  ResourceDescriptionState
> {
  constructor(props: ResourceDescriptionProps) {
    super(props);
    this.state = { modalOpen: false };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalOpen: true });
  }

  closeModal() {
    this.setState({ modalOpen: false });
  }

  render() {
    const descAndRules = renderDescriptionAndRules(this.props.resource);
    if (descAndRules) {
      return (
        <div className="v-resource-description-container">
          <div style={{ position: 'relative' }}>
            <div className="v-desc-content">{descAndRules}</div>

            <div className="v-open-button-container">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a href="#" onClick={this.openModal}>
                Katso kuvaus ja säännöt
              </a>
            </div>
            <Modal isOpen={this.state.modalOpen} toggle={this.closeModal}>
              <ModalHeader toggle={this.closeModal}>
                <span>{this.props.resource ? `${this.props.resource.name} - ` : ''}</span>
                <span>{'kuvaus ja säännöt'}</span>
              </ModalHeader>
              <ModalBody>{descAndRules}</ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.closeModal}>
                  Sulje
                </Button>
              </ModalFooter>
            </Modal>
          </div>
        </div>
      );
    }
    return null;
  }
}

export default ResourceDescription;
