import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Organization } from '../../models/models';
import { VarauksetState } from '../../redux/store';
import { removeBookmarkedOrganizationAsync } from '../../redux/user';
import * as paths from '../../routerPaths';
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import * as LocalStorageUtils from '../../localStorageUtils';
import FAT from '../FontAwesomeWithTooltip';

interface MyBookmarkedOrganizationsStateProps {
  organizations: Organization[];
}

interface MyBookmarkedOrganizationsDispatchProps {
  onRemoveBookmark(org: Organization): void;
}

interface MyBookmarkedOrganizationsProps
  extends MyBookmarkedOrganizationsStateProps,
    MyBookmarkedOrganizationsDispatchProps {}

export class MyBookmarkedOrganizations extends React.Component<MyBookmarkedOrganizationsProps> {
  render() {
    const visitedOrg = LocalStorageUtils.getVisitedOrganization();
    let visitedOrgs: LocalStorageUtils.VisitedOrganization[] = [];
    if (visitedOrg && !this.props.organizations.some((o) => o.slug === visitedOrg.slug)) {
      visitedOrgs = [visitedOrg, ...this.props.organizations];
    } else {
      visitedOrgs = this.props.organizations;
    }

    return (
      <div className="card">
        <div className="card-body">
          {visitedOrgs.length === 0 ? (
            <>
              <h4 className="card-title">Kuinka pääsen varauskalenteriin?</h4>
              <p className="text-muted">
                Syötä haluamasi kalenterin osoite selaimen osoiteriville. Voit myös käyttää saamaasi
                linkkiä varauskalenteriin.
              </p>
              <p className="text-muted">
                Kun olet ensimmäisen kerran sisäänkirjautuneena vieraillut kalenterissa, muistamme
                automaattisesti mitä kalenteria haluat käyttää.
              </p>
            </>
          ) : (
            <>
              <h4 className="card-title">Kirjanmerkit</h4>
              <p className="text-muted">Olet aiemmin käyttänyt:</p>
              <ul>
                {visitedOrgs.map((o) => (
                  <li key={o.slug} style={{ fontSize: '1.3rem' }}>
                    <Link to={paths.getOrganizationMainUrl(o.slug)}>{o.name}</Link>
                    {this.props.organizations.some((org) => org.slug === o.slug) && (
                      <span
                        style={{ cursor: 'pointer', marginLeft: '5px' }}
                        title="Poista kirjanmerkeistä"
                        onClick={() =>
                          this.props.onRemoveBookmark(
                            this.props.organizations.find(
                              (org) => org.slug === o.slug
                            ) as Organization
                          )
                        }
                      >
                        <FAT id={`remove-bookmark-${o.slug}`} icon={faMinusCircle}>
                          Poista kalenteri kirjanmerkeistäsi
                        </FAT>
                      </span>
                    )}
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: VarauksetState): MyBookmarkedOrganizationsStateProps => ({
  organizations: state.user.activeUser ? state.user.activeUser.bookmarkedOrganizations : [],
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<VarauksetState, undefined, AnyAction>
): MyBookmarkedOrganizationsDispatchProps => ({
  onRemoveBookmark: (org: Organization) => dispatch(removeBookmarkedOrganizationAsync(org)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyBookmarkedOrganizations);
