import { Action, createAction } from 'redux-actions';

export enum WaitAction {
  START_WAIT = 'varaukset/wait/START_WAIT',
  STOP_WAIT = 'varaukset/wait/STOP_WAIT',
}

export const startWait = createAction(WaitAction.START_WAIT);
export const stopWait = createAction(WaitAction.STOP_WAIT);

export interface WaitState {
  waitOperationCount: number;
}

const waitInitialState: WaitState = {
  waitOperationCount: 0,
};

export default function waitReducer(
  state: WaitState = waitInitialState,
  action: Action<any>
): WaitState {
  switch (action.type) {
    case WaitAction.START_WAIT:
      return { waitOperationCount: state.waitOperationCount + 1 };
    case WaitAction.STOP_WAIT:
      return { waitOperationCount: Math.max(state.waitOperationCount - 1, 0) };

    default:
      return state;
  }
}
