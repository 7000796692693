import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { confirmReservationAsync } from '../../redux/confirm';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import { ContentContainer } from '../ContentContainer';
import { VarauksetState } from '../../redux/store';

interface ConfirmReservationStateProps {
  confirmed?: boolean;
}

interface ConfirmReservationRouterProps {
  confirmationToken: string;
}

interface ConfirmReservationDispatchProps {
  onConfirm(confirmationToken: string): void;
}

interface ConfirmReservationProps
  extends ConfirmReservationStateProps,
    ConfirmReservationDispatchProps,
    ConfirmReservationRouterProps {}

export class ConfirmReservation extends React.Component<ConfirmReservationProps> {
  componentDidMount() {
    this.props.onConfirm(this.props.confirmationToken);
  }

  render() {
    return (
      <ContentContainer>
        <ScrollToTopOnMount />
        {this.props.confirmed === undefined && <p>Odota ...</p>}
        {this.props.confirmed === true && <p>Varaus vahvistettu!</p>}
        {this.props.confirmed === false && <p>Vahvistaminen epäonnistui.</p>}
      </ContentContainer>
    );
  }
}

const mapStateToProps = (state: VarauksetState): ConfirmReservationStateProps => ({
  confirmed: state.confirm.reservationConfirmed,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<VarauksetState, undefined, AnyAction>
): ConfirmReservationDispatchProps => ({
  onConfirm: (confirmationToken: string) => dispatch(confirmReservationAsync(confirmationToken)),
});

const ConfirmReservationConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmReservation);

export default withRouter((props: RouteComponentProps<any>) => (
  <ConfirmReservationConnected confirmationToken={props.match.params.confirmationToken} />
));
