import * as joda from 'js-joda';
import * as React from 'react';
import { timeString, ValidationResult } from '../validators';

interface TimeInputProps {
  tabIndex?: number;
  id?: string;
  valid?: boolean;
  value: joda.LocalTime | undefined;
  onChange: (time: joda.LocalTime | undefined, validationResult: ValidationResult) => void;
}

interface TimeInputState {
  rawInput: string;
}

export default class TimeInput extends React.Component<TimeInputProps, TimeInputState> {
  constructor(props: TimeInputProps) {
    super(props);
    this.state = {
      rawInput: '',
    };
  }

  handleTimeChange(timeStr: string) {
    this.setState({ rawInput: timeStr });
    const validationResult = timeString(timeStr);
    let time = undefined;
    if (validationResult.valid && timeStr) {
      time = joda.LocalTime.parse(timeStr);
    }
    this.props.onChange(time, validationResult);
  }

  render() {
    const props = this.props;
    const inputClasses = ['form-control'];
    if (props.valid) {
      inputClasses.push('is-valid');
    } else if (props.valid === false) {
      inputClasses.push('is-invalid');
    }

    return (
      <input
        tabIndex={props.tabIndex}
        className={inputClasses.join(' ')}
        type="time"
        name="time"
        id={props.id}
        placeholder="TT:MM"
        value={props.value ? props.value.toString() : this.state.rawInput}
        onChange={(e) => this.handleTimeChange(e.target.value)}
      />
    );
  }
}
