import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface LoadMoneyConfirmModalProps {
  isOpen: boolean;
  transactionFee: number;
  totalAmount: number;
  payEnabled: boolean;
  onCancel(): void;
  onConfirm(): void;
}

const LoadMoneyConfirmModal: React.FC<LoadMoneyConfirmModalProps> = (props) => {
  function renderTotalPrice() {
    return (
      <span className="badge badge-light" style={{ fontSize: '1rem' }}>
        {`${props.totalAmount.toFixed(2)} €`}
      </span>
    );
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.onCancel} backdrop="static">
      <ModalHeader toggle={props.onCancel}>{'Vahvista veloitus'}</ModalHeader>
      <ModalBody>
        Ladataan {(props.totalAmount - props.transactionFee).toFixed(2)} € Varaukset.fi-tilillesi.
        Kortiltasi veloitetaan {props.totalAmount.toFixed(2)} €.
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.onCancel} tabIndex={1}>
          Peruuta
        </Button>
        <Button
          color="primary"
          autoFocus={true}
          onClick={props.onConfirm}
          tabIndex={2}
          disabled={!props.payEnabled}
        >
          Maksa {renderTotalPrice()}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LoadMoneyConfirmModal;
