import * as _ from 'lodash';
import * as React from 'react';
import { ReservationMode, User } from '../../../models/models';
import {
  validate,
  ValidationField,
  ValidationResult,
  validEmailBuilder,
} from '../../../validators';
import FAT from '../../FontAwesomeWithTooltip';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

function validateEmail(email: string, required: boolean): ValidationResult {
  // TODO: Manual hardcoded blacklist for now. Emails where sending has failed.
  const blacklist = [
    'Alikakeai@gmail.comA2',
    'helmimarja.honkanen@loud.fi',
    'kouvalainenmsija@gmail.com',
    'hengjing@helsinki.fi',
    'tuija.hamari@gmail.com',
    'christoforusdimassatra@gmail.com',
    'binokoi86@gmail.com',
    'binokoi56@gmail.com',
    'Katriina.lumdqvist@gmail.com',
    'mmmartat.joensuu@gemail.com',
    'nieyafei@helsinki.fi',
    'haikovirve@gmail.com',
  ];
  return validate(email, [validEmailBuilder(required, blacklist)]);
}

interface EmailComponentProps {
  user?: User;
  reservationMode?: ReservationMode;
  onEmailChange: (d: string) => void;
  onValidityChange: (v: boolean) => void;
}

interface EmailState {
  userHasTouchedForm: boolean;
  email: ValidationField<string>;
}

interface EmailProps extends EmailComponentProps {}

export default class EmailField extends React.Component<EmailProps, EmailState> {
  constructor(props: EmailProps) {
    super(props);

    this.state = {
      userHasTouchedForm: false,
      email: { value: '', validationResult: { valid: false } },
    };
  }

  componentDidMount() {
    this.setInitialState();
    this.communicateChangesIfNeeded();
  }

  componentDidUpdate(prevProps: EmailProps, prevState: EmailState) {
    if (!_.isEqual(prevProps, this.props)) {
      if (!this.state.userHasTouchedForm) {
        this.setInitialState();
      }
    }
    if (!_.isEqual(prevState, this.state)) {
      this.communicateChangesIfNeeded();
    }
  }

  setInitialState() {
    let email = '';
    this.setState({
      userHasTouchedForm: false,
      email: this.createEmailValidated(email),
    });
  }

  isValid() {
    return this.state.email.validationResult.valid;
  }

  communicateChangesIfNeeded() {
    this.props.onEmailChange(this.state.email.value);
    this.props.onValidityChange(this.isValid());
  }

  communicateUserTouchedIfNeeded() {
    if (!this.state.userHasTouchedForm) {
      this.setState({ userHasTouchedForm: true });
    }
  }

  handleEmailChange(email: string) {
    const validated = this.createEmailValidated(email);
    this.setState({ email: validated });
  }

  createEmailValidated(email: string) {
    let required = this.props.reservationMode === ReservationMode.EmailConfirmation;
    if (this.props.user) {
      required = false;
    }
    return {
      value: email,
      validationResult: validateEmail(email, required),
    };
  }

  render() {
    const emailInputClasses = ['form-control'];

    if (this.state.email.validationResult.valid) {
      emailInputClasses.push('is-valid');
    } else {
      emailInputClasses.push('is-invalid');
    }

    const moreHelp = (
      <FAT id="reservation-email-help" icon={faQuestionCircle}>
        Sähköpostiosoite näkyy vain ylläpidolle. Peruuttaaksesi varauksen, rekisteröidy ja kirjaudu
        sisään käyttäen varauksen yhteydessä syöttämääsi sähköpostiosoitetta. Kirjaudu sisään, niin
        sinun ei tarvitse syöttää sähköpostiosoitetta varauksen yhteydessä.
      </FAT>
    );

    let help = (
      <>
        <small className="form-text text-muted" style={{ display: 'inline' }}>
          Sähköpostiosoitteen antaminen on vapaaehtoista. Et voi muokata tai perua varausta, jos et
          anna sähköpostiosoitetta!
        </small>{' '}
        {moreHelp}
      </>
    );
    if (this.props.reservationMode === ReservationMode.EmailConfirmation) {
      help = (
        <>
          <small className="form-text text-muted" style={{ display: 'inline' }}>
            Antamaasi sähköpostiosoitteeseen lähetetään vahvistuslinkki. Vahvistamaton varaus
            poistuu järjestelmästä 15 min kuluttua.
          </small>{' '}
          {moreHelp}
        </>
      );
    }

    if (!this.props.user) {
      return (
        <>
          <hr />
          <div className="v-reservation-email-container">
            <label htmlFor="reservation-email">Email</label>
            <input
              tabIndex={6}
              type="text"
              className={emailInputClasses.join(' ')}
              id="reservation-email"
              value={this.state.email.value}
              onChange={(e) => this.handleEmailChange(e.target.value)}
              placeholder="sähkö@posti.fi"
            />
            {help}
            {!this.state.email.validationResult.valid &&
            this.state.email.validationResult.errorMessages &&
            this.state.email.validationResult.errorMessages.length ? (
              <div className="invalid-feedback">
                <ul>
                  {this.state.email.validationResult.errorMessages.map((msg) => (
                    <li key={msg}>{msg}</li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </>
      );
    }
    return null;
  }
}
