import * as joda from 'js-joda';
import _ from 'lodash';
import * as React from 'react';
import * as uuid from 'uuid';
import {
  NullableDateAndTimeRange,
  OpenDateRange,
  OpenTimeRange,
  RepeatMode,
  ReservationDatetimeRange,
  Rules,
  TimeRange,
} from '../../../models/models';
import {
  concatArrays,
  getVisibleTimes,
  jodaNow,
  reservationActiveOnDate,
  reservationDefaultLength,
} from '../../../utils';
import {
  squashValidationResults,
  startAfterBuilder,
  startBeforeBuilder,
  timeRangeInAtLeastOneRangeBuilder,
  timeRangeMaxLengthBuilder,
  timeRangeMinLengthBuilder,
  timeRangeNotIntersectingBuilder,
  validate,
  ValidationField,
  ValidationResult,
  ValidatorFunc,
  validOpenDateRange,
  validTimeRange,
} from '../../../validators';
import DateInput from '../../DateInput';
import TimeInput from '../../TimeInput';
import './DateAndTimeRangeSelect.scss';

// Note! Does not take into account all possible overlaps with repeating reservations.
// Complete validation of these is intentionally left to be done server side.
function validateReservationDateTimeRange(
  adminReservation: boolean,
  date: joda.LocalDate | undefined,
  repeatLastDate: joda.LocalDate | undefined,
  timeRange: OpenTimeRange,
  rules: Rules | undefined,
  otherReservations: ReservationDatetimeRange[]
): ValidationResult {
  const now = jodaNow();

  const dateAndTimeRangeValidatorFuncs: ValidatorFunc<NullableDateAndTimeRange>[] = [];
  if (!adminReservation) {
    dateAndTimeRangeValidatorFuncs.push(startAfterBuilder(now.plusHours(-1)));
  }
  if (!adminReservation && rules && rules.reservationAllowedDaysToFuture) {
    dateAndTimeRangeValidatorFuncs.push(
      startBeforeBuilder(now.plusDays(rules.reservationAllowedDaysToFuture))
    );
  }

  const timeRangeValidatorFuncs: ValidatorFunc<OpenTimeRange>[] = [
    validTimeRange,
    timeRangeNotIntersectingBuilder(
      otherReservations
        .filter((r) => date && reservationActiveOnDate(r, date))
        .map((r) => r.timeRange)
    ),
  ];
  if (rules) {
    timeRangeValidatorFuncs.push(timeRangeInAtLeastOneRangeBuilder(getVisibleTimes(date, rules)));
  }
  if (!adminReservation && rules && rules.reservationMinLen) {
    // Minus 1 minute, because of the special case of day end being 23.59 instead of 00.00 (which is start of day).
    // Without allowing one minute smaller than the real rule, we wouldn't allow reserving the end of the day.
    timeRangeValidatorFuncs.push(
      timeRangeMinLengthBuilder(rules.reservationMinLen.minusMinutes(1))
    );
  }
  if (!adminReservation && rules && rules.reservationMaxLen) {
    timeRangeValidatorFuncs.push(timeRangeMaxLengthBuilder(rules.reservationMaxLen));
  }

  const dtr: NullableDateAndTimeRange = { date, timeRange };
  const dateRange: OpenDateRange = { start: date, end: repeatLastDate };
  return squashValidationResults(
    validate(dtr, dateAndTimeRangeValidatorFuncs),
    validate(timeRange, timeRangeValidatorFuncs),
    validate(dateRange, [validOpenDateRange])
  );
}

function validateEndDate(date: joda.LocalDate | undefined, required: boolean): ValidationResult {
  if (!date && required) {
    return { valid: false, errorMessages: ['Syötä loppupäivämäärä.'] };
  }
  return { valid: true };
}

function validateStartDate(date: joda.LocalDate | undefined): ValidationResult {
  if (!date) {
    return { valid: false, errorMessages: ['Syötä päivämäärä.'] };
  }
  return { valid: true };
}

function getInitialValidatedFormState(
  formState: DateAndTimeRangeForm,
  rules: Rules | undefined,
  otherReservations: ReservationDatetimeRange[],
  isAdminReservation: boolean
): DateAndTimeRangeForm {
  const timeRange = {
    value: formState.timeRange.value,
    validationResult: validateReservationDateTimeRange(
      isAdminReservation,
      formState.date.value,
      formState.repeatLastDate.value,
      formState.timeRange.value,
      rules,
      otherReservations
    ),
  };

  return {
    repeating: formState.repeating,
    infiniteRepeating: formState.infiniteRepeating,
    date: {
      value: formState.date.value,
      validationResult: { valid: true },
    },
    repeatLastDate: { value: formState.repeatLastDate.value, validationResult: { valid: true } },
    timeRange,
  };
}

function getInitialFormState(
  initialDate: joda.LocalDate | undefined,
  initialTimeRange: TimeRange | undefined,
  rules: Rules | undefined,
  otherReservations: ReservationDatetimeRange[],
  isAdminReservation: boolean
): DateAndTimeRangeSelectState {
  const now = jodaNow();
  let date: joda.LocalDate = initialDate || now.toLocalDate();
  let timeRange: TimeRange;
  if (initialTimeRange) {
    timeRange = initialTimeRange;
  } else {
    let baseStart = now.toLocalTime().truncatedTo(joda.ChronoUnit.HOURS).plusHours(1);
    if (baseStart.hour() > 20) {
      baseStart = joda.LocalTime.parse('10:00');
      date = date.plusDays(1);
    } else if (baseStart.hour() < 10) {
      baseStart = joda.LocalTime.parse('10:00');
    }
    const defaultLength = reservationDefaultLength(rules);
    timeRange = { start: baseStart, end: baseStart.plus(defaultLength) };
  }

  const rawForm: DateAndTimeRangeForm = {
    repeating: false,
    repeatMode: undefined,
    infiniteRepeating: false,
    date: { value: date, validationResult: { valid: true } },
    repeatLastDate: { value: undefined, validationResult: { valid: true } },
    timeRange: {
      value: timeRange,
      validationResult: { valid: true },
    },
  };

  const formState = getInitialValidatedFormState(
    rawForm,
    rules,
    otherReservations,
    isAdminReservation
  );
  return {
    form: formState,
    userHasTouchedForm: false,
  };
}

interface DateAndTimeRangeSelectComponentProps {
  adminReservation?: boolean;
  rules?: Rules;
  otherReservations: ReservationDatetimeRange[];
  initialDate?: joda.LocalDate;
  initialTimeRange?: TimeRange;
  onDateAndTimeRangeChange: (dt: ReservationDatetimeRange | undefined) => void;
  onValidityChange: (v: boolean) => void;
}

interface DateAndTimeRangeSelectProps extends DateAndTimeRangeSelectComponentProps {}

interface DateAndTimeRangeForm {
  repeating: boolean;
  repeatMode?: RepeatMode;
  infiniteRepeating: boolean;
  date: ValidationField<joda.LocalDate | undefined>;
  repeatLastDate: ValidationField<joda.LocalDate | undefined>;
  timeRange: ValidationField<OpenTimeRange>;
}

interface DateAndTimeRangeSelectState {
  userHasTouchedForm: boolean;
  form: DateAndTimeRangeForm;
}

export default class DateAndTimeRangeSelect extends React.Component<
  DateAndTimeRangeSelectProps,
  DateAndTimeRangeSelectState
> {
  static defaultProps: Partial<DateAndTimeRangeSelectProps> = {
    adminReservation: false,
  };

  constructor(props: DateAndTimeRangeSelectProps) {
    super(props);

    this.state = getInitialFormState(
      props.initialDate,
      props.initialTimeRange,
      props.rules,
      props.otherReservations,
      !!props.adminReservation
    );

    this.communicateChangesIfNeeded = this.communicateChangesIfNeeded.bind(this);
    this.setUserTouchedIfNeeded = this.setUserTouchedIfNeeded.bind(this);
    this.handleRepeatingChange = this.handleRepeatingChange.bind(this);
    this.handleRepeatModeChange = this.handleRepeatModeChange.bind(this);
    this.handleInfiniteRepeatingChange = this.handleInfiniteRepeatingChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleRepeatLastDateChange = this.handleRepeatLastDateChange.bind(this);
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
    this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
  }

  componentDidMount() {
    this.setInitialStateFromProps();
    this.communicateChangesIfNeeded();
  }

  componentDidUpdate(
    prevProps: DateAndTimeRangeSelectProps,
    prevState: DateAndTimeRangeSelectState
  ) {
    if (!_.isEqual(prevProps, this.props)) {
      if (!this.state.userHasTouchedForm) {
        this.setInitialStateFromProps();
        this.communicateChangesIfNeeded();
      }
    }
    if (prevProps.adminReservation !== this.props.adminReservation) {
      this.handleRepeatingChange(false, false);
    }
    if (!_.isEqual(prevState, this.state)) {
      this.communicateChangesIfNeeded();
    }
  }

  setInitialStateFromProps() {
    const props = this.props;
    this.setState(
      getInitialFormState(
        props.initialDate,
        props.initialTimeRange,
        props.rules,
        props.otherReservations,
        !!props.adminReservation
      )
    );
  }

  isValid() {
    return (
      this.state.form.date.validationResult.valid &&
      this.state.form.repeatLastDate.validationResult.valid &&
      this.state.form.timeRange.validationResult.valid
    );
  }

  communicateChangesIfNeeded() {
    const { form } = this.state;
    let dt: ReservationDatetimeRange | undefined = undefined;
    if (form.date.validationResult.valid && form.timeRange.validationResult.valid) {
      dt = {
        date: form.date.value as joda.LocalDate,
        timeRange: form.timeRange.value as TimeRange,
        repeating: form.repeating,
        repeatMode: form.repeatMode,
        repeatLastDate: form.repeatLastDate.value,
      };
    }
    this.props.onDateAndTimeRangeChange(dt);
    this.props.onValidityChange(this.isValid());
  }

  setUserTouchedIfNeeded() {
    if (!this.state.userHasTouchedForm) {
      this.setState({ userHasTouchedForm: true });
    }
  }

  handleRepeatingChange(checked: boolean, userChange: boolean = true) {
    this.setState((prevState: DateAndTimeRangeSelectState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        repeating: checked,
        infiniteRepeating: checked,
        repeatMode: checked ? RepeatMode.Weekly : undefined,
      },
    }));
    const newEndDate = checked ? this.state.form.repeatLastDate.value : undefined;
    const newEndDateValidationResult: ValidationResult = checked
      ? this.state.form.repeatLastDate.validationResult
      : { valid: true };
    this.handleRepeatLastDateChange(newEndDate, newEndDateValidationResult, userChange);
  }

  handleInfiniteRepeatingChange(checked: boolean) {
    this.setState((prevState: DateAndTimeRangeSelectState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        infiniteRepeating: checked,
        repeatMode: checked ? prevState.form.repeatMode : undefined,
      },
    }));
    const newEndDate = !checked ? this.state.form.repeatLastDate.value : undefined;
    const newEndDateValidationResult: ValidationResult = !checked
      ? this.state.form.repeatLastDate.validationResult
      : { valid: true };
    this.handleRepeatLastDateChange(newEndDate, newEndDateValidationResult);
  }

  handleRepeatModeChange(repeatMode: RepeatMode | undefined) {
    this.setState((prevState: DateAndTimeRangeSelectState) => ({
      ...prevState,
      form: { ...prevState.form, repeatMode: repeatMode },
    }));
  }

  handleDateChange(date: joda.LocalDate | undefined, dateStrValidationResult: ValidationResult) {
    this.setState((prevState: DateAndTimeRangeSelectState) => {
      const dateValidationResult = validateStartDate(date);
      const dateValidated = {
        value: date,
        validationResult: squashValidationResults(dateStrValidationResult, dateValidationResult),
        touched: true,
      };
      let timeRangeValidated = prevState.form.timeRange;
      if (dateStrValidationResult.valid) {
        timeRangeValidated = this.createTimeRangeValidated(
          dateValidated.value,
          prevState.form.repeatLastDate.value,
          prevState.form.timeRange.value
        );
      }
      return {
        form: {
          ...prevState.form,
          date: dateValidated,
          timeRange: timeRangeValidated,
        },
      };
    });
    this.communicateChangesIfNeeded();
    this.setUserTouchedIfNeeded();
  }

  handleRepeatLastDateChange(
    date: joda.LocalDate | undefined,
    dateStrValidationResult: ValidationResult,
    userChange: boolean = true
  ) {
    this.setState((prevState: DateAndTimeRangeSelectState) => {
      const dateValidationResult = validateEndDate(
        date,
        prevState.form.repeating && !prevState.form.infiniteRepeating
      );
      const dateValidated = {
        value: date,
        validationResult: squashValidationResults(dateStrValidationResult, dateValidationResult),
        touched: true,
      };
      let timeRangeValidated = prevState.form.timeRange;
      if (dateStrValidationResult.valid) {
        timeRangeValidated = this.createTimeRangeValidated(
          prevState.form.date.value,
          dateValidated.value,
          prevState.form.timeRange.value
        );
      }
      return {
        form: {
          ...prevState.form,
          repeatLastDate: dateValidated,
          timeRange: { ...prevState.form.timeRange, ...timeRangeValidated },
        },
      };
    });
    this.communicateChangesIfNeeded();
    if (userChange) {
      this.setUserTouchedIfNeeded();
    }
  }

  handleStartTimeChange(
    time: joda.LocalTime | undefined,
    timeStrValidationResult: ValidationResult
  ) {
    this.setState((prevState: DateAndTimeRangeSelectState) => {
      const timeRangeValidated = this.createTimeRangeValidated(
        prevState.form.date.value,
        prevState.form.repeatLastDate.value,
        {
          start: time,
          end: prevState.form.timeRange.value.end,
        }
      );
      timeRangeValidated.validationResult = squashValidationResults(
        timeStrValidationResult,
        timeRangeValidated.validationResult
      );
      return {
        form: {
          ...prevState.form,
          timeRange: { ...timeRangeValidated, touched: true },
        },
      };
    });
    this.communicateChangesIfNeeded();
    this.setUserTouchedIfNeeded();
  }

  handleEndTimeChange(time: joda.LocalTime | undefined, timeStrValidationResult: ValidationResult) {
    this.setState((prevState: DateAndTimeRangeSelectState) => {
      const timeRangeValidated = this.createTimeRangeValidated(
        prevState.form.date.value,
        prevState.form.repeatLastDate.value,
        {
          start: prevState.form.timeRange.value.start,
          end: time,
        }
      );
      timeRangeValidated.validationResult = squashValidationResults(
        timeStrValidationResult,
        timeRangeValidated.validationResult
      );
      return {
        form: {
          ...prevState.form,
          timeRange: { ...timeRangeValidated, touched: true },
        },
      };
    });
    this.communicateChangesIfNeeded();
    this.setUserTouchedIfNeeded();
  }

  // Validation results will contain results for date + time range combo.
  createTimeRangeValidated(
    date: joda.LocalDate | undefined,
    repeatLastDate: joda.LocalDate | undefined,
    timeRange: OpenTimeRange
  ) {
    return {
      value: timeRange,
      validationResult: validateReservationDateTimeRange(
        !!this.props.adminReservation,
        date,
        repeatLastDate,
        timeRange,
        this.props.rules,
        this.props.otherReservations
      ),
    };
  }

  render() {
    const form = this.state.form;
    const datetimeValid = this.isValid();
    const datetimeErrorMessages = concatArrays([
      form.date.validationResult.errorMessages,
      form.repeatLastDate.validationResult.errorMessages,
      form.timeRange.validationResult.errorMessages,
    ]);
    let tabIndex = 1;

    return (
      <>
        <label htmlFor="reservation-description">Aika</label>
        {this.props.adminReservation && (
          <div className="form-check" style={{ paddingTop: '5px', paddingBottom: '5px' }}>
            <input
              id="repeat-check"
              className="form-check-input"
              type="checkbox"
              checked={this.state.form.repeating}
              onChange={(e) => this.handleRepeatingChange(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="repeat-check">
              Toistuva varaus
            </label>
          </div>
        )}
        {this.props.adminReservation && this.state.form.repeating && (
          <div style={{ paddingBottom: '5px', paddingLeft: '20px' }}>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="repeat-mode"
                id="repeat-mode-weekly"
                value="weekly"
                checked={this.state.form.repeatMode === RepeatMode.Weekly}
                onChange={(e) => this.handleRepeatModeChange(e.target.value as RepeatMode)}
              />
              <label className="form-check-label" htmlFor="repeat-mode-weekly">
                Viikoittain
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="repeat-mode"
                id="repeat-mode-biweekly"
                value="biweekly"
                checked={this.state.form.repeatMode === RepeatMode.Biweekly}
                onChange={(e) => this.handleRepeatModeChange(e.target.value as RepeatMode)}
              />
              <label className="form-check-label" htmlFor="repeat-mode-biweekly">
                Joka toinen viikko
              </label>
            </div>
          </div>
        )}
        {this.props.adminReservation && this.state.form.repeating && (
          <div className="form-check" style={{ paddingBottom: '5px' }}>
            <input
              id="infinite-repeat-check"
              className="form-check-input"
              type="checkbox"
              checked={this.state.form.infiniteRepeating}
              onChange={(e) => this.handleInfiniteRepeatingChange(e.target.checked)}
            />
            <label className="form-check-label" htmlFor="infinite-repeat-check">
              Jatkuu toistaiseksi
            </label>
          </div>
        )}
        <div className="v-reservation-form-time-container">
          <div className="v-time-inputs">
            <DateInput
              id="startDate"
              tabIndex={tabIndex++}
              value={this.state.form.date.value}
              valid={this.state.form.date.touched ? datetimeValid : undefined}
              onChange={this.handleDateChange}
            />
            {this.state.form.repeating && !this.state.form.infiniteRepeating && (
              <>
                <div>{'-'}</div>
                <DateInput
                  id="repeatLastDate"
                  tabIndex={tabIndex++}
                  value={this.state.form.repeatLastDate.value}
                  valid={this.state.form.repeatLastDate.touched ? datetimeValid : undefined}
                  onChange={this.handleRepeatLastDateChange}
                />
              </>
            )}
          </div>

          <div className="v-time-inputs">
            <TimeInput
              id="startTime"
              tabIndex={tabIndex++}
              value={this.state.form.timeRange.value.start}
              valid={this.state.form.timeRange.touched ? datetimeValid : undefined}
              onChange={this.handleStartTimeChange}
            />
            <div>{'-'}</div>
            <TimeInput
              id="endTime"
              tabIndex={tabIndex++}
              value={this.state.form.timeRange.value.end}
              valid={this.state.form.timeRange.touched ? datetimeValid : undefined}
              onChange={this.handleEndTimeChange}
            />
          </div>
          {!datetimeValid && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              <ul>
                {datetimeErrorMessages.map((msg) => (
                  <li key={uuid.v4()}>{msg}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </>
    );
  }
}
