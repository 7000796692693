import * as React from 'react';
import { Route, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import StaticModalNotification from '../StaticModalNotification';
import { VarauksetState } from '../../redux/store';
import { LoadState } from '../../models/models';
import Login from '../auth/Login';

interface LoggedInProtectedRouteConnectedProps {
  component: React.ComponentType<RouteComponentProps<any> | {}>;
  path: string;
  exact?: boolean;
}

interface LoggedInProtectedRouteProps extends LoggedInProtectedRouteConnectedProps {
  loginLoadState: LoadState;
  loggedIn: boolean;
}

export class LoggedInProtectedRoute extends React.Component<LoggedInProtectedRouteProps> {
  render() {
    return (
      <Route
        exact={this.props.exact}
        path={this.props.path}
        render={(routeProps: any) => {
          const { loginLoadState, loggedIn } = this.props;
          if (loggedIn) {
            return <this.props.component {...routeProps} />;
          } else if (loginLoadState === LoadState.InProgress) {
            return (
              <StaticModalNotification>Tarkastetaan kirjautumista ...</StaticModalNotification>
            );
          }
          return <Login {...routeProps} />;
        }}
      />
    );
  }
}

const mapStateToProps = (state: VarauksetState, ownProps: LoggedInProtectedRouteConnectedProps) => {
  return {
    loginLoadState: state.user.loginLoadState,
    loggedIn: !!state.token.token,
  };
};

const LoggedInProtectedRouteConnected = connect(mapStateToProps)(LoggedInProtectedRoute as any);

export default LoggedInProtectedRouteConnected;
