import { Dispatch } from 'redux';
import { Action, createAction } from 'redux-actions';
import * as api from '../apiClient';
import { DateRange, Organization, ReportType, UserLevel } from '../models/models';
import { GetState } from './store';
import { startWait, stopWait } from './wait';

export enum PaymentReport {
  RECEIVE_ORGANIZATIONS = 'varaukset/paymentReport/RECEIVE_ORGANIZATIONS',
}

export const receiveOrganizations = createAction<Organization[]>(
  PaymentReport.RECEIVE_ORGANIZATIONS
);

export function getManagedOrganizationsAsync() {
  return async (dispatch: Dispatch<any>, getState: GetState) => {
    const state = getState();
    const user = state.user.activeUser;
    if (user) {
      if (user.level === UserLevel.Superuser) {
        dispatch(startWait());
        const orgs = await api.getOrganizationsFull();
        dispatch(stopWait());
        dispatch(receiveOrganizations(orgs));
      } else {
        dispatch(receiveOrganizations(user.managedOrganizations));
      }
    }
  };
}

export function downloadReportAsync(
  dateRange: DateRange,
  organizationId: string,
  reportType: ReportType
) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startWait());
    if (reportType === ReportType.Payments) {
      await api.downloadPaymentsReport(dateRange, organizationId);
    } else if (reportType === ReportType.Reservations) {
      await api.downloadReservationsReport(dateRange, organizationId);
    }
    dispatch(stopWait());
  };
}

export interface PaymentReportState {
  organizations: Organization[];
}

const paymentReportInitialState: PaymentReportState = {
  organizations: [],
};

export default function paymentReportReducer(
  state: PaymentReportState = paymentReportInitialState,
  action: Action<any>
): PaymentReportState {
  switch (action.type) {
    case PaymentReport.RECEIVE_ORGANIZATIONS:
      return { ...state, organizations: action.payload };

    default:
      return state;
  }
}
