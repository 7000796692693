import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { confirmEmailAsync } from '../../redux/confirm';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import { ContentContainer } from '../ContentContainer';
import { VarauksetState } from '../../redux/store';
import { Link } from 'react-router-dom';
import * as paths from '../../routerPaths';

interface ConfirmEmailStateProps {
  confirmed?: boolean;
}

interface ConfirmEmailRouterProps {
  confirmationToken: string;
}

interface ConfirmEmailDispatchProps {
  onConfirm(confirmationToken: string): void;
}

interface ConfirmEmailProps
  extends ConfirmEmailStateProps,
    ConfirmEmailDispatchProps,
    ConfirmEmailRouterProps {}

export class ConfirmEmail extends React.Component<ConfirmEmailProps> {
  componentDidMount() {
    this.props.onConfirm(this.props.confirmationToken);
  }

  render() {
    return (
      <ContentContainer>
        <ScrollToTopOnMount />
        {this.props.confirmed === undefined && <p>⌛ Odota ...</p>}
        {this.props.confirmed === true && (
          <>
            <p>✔️ Sähköpostiosoite vahvistettu!</p>
            <p>
              <Link to={paths.loginUrl}>Kirjaudu seuraavaksi sisään (klikkaa tästä)</Link>
            </p>
          </>
        )}
        {this.props.confirmed === false && (
          <>
            <p>❌ Vahvistaminen epäonnistui.</p>
            <p>Kokeile vahvistuslinkkiä uudelleen. Jos se ei auta, voit kysyä apua ylläpidolta.</p>
          </>
        )}
      </ContentContainer>
    );
  }
}

const mapStateToProps = (state: VarauksetState): ConfirmEmailStateProps => ({
  confirmed: state.confirm.emailConfirmed,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<VarauksetState, undefined, AnyAction>
): ConfirmEmailDispatchProps => ({
  onConfirm: (confirmationToken: string) => dispatch(confirmEmailAsync(confirmationToken)),
});

const ConfirmEmailConnected = connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);

export default withRouter((props: RouteComponentProps<any>) => (
  <ConfirmEmailConnected confirmationToken={props.match.params.confirmationToken} />
));
