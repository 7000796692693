import { Action, createAction } from 'redux-actions';
import { UserAction } from './userSharedActions';

export enum TokenAction {
  RECEIVE_TOKEN = 'varaukset/token/RECEIVE_TOKEN',
}

export const receiveToken = createAction<string | null>(TokenAction.RECEIVE_TOKEN);

export interface TokenState {
  token: string | null;
}

const tokenInitialState: TokenState = {
  token: null,
};

// Token reducer is separate just so that it can easily be separately persisted and rehydrated
// to/from local storage
export default function tokenReducer(
  state: TokenState = tokenInitialState,
  action: Action<any>
): TokenState {
  switch (action.type) {
    case TokenAction.RECEIVE_TOKEN:
      return { ...state, token: action.payload };
    case UserAction.LOGOUT:
      return tokenInitialState;

    default:
      return state;
  }
}
