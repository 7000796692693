import * as React from 'react';
import { connect } from 'react-redux';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VarauksetState } from '../redux/store';
import './FullPageWaitIndicator.scss';

interface FullPageWaitIndicatorProps {
  visible: boolean;
}

export const FullPageWaitIndicator = (props: FullPageWaitIndicatorProps) => {
  return (
    <div style={{ display: props.visible ? '' : 'none' }} className="v-full-spinner-container">
      <div className="v-spinner">
        <FontAwesomeIcon icon={faSpinner} pulse={true} size="5x" />
      </div>
    </div>
  );
};

const mapStateToProps = (state: VarauksetState) => ({
  visible: state.wait.waitOperationCount > 0,
});

export default connect(mapStateToProps)(FullPageWaitIndicator);
