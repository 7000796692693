import { TimeRange, NullableDateAndTimeRange, OpenTimeRange, OpenDateRange } from './models/models';
import { timeRangesIntersect, getDecimalPlaces, stringToFloat } from './utils';
import moment from 'moment';
import * as joda from 'js-joda';
import _ from 'lodash';

export interface ValidationField<T> {
  value: T;
  validationResult: ValidationResult;
  touched?: boolean;
}

export interface ValidationResult {
  valid: boolean;
  errorMessages?: string[];
}

export type ValidatorFunc<T> = (value: T) => ValidationResult;

export function validate<T>(value: T, validators: ValidatorFunc<T>[]): ValidationResult {
  let errorMessages: string[] = [];
  let valid = true;
  for (let validator of validators) {
    const result = validator(value);
    if (!result.valid) {
      valid = false;
      if (result.errorMessages && result.errorMessages.length) {
        errorMessages = errorMessages.concat(result.errorMessages);
      }
    }
  }
  return { valid, errorMessages };
}

export function squashValidationResults(...results: ValidationResult[]): ValidationResult {
  let errorMessages: string[] = [];
  let valid = true;
  for (let result of results) {
    if (!result.valid) {
      valid = false;
      if (result.errorMessages && result.errorMessages.length) {
        errorMessages = errorMessages.concat(result.errorMessages);
      }
    }
  }
  return { valid, errorMessages };
}

export function requiredString(value?: string): ValidationResult {
  if (value && _.clone(value).trim().length > 0) {
    return { valid: true };
  } else {
    return { valid: false, errorMessages: ['Kenttä on pakollinen.'] };
  }
}

export function minLengthBuilder(minLength: number) {
  return (value?: string): ValidationResult => {
    if (value && _.clone(value).trim().length >= minLength) {
      return { valid: true };
    } else {
      return { valid: false, errorMessages: [`Vähintään ${minLength} merkkiä.`] };
    }
  };
}

export function isEqualBuilder(expected?: string) {
  return (value?: string): ValidationResult => {
    if (value === expected) {
      return { valid: true };
    } else {
      return { valid: false, errorMessages: ['Ei täsmää.'] };
    }
  };
}

export function validEmailBuilder(requiredField: boolean = true, blacklist: string[] = []) {
  return (e?: string) => {
    const trimmed = e ? e.trim() : '';

    if (!requiredField && !trimmed) {
      return { valid: true };
    }
    if (requiredField && !trimmed) {
      return { valid: false, errorMessages: ['Kenttä on pakollinen.'] };
    }

    if (blacklist.find((b) => b.trim().toLocaleLowerCase() === trimmed.toLocaleLowerCase())) {
      return { valid: false, errorMessages: ['Tarkista sähköpostiosoite.'] };
    }

    // tslint:disable-next-line:max-line-length
    const re =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (trimmed.length < 254 && re.test(trimmed)) {
      return { valid: true };
    } else {
      return { valid: false, errorMessages: ['Tarkista sähköpostiosoite.'] };
    }
  };
}

export function validTimeRange(timeRange: OpenTimeRange): ValidationResult {
  if (!timeRange.start) {
    return { valid: false, errorMessages: ['Syötä alkuaika.'] };
  }
  if (!timeRange.end) {
    return { valid: false, errorMessages: ['Syötä loppuaika.'] };
  }
  if (timeRange.start.isAfter(timeRange.end)) {
    return { valid: false, errorMessages: ['Valitun ajan alun täytyy olla ennen loppua.'] };
  }
  return { valid: true };
}

export function validDateRange(dateRange: OpenDateRange): ValidationResult {
  if (!dateRange.start) {
    return { valid: false, errorMessages: ['Syötä alkupäivämäärä.'] };
  }
  if (!dateRange.end) {
    return { valid: false, errorMessages: ['Syötä loppupäivämäärä.'] };
  }

  if (dateRange.start.isAfter(dateRange.end)) {
    return { valid: false, errorMessages: ['Valitun alun täytyy olla ennen loppua.'] };
  }
  return { valid: true };
}

export function validOpenDateRange(dateRange: OpenDateRange): ValidationResult {
  if (!(dateRange.start === undefined || dateRange.end === undefined)) {
    if (dateRange.start.isAfter(dateRange.end)) {
      return { valid: false, errorMessages: ['Valitun alun täytyy olla ennen loppua.'] };
    }
  }
  return { valid: true };
}

export function startAfterBuilder(
  limitDateTime: joda.LocalDateTime
): ValidatorFunc<NullableDateAndTimeRange> {
  return (dtr: NullableDateAndTimeRange): ValidationResult => {
    const { date, timeRange } = dtr;
    if (!(date === undefined || timeRange.start === undefined || timeRange.end === undefined)) {
      if (date.atTime(timeRange.start).isBefore(limitDateTime)) {
        return {
          valid: false,
          errorMessages: ['Valittu aika ei saa olla menneisyydessä.'],
        };
      }
    }
    return { valid: true };
  };
}

export function startBeforeBuilder(
  limitDateTime: joda.LocalDateTime,
  errorMsg?: string
): ValidatorFunc<NullableDateAndTimeRange> {
  return (dtr: NullableDateAndTimeRange): ValidationResult => {
    const { date, timeRange } = dtr;
    if (!(date === undefined || timeRange.start === undefined || timeRange.end === undefined)) {
      if (date.atTime(timeRange.start).isAfter(limitDateTime)) {
        return {
          valid: false,
          errorMessages: [errorMsg || 'Valittu aika ei saa olla näin pitkällä tulevaisuudessa.'],
        };
      }
    }
    return { valid: true };
  };
}

export function timeRangeInAtLeastOneRangeBuilder(
  validTimeRanges: TimeRange[],
  errorMsg?: string
): ValidatorFunc<OpenTimeRange> {
  return (timeRange: OpenTimeRange): ValidationResult => {
    if (!(timeRange.start === undefined || timeRange.end === undefined)) {
      for (let validRange of validTimeRanges) {
        if (
          !(timeRange.start.isBefore(validRange.start) || timeRange.end.isAfter(validRange.end))
        ) {
          return { valid: true };
        }
      }
    }
    return {
      valid: false,
      errorMessages: [errorMsg || 'Valittu aika ei ole sallituissa rajoissa.'],
    };
  };
}

export function timeRangeNotIntersectingBuilder(
  invalidTimeRanges: TimeRange[],
  errorMsg?: string
): ValidatorFunc<OpenTimeRange> {
  return (timeRange: OpenTimeRange): ValidationResult => {
    if (!(timeRange.start === undefined || timeRange.end === undefined)) {
      for (let invalidTimeRange of invalidTimeRanges) {
        if (timeRangesIntersect(timeRange as TimeRange, invalidTimeRange)) {
          return {
            valid: false,
            errorMessages: [errorMsg || 'Valittu aika osuu päällekkäin toisen varauksen kanssa.'],
          };
        }
      }
    }
    return { valid: true };
  };
}

export function dateString(str: string): ValidationResult {
  if (!str) {
    return { valid: true };
  }
  if (moment(str, 'YYYY-MM-DD', true).isValid()) {
    return { valid: true };
  }
  return {
    valid: false,
    errorMessages: ['Syötä päivämäärä muodossa VVVV-KK-PP, esim. 2017-12-31.'],
  };
}

export function timeString(str: string): ValidationResult {
  if (!str) {
    return { valid: true };
  }
  try {
    joda.LocalTime.parse(str);
    return { valid: true };
  } catch {
    return { valid: false, errorMessages: ['Syötä kellonaika muodossa TT:MM, esim. 16:59.'] };
  }
}

export function timeRangeMinLengthBuilder(duration: joda.Duration): ValidatorFunc<OpenTimeRange> {
  return (timeRange: OpenTimeRange): ValidationResult => {
    if (!(timeRange.start === undefined || timeRange.end === undefined)) {
      if (joda.Duration.between(timeRange.start, timeRange.end).toMillis() < duration.toMillis()) {
        return {
          valid: false,
          errorMessages: [`Lyhin sallittu aika on ${duration.toMinutes()} minuuttia.`],
        };
      }
    }
    return { valid: true };
  };
}

export function timeRangeMaxLengthBuilder(duration: joda.Duration): ValidatorFunc<OpenTimeRange> {
  return (timeRange: OpenTimeRange): ValidationResult => {
    if (!(timeRange.start === undefined || timeRange.end === undefined)) {
      if (joda.Duration.between(timeRange.start, timeRange.end).toMillis() > duration.toMillis()) {
        return {
          valid: false,
          errorMessages: [`Pisin sallittu aika on ${duration.toMinutes()} minuuttia.`],
        };
      }
    }
    return { valid: true };
  };
}

export function sufficientFundsBuilder(availableFunds: number): ValidatorFunc<number> {
  return (reservationTotalPrice: number): ValidationResult => {
    if (reservationTotalPrice > availableFunds) {
      return {
        valid: false,
        errorMessages: [
          `Tilisi saldo (${availableFunds.toFixed(2)} €) ei riitä varauksen tekemiseen.`,
        ],
      };
    }
    return { valid: true };
  };
}

export function isNumber(value: string): ValidationResult {
  const f = stringToFloat(value);
  if (f === undefined) {
    return {
      valid: false,
      errorMessages: ['Syötetty arvo ei ole luku.'],
    };
  }
  return { valid: true };
}

export function isValidMoney(amount: number): ValidationResult {
  const decimalPlaces = getDecimalPlaces(amount);
  if (decimalPlaces > 2) {
    return {
      valid: false,
      errorMessages: ['Desimaaliosan maksimipituus on kaksi numeroa. Esim. 0,01'],
    };
  }
  return { valid: true };
}

export function numberMinBuilder(minValue: number): ValidatorFunc<number> {
  return (value: number): ValidationResult => {
    if (value < minValue) {
      return { valid: false, errorMessages: [`Luvun täytyy olla vähintään ${minValue}.`] };
    }
    return { valid: true };
  };
}

export function numberMaxBuilder(maxValue: number): ValidatorFunc<number> {
  return (value: number): ValidationResult => {
    if (value > maxValue) {
      return { valid: false, errorMessages: [`Luku saa olla korkeintaan ${maxValue}.`] };
    }
    return { valid: true };
  };
}
