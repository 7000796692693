import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import { createLogger } from 'redux-logger';
import { persistCombineReducers, PersistConfig, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { devEnv, prodEnv } from '../utils';
import history from './browserHistory';
import calendar, { CalendarState } from './calendar';
import confirm, { ConfirmState } from './confirm';
import myAccountTransactions, { MyAccountTransactionsState } from './myAccountTransactions';
import myReservations, { MyReservationsState } from './myReservations';
import organization, { OrganizationState } from './organization';
import paymentReport, { PaymentReportState } from './paymentReport';
import reservationDetails, { ReservationDetailsState } from './reservationDetails';
import resource, { ResourceState } from './resource';
import token, { TokenState } from './token';
import user, { UserState } from './user';
import wait, { WaitAction, WaitState } from './wait';

export interface VarauksetState {
  calendar: CalendarState;
  resource: ResourceState;
  organization: OrganizationState;
  wait: WaitState;
  user: UserState;
  token: TokenState;
  myReservations: MyReservationsState;
  myAccountTransactions: MyAccountTransactionsState;
  confirm: ConfirmState;
  reservationDetails: ReservationDetailsState;
  paymentReport: PaymentReportState;
}

export type GetState = () => VarauksetState;

const persistConfig: PersistConfig<VarauksetState> = {
  key: 'primary',
  storage,
  // Persist only token in tokenReducer
  whitelist: ['token'],
};

// TODO: Any type
const rootReducer = persistCombineReducers<VarauksetState, any>(persistConfig, {
  calendar,
  resource,
  organization,
  wait,
  user,
  token,
  myReservations,
  myAccountTransactions,
  confirm,
  reservationDetails,
  paymentReport,
});

function isDoNotTrack() {
  let doNotTrack = undefined;
  if ((window as any).doNotTrack || navigator.doNotTrack) {
    if (
      (window as any).doNotTrack === '1' ||
      navigator.doNotTrack === 'yes' ||
      navigator.doNotTrack === '1'
    ) {
      doNotTrack = true;
    } else {
      doNotTrack = false;
    }
  } else {
    doNotTrack = undefined;
  }
  return doNotTrack === undefined ? false : doNotTrack;
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

if (isDoNotTrack()) {
  console.log('"Do not track" detected.');
} else if (prodEnv()) {
  const ga4Tag = process.env.REACT_APP_GA4_TAG || 'no-ga4-tracking-code';

  ReactGA.initialize([{ trackingId: ga4Tag, gaOptions: { anonymize_ip: true } }]);
  ReactGA.send('pageview');
} else {
  console.log('Development GA: ' + window.location.pathname + window.location.search);
}

history.listen((location, action) => {
  if (prodEnv()) {
    ReactGA.send('pageview');
  } else {
    console.log('Development GA: ' + location.pathname + location.search);
  }
});

const middlewares: Middleware[] = [thunk];

if (devEnv()) {
  const reduxLoggerIgnoreActionTypes = [WaitAction.START_WAIT, WaitAction.STOP_WAIT];
  const loggerMiddleware = createLogger({
    predicate: (getState, action) => !reduxLoggerIgnoreActionTypes.find((i) => i === action.type),
    collapsed: true,
  });
  middlewares.push(loggerMiddleware);
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// TODO: Any types
const store = createStore<any, any, any, any>(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
);

persistStore(store, null, () => {
  if (devEnv()) {
    console.log('Rehydration complete');
  }
});

export { store, history };
