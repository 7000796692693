import * as React from 'react';
import './Hour.scss';

interface HourProps {
  remHeight: number;
  readOnly?: boolean; // Calendar in read-only mode
  enabled?: boolean; // Disabled e.g. if past.
  onPress?(minute: number): void;
}

export const Hour: React.FC<HourProps> = (props) => {
  const commonStyle = {
    height: `${props.remHeight / 2}rem`,
  };

  const topClassNames = ['v-hour-top-cell'];
  const bottomClassNames = ['v-hour-bottom-cell'];

  if (!props.readOnly && props.enabled) {
    topClassNames.push('v-hour-reservable');
    bottomClassNames.push('v-hour-reservable');
  }

  return (
    <div>
      <div
        style={commonStyle}
        className={topClassNames.join(' ')}
        onClick={() => !props.readOnly && props.onPress && props.onPress(0)}
      />
      <div
        style={commonStyle}
        className={bottomClassNames.join(' ')}
        onClick={() => !props.readOnly && props.onPress && props.onPress(30)}
      />
    </div>
  );
};

export default Hour;
