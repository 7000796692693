import * as React from 'react';
import { formatPrice } from '../../utils';

export const HourPriceExamples: React.FC<{ price: number | undefined }> = (props) => {
  return (
    <ul>
      <li>5 min: {props.price ? formatPrice(props.price) : '1 * tuntihinta'}</li>
      <li>30 min: {props.price ? formatPrice(props.price) : '1 * tuntihinta'}</li>
      <li>60 min: {props.price ? formatPrice(props.price) : '1 * tuntihinta'}</li>
      <li>61 min: {props.price ? formatPrice(props.price * 2) : '2 * tuntihinta'}</li>
      <li>120 min: {props.price ? formatPrice(props.price * 2) : '2 * tuntihinta'}</li>
    </ul>
  );
};

export const HalfHourPriceExamples: React.FC<{ price: number }> = (props) => {
  return (
    <ul>
      <li>5 min: {props.price ? formatPrice(props.price * 0.5) : '0.5 * tuntihinta'}</li>
      <li>30 min: {props.price ? formatPrice(props.price * 0.5) : '0.5 * tuntihinta'}</li>
      <li>60 min: {props.price ? formatPrice(props.price) : '1 * tuntihinta'}</li>
      <li>61 min: {props.price ? formatPrice(props.price * 1.5) : '1.5 * tuntihinta'}</li>
      <li>120 min: {props.price ? formatPrice(props.price * 2) : '2 * tuntihinta'}</li>
    </ul>
  );
};
