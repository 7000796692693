import * as React from 'react';
import { connect } from 'react-redux';
import { match, matchPath, Route, RouteComponentProps, withRouter } from 'react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { LoadState, Organization, ReservationMode } from '../../models/models';
import { fetchResourcesAsync } from '../../redux/resource';
import { VarauksetState } from '../../redux/store';
import Login from '../auth/Login';
import StaticModalNotification from '../StaticModalNotification';

interface ResourceModeRouteWithRouterProps {
  component: React.ComponentType<RouteComponentProps<any> | {}>;
  path: string;
  exact?: boolean;
}

interface ResourceModeRouteConnectedProps extends ResourceModeRouteWithRouterProps {
  resourceId?: string;
}

interface ResourceModeRouteProps extends ResourceModeRouteConnectedProps {
  loginRequired: boolean;
  loginLoadState: LoadState;
  loggedIn: boolean;
  organization: Organization;
  userFetchFired: boolean;
  resourcesFetchFired: boolean;
  onFetchResources(organizationId: string): void;
}

export class ResourceModeRoute extends React.Component<ResourceModeRouteProps> {
  componentDidMount() {
    this.fetchResourcesIfNeeded(this.props);
  }

  componentWillReceiveProps(newProps: ResourceModeRouteProps) {
    this.fetchResourcesIfNeeded(newProps);
  }

  fetchResourcesIfNeeded(props: ResourceModeRouteProps) {
    if (props.loginRequired === undefined && !props.resourcesFetchFired) {
      this.props.onFetchResources(props.organization.id);
    }
  }

  render() {
    return (
      <Route
        exact={this.props.exact}
        path={this.props.path}
        render={(routeProps: any) => {
          const { loginLoadState, loggedIn, loginRequired } = this.props;
          if (loginRequired) {
            if (loginLoadState === LoadState.InProgress) {
              return (
                <StaticModalNotification>Tarkastetaan kirjautumista ...</StaticModalNotification>
              );
            } else if (loginLoadState === LoadState.Unknown || !loggedIn) {
              return <Login {...routeProps} />;
            }
          } else if (loginRequired === undefined) {
            return <StaticModalNotification>Tarkastetaan resurssia ...</StaticModalNotification>;
          }
          return <this.props.component {...routeProps} />;
        }}
      />
    );
  }
}

const mapStateToProps = (state: VarauksetState, ownProps: ResourceModeRouteConnectedProps) => {
  let loginRequired: boolean | undefined = undefined;
  if (state.resource.resourcesFetchState !== LoadState.Unknown) {
    const resource = state.resource.resources.find((r) => r.id === ownProps.resourceId);
    if (resource) {
      loginRequired = resource.reservationMode === ReservationMode.LoginRequired;
    } else if (state.resource.resourcesFetchState === LoadState.Finished) {
      console.warn(`Non existing resource id: ${ownProps.resourceId}`);
    }
  }
  return {
    loginLoadState: state.user.loginLoadState,
    loggedIn: !!state.token.token,
    organization: state.organization.organization,
    loginRequired,
    resourcesFetchFired: state.resource.resourcesFetchState !== LoadState.Unknown,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<VarauksetState, undefined, AnyAction>) => {
  return {
    onFetchResources: (organizationId: string) => dispatch(fetchResourcesAsync(organizationId)),
  };
};

const ResourceModeRouteConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceModeRoute as any);

export default withRouter((props: ResourceModeRouteConnectedProps & RouteComponentProps<any>) => {
  // For some reason, props.match.params is always an empty object.
  // Lets find parameters manually.
  const m = matchPath(props.location.pathname, { path: props.path }) as match<any> | null;
  return <ResourceModeRouteConnected {...props} resourceId={m && m.params.resourceId} />;
});
