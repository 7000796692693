import { Action, createAction } from 'redux-actions';
import { Dispatch } from 'redux';
import { startWait, stopWait } from './wait';
import * as api from '../apiClient';

export enum ConfirmAction {
  RECEIVE_RESERVATION_CONFIRMED = 'varaukset/confirm/RECEIVE_RESERVATION_CONFIRMED',
  RECEIVE_EMAIL_CONFIRMED = 'varaukset/confirm/RECEIVE_EMAIL_CONFIRMED',
}

export const receiveReservationConfirmed = createAction<boolean>(
  ConfirmAction.RECEIVE_RESERVATION_CONFIRMED
);

export function confirmReservationAsync(token: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startWait());
    const confirmed = await api.confirmReservation(token);
    dispatch(stopWait());
    dispatch(receiveReservationConfirmed(confirmed));
  };
}

export const receiveEmailConfirmed = createAction<boolean>(ConfirmAction.RECEIVE_EMAIL_CONFIRMED);

export function confirmEmailAsync(token: string) {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startWait());
    const confirmed = await api.confirmEmail(token);
    dispatch(stopWait());
    dispatch(receiveEmailConfirmed(confirmed));
  };
}

export interface ConfirmState {
  reservationConfirmed?: boolean;
  emailConfirmed?: boolean;
}

const confirmInitialState: ConfirmState = {
  reservationConfirmed: undefined,
  emailConfirmed: undefined,
};

export default function confirmReducer(
  state: ConfirmState = confirmInitialState,
  action: Action<any>
): ConfirmState {
  switch (action.type) {
    case ConfirmAction.RECEIVE_RESERVATION_CONFIRMED:
      return { ...state, reservationConfirmed: action.payload };
    case ConfirmAction.RECEIVE_EMAIL_CONFIRMED:
      return { ...state, emailConfirmed: action.payload };

    default:
      return state;
  }
}
