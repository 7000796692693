import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as uuid from 'uuid';
import * as api from '../../apiClient';
import { StripePaymentIntent } from '../../models/models';
import { createToast } from '../../notification';
import { VarauksetState } from '../../redux/store';
import { startWait, stopWait } from '../../redux/wait';
import * as routerPaths from '../../routerPaths';
import { ScrollToTopOnMount } from '../../ScrollToTopOnMount';
import { stringToFloat } from '../../utils';
import {
  isNumber,
  isValidMoney,
  numberMaxBuilder,
  numberMinBuilder,
  requiredString,
  squashValidationResults,
  validate,
  ValidationField,
} from '../../validators';
import { ContentContainer } from '../ContentContainer';
import './LoadMoney.scss';
import LoadMoneyConfirmModal from './LoadMoneyConfirmModal';
import PaymentInfoModal from './PaymentInfoModal';

const visaImg = require('../../images/visa.png');
const visaeImg = require('../../images/visae.png');
const masterImg = require('../../images/mastercard.png');
const amexImg = require('../../images/amex.png');
const stripeImg = require('../../images/powered_by_stripe.png');

const transactionFee = 1;

function createAmountValidated(amountStr: string) {
  let validationResult = validate(amountStr, [requiredString, isNumber]);
  if (validationResult.valid) {
    validationResult = squashValidationResults(
      validationResult,
      validate(stringToFloat(amountStr) as number, [
        isValidMoney,
        numberMinBuilder(0.01),
        numberMaxBuilder(999),
      ])
    );
  }

  return {
    value: amountStr,
    touched: true,
    validationResult,
  };
}

async function createOrUpdatePaymentIntent(
  existingPaymentIntent: StripePaymentIntent | undefined,
  amount: number,
  transactionFee: number
): Promise<StripePaymentIntent | undefined> {
  if (existingPaymentIntent) {
    const response = await api.updateStripePaymentIntent(
      existingPaymentIntent.id,
      amount,
      transactionFee
    );
    if (response.success) {
      return { ...existingPaymentIntent };
    }
  } else {
    const response = await api.createStripePaymentIntent(amount, transactionFee);
    if (response.success) {
      return response.data;
    }
  }
  return undefined;
}

interface LoadMoneyFormState {
  amountStr: ValidationField<string>;
  card: { errorMsg: string; complete: boolean };
}

const formInitialState: LoadMoneyFormState = {
  amountStr: {
    value: '',
    validationResult: { valid: false },
    touched: false,
  },
  card: {
    complete: false,
    errorMsg: '',
  },
};

const LoadMoney: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const user = useSelector((state: VarauksetState) => state.user.activeUser);
  const dispatch = useDispatch();

  const [formState, setFormState] = useState<LoadMoneyFormState>(formInitialState);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState<StripePaymentIntent>();
  const [paymentIntentLoading, setPaymentIntentLoading] = useState(false);
  const [handlingPayment, setHandlingPayment] = useState(false);

  async function openConfirmModal(event: React.SyntheticEvent<any>) {
    event.preventDefault();
    const amount = getAmountNumber();
    if (amount) {
      setConfirmModalOpen(true);
      setPaymentIntentLoading(true);
      dispatch(startWait());
      let newPaymentIntent: StripePaymentIntent | undefined;
      newPaymentIntent = await createOrUpdatePaymentIntent(paymentIntent, amount, transactionFee);
      setPaymentIntent(newPaymentIntent);
      setPaymentIntentLoading(false);
      dispatch(stopWait());
    }
  }

  function closeConfirmModal() {
    setConfirmModalOpen(false);
  }

  function openInfoModal(event: React.SyntheticEvent<any>) {
    event.preventDefault();
    setInfoModalOpen(true);
  }

  function closeTermsModal() {
    setInfoModalOpen(false);
  }

  function getAmountNumber() {
    return stringToFloat(formState.amountStr.value);
  }

  async function handleOk() {
    dispatch(startWait());
    setHandlingPayment(true);
    const amount = getAmountNumber();
    if (stripe && elements && amount && paymentIntent) {
      closeConfirmModal();
      const cardElement = elements.getElement(CardElement);
      if (cardElement) {
        const now = new Date();
        const { error } = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
          payment_method: {
            card: cardElement,
          },
        });
        if (error) {
          alert(error.message || 'Maksu epäonnistui!');
        } else {
          createToast(`${amount} € ladattu tilillesi.`, 'success');
          history.push(routerPaths.getMyAccountPollForLoad(now));
        }
      }
    }
    setHandlingPayment(false);
    dispatch(stopWait());
  }

  function handleAmountChange(amountStr: string) {
    setFormState({
      ...formState,
      amountStr: createAmountValidated(amountStr),
    });
  }

  function handleCardChange(e: any) {
    setFormState({
      ...formState,
      card: {
        ...formState.card,
        errorMsg: e.error ? e.error.message : '',
        complete: e.complete,
      },
    });
  }

  if (!user) {
    return null;
  }

  const balance = user.balance;
  const form = formState;
  const amount = getAmountNumber();
  const totalAmount = amount !== undefined ? amount + transactionFee : undefined;

  const payButtonEnabled = !!paymentIntent && !paymentIntentLoading && !handlingPayment;

  const amountInputClasses = ['form-control'];

  if (form.amountStr.touched && !form.amountStr.validationResult.valid) {
    amountInputClasses.push('is-invalid');
  } else {
    amountInputClasses.push('is-valid');
  }

  const formValid = form.amountStr.validationResult.valid && form.card.complete;

  return (
    <ContentContainer style={{ maxWidth: '600px', marginLeft: 'auto', marginRight: 'auto' }}>
      <ScrollToTopOnMount />
      <h2>Lataa arvoa</h2>
      <hr />
      <form onSubmit={openConfirmModal} noValidate={true} className="v-load-money-form">
        <p>
          <span>{`Tilisi saldo nyt: ${balance.toFixed(2)} €`}</span>{' '}
          {amount !== undefined && (
            <span>{`(Latauksen jälkeen: ${(balance + amount).toFixed(2)} €)`}</span>
          )}
        </p>
        <div className="form-group">
          <label htmlFor="load-amount">Ladattava summa</label>
          <div className="input-group">
            <input
              style={{ maxWidth: '100px' }}
              type="number"
              min="0"
              step="1"
              max="9999"
              className={amountInputClasses.join(' ')}
              id="load-amount"
              aria-describedby="amount-load-help"
              placeholder="0,00"
              tabIndex={1}
              autoFocus={true}
              value={formState.amountStr.value}
              onChange={(e) => handleAmountChange(e.target.value)}
            />
            <div className="input-group-append">
              <span className="input-group-text">€ + latausmaksu {1} €</span>
            </div>
          </div>
          {form.amountStr.touched &&
          !form.amountStr.validationResult.valid &&
          form.amountStr.validationResult.errorMessages &&
          form.amountStr.validationResult.errorMessages.length ? (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              <ul>
                {form.amountStr.validationResult.errorMessages.map((msg) => (
                  <li key={uuid.v4()}>{msg}</li>
                ))}
              </ul>
            </div>
          ) : null}
          <small id="amount-load-help" className="form-text text-muted">
            Ladattava arvo on käytettävissä kaikkiin varauksiin Varaukset.fi:ssä. Ladattua arvoa ei
            voi palauttaa.
          </small>
        </div>
        <div>
          <label style={{ display: 'block' }}>
            Maksukortin tiedot
            <CardElement
              onChange={handleCardChange}
              options={{
                style: {
                  base: {
                    color: '#424770',
                    letterSpacing: '0.025em',
                    '::placeholder': {
                      color: '#aab7c4',
                    },
                  },
                  invalid: {
                    color: '#9e2146',
                  },
                },
              }}
            />
          </label>
          {form.card.errorMsg ? (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              <ul>
                <li>{form.card.errorMsg}</li>
              </ul>
            </div>
          ) : null}
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexWrap: 'wrap',
              marginTop: '15px',
            }}
          >
            <div style={{ flex: '0 1 auto' }}>
              <img src={visaImg} className="v-card-logo" alt="Visa" />
            </div>
            <div style={{ flex: '0 1 auto' }}>
              <img src={visaeImg} className="v-card-logo" alt="Visa Electron" />
            </div>
            <div style={{ flex: '0 1 auto' }}>
              <img src={masterImg} className="v-card-logo" alt="Mastercard" />
            </div>
            <div style={{ flex: '0 1 auto' }}>
              <img src={amexImg} className="v-card-logo" alt="American Express" />
            </div>
            <div style={{ flex: '0 1 auto', marginLeft: 'auto' }}>
              <a href="https://stripe.com" target="_blank" rel="noreferrer">
                <img src={stripeImg} className="v-stripe-logo" alt="Powered by Stripe" />
              </a>
            </div>
          </div>
        </div>
        <hr />
        <div>
          Jatkamalla hyväksyt{' '}
          <a href={routerPaths.getTermsAbsoluteUrl()} target="_blank" rel="noreferrer">
            käyttöehdot
          </a>
          .{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}{' '}
          <a href="#" onClick={openInfoModal}>
            Tietoa maksamisesta ja tietoturvasta
          </a>
          .
        </div>
        <div className="v-load-money-buttons">
          <button className="btn btn-secondary" type="button" onClick={history.goBack} tabIndex={2}>
            Takaisin
          </button>
          <button type="submit" tabIndex={3} className="btn btn-primary" disabled={!formValid}>
            Veloita kortilta {totalAmount !== undefined && `${totalAmount.toFixed(2)} €`}
          </button>
        </div>
      </form>
      {totalAmount !== undefined && (
        <LoadMoneyConfirmModal
          isOpen={confirmModalOpen}
          totalAmount={totalAmount}
          transactionFee={transactionFee}
          onCancel={closeConfirmModal}
          onConfirm={handleOk}
          payEnabled={payButtonEnabled}
        />
      )}
      <PaymentInfoModal isOpen={infoModalOpen} onClose={closeTermsModal} />
    </ContentContainer>
  );
};

export default LoadMoney;
