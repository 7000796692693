import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Organization, User } from '../../models/models';
import { setVisitedOrganizationAsync } from '../../redux/organization';
import { VarauksetState } from '../../redux/store';
import CheckingOrganization from './CheckingOrganization';
import OrganizationNotFound from './OrganizationNotFound';
import * as LocalStorageUtils from '../../localStorageUtils';

interface OrganizationRouteStateProps {
  stateOrganization?: Organization | null;
  user?: User;
}

interface OrganizationRouteDispatchProps {
  onSetVisitedOrganization(org: Organization): void;
}

interface OrganizationRouteComponentProps {
  component?: React.ComponentType<RouteComponentProps<any> | {}>;
  path: string;
  exact?: boolean;
  render?(routeProps: any): JSX.Element;
}

interface OrganizationRouteProps
  extends OrganizationRouteComponentProps,
    OrganizationRouteStateProps,
    OrganizationRouteDispatchProps {}

/**
 * Should work just like <Route /> (only component-prop supported, not e.g. render),
 * except that this component internally checks if organization in url is legit and
 * shows error if not.
 */
class OrganizationRoute extends React.Component<OrganizationRouteProps, {}> {
  componentDidUpdate() {
    if (this.props.stateOrganization) {
      // Don't save the global demo calendar
      if (this.props.stateOrganization.slug !== 'esimerkkitalo') {
        LocalStorageUtils.setVisitedOrganization(this.props.stateOrganization);
      }
      this.props.onSetVisitedOrganization(this.props.stateOrganization);
    }
  }

  render() {
    return (
      <Route
        exact={this.props.exact}
        path={this.props.path}
        render={(routeProps: any) => {
          const stateOrg = this.props.stateOrganization;
          const routeSlug = routeProps.match.params.organizationSlug;

          if (stateOrg === undefined || (stateOrg && stateOrg.slug !== routeSlug)) {
            // Organization not fetched or fetched is not same as used in url
            return <CheckingOrganization routeSlug={routeSlug} />;
          } else if (stateOrg === null) {
            // Organization with used routeSlug does not exist
            return <OrganizationNotFound organizationSlug={routeSlug} />;
          } else {
            // Organization with current routeSlug exists and is fetched to state
            if (this.props.component) {
              return <this.props.component {...routeProps} />;
            } else if (this.props.render) {
              return this.props.render(routeProps);
            } else {
              throw new Error('Either component or render must be defined,');
            }
          }
        }}
      />
    );
  }
}

const mapStateToProps = (state: VarauksetState): OrganizationRouteStateProps => ({
  stateOrganization: state.organization.organization,
  user: state.user.activeUser,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<VarauksetState, undefined, AnyAction>
): OrganizationRouteDispatchProps => ({
  onSetVisitedOrganization: (org: Organization) => dispatch(setVisitedOrganizationAsync(org)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationRoute);
