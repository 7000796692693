import { Action, createAction } from 'redux-actions';
import { MyReservations } from '../models/models';
import { Dispatch } from 'redux';
import { startWait, stopWait } from './wait';
import * as api from '../apiClient';

// ACTIONS

export enum MyReservationsAction {
  RECEIVE_MY_RESERVATIONS = 'varaukset/myReservations/RECEIVE_MY_RESERVATIONS',
}

export const receiveMyReservations = createAction<MyReservations | null>(
  MyReservationsAction.RECEIVE_MY_RESERVATIONS
);

export function fetchMyReservationsAsync() {
  return async (dispatch: Dispatch<any>) => {
    dispatch(startWait());
    const apiDataResponse = await api.getMyReservations();
    dispatch(stopWait());
    if (apiDataResponse.success && apiDataResponse.data) {
      dispatch(receiveMyReservations(apiDataResponse.data));
    } else {
      dispatch(receiveMyReservations(null));
    }
  };
}

// REDUCER

export interface MyReservationsState {
  myReservations?: MyReservations;
}

const myReservationsInitialState: MyReservationsState = {
  myReservations: undefined,
};

export default function myReservationsReducer(
  state: MyReservationsState = myReservationsInitialState,
  action: Action<any>
): MyReservationsState {
  switch (action.type) {
    case MyReservationsAction.RECEIVE_MY_RESERVATIONS:
      return {
        ...state,
        myReservations: action.payload,
      };

    default:
      return state;
  }
}
