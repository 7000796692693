import * as React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as joda from 'js-joda';
import Day from './Day';
import HourMarkers from './HourMarkers';
import { filter } from 'lodash';
import * as uuid from 'uuid';
import Controls from './Controls';
import { Reservation, CalendarMode, Rules, TimeRange, DateRange } from '../../models/models';
import './Calendar.scss';
import {
  getVisibleTimes,
  getMinTime,
  getMaxTime,
  reservationActiveOnDate,
  timeFloor,
  timeCeil,
} from '../../utils';
import { withRouter, RouteComponentProps } from 'react-router';
import { VarauksetState } from '../../redux/store';

export interface CalendarDay {
  date: joda.LocalDate;
  timeRanges: TimeRange[];
}

interface CalendarOwnProps {
  now: joda.LocalDateTime;
  style?: React.CSSProperties;
}

interface CalendarConnectedProps extends CalendarOwnProps {
  resourceId: string;
}

export interface CalendarProps extends CalendarOwnProps {
  calendarDays: CalendarDay[];
  mode: CalendarMode;
  reservations: Reservation[];
}

export const Calendar = (props: CalendarProps) => {
  const dayHeaderRemHeight = 3.25;
  const hourRemHeight = 3;

  const now = props.now || moment();

  let minTime = joda.LocalTime.parse('10:00');
  let maxTime = joda.LocalTime.parse('15:00');

  for (let calendarDay of props.calendarDays) {
    for (let timeRange of calendarDay.timeRanges) {
      minTime = getMinTime(minTime, timeFloor(timeRange.start));
      let endCeil = timeCeil(timeRange.end);
      if (endCeil.isBefore(timeRange.end)) {
        // Special handling for when ceil for 23.xx is 00.00
        endCeil = joda.LocalTime.parse('23:59');
      }
      maxTime = getMaxTime(maxTime, endCeil);
    }
  }

  for (let { timeRange } of props.reservations) {
    minTime = getMinTime(minTime, timeFloor(timeRange.start));
    let endCeil = timeCeil(timeRange.end);
    if (endCeil.isBefore(timeRange.end)) {
      // Special handling for when ceil for 23.xx is 00.00
      endCeil = joda.LocalTime.parse('23:59');
    }
    maxTime = getMaxTime(maxTime, endCeil);
  }

  return (
    <div style={props.style}>
      <Controls />
      <div style={{ display: 'flex', paddingTop: '20px' }} className="v-calendar">
        <div style={{ flexGrow: 1, flexBasis: 0 }}>
          <HourMarkers
            start={minTime}
            end={maxTime}
            headerRemHeight={dayHeaderRemHeight}
            hourRemHeight={hourRemHeight}
          />
        </div>
        {props.calendarDays.map((d) => (
          <div key={uuid.v4()} style={{ flexGrow: 2, flexBasis: 0 }}>
            <Day
              reservations={filter(props.reservations, (r) => reservationActiveOnDate(r, d.date))}
              date={d.date}
              visibleTimeRange={{
                start: minTime,
                end: maxTime,
              }}
              availableTimeRanges={d.timeRanges}
              now={now}
              headerRemHeight={dayHeaderRemHeight}
              hourRemHeight={hourRemHeight}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export function rulesAndRangeToCalendarDates(
  visibleDateRange: DateRange,
  rules?: Rules,
  defaultTimeRange?: TimeRange
): CalendarDay[] {
  const calendarDays: CalendarDay[] = [];
  for (
    let i = 0;
    i <= visibleDateRange.start.until(visibleDateRange.end, joda.ChronoUnit.DAYS);
    ++i
  ) {
    const date = visibleDateRange.start.plusDays(i);
    const timeRanges = getVisibleTimes(date, rules, defaultTimeRange);
    calendarDays.push({ date, timeRanges });
  }
  return calendarDays;
}

function getReservations(state: VarauksetState): Reservation[] {
  const reservations = state.resource.reservations.slice();
  if (state.resource.preReservation) {
    reservations.push(state.resource.preReservation);
  }
  return reservations;
}

const mapStateToProps = (state: VarauksetState, ownProps: CalendarConnectedProps) => {
  let rules;
  if (state.resource.resources) {
    const resource = state.resource.resources.find((r) => r.id === ownProps.resourceId);
    if (resource) {
      rules = resource.rules;
    }
  }

  return {
    calendarDays: rulesAndRangeToCalendarDates(state.calendar.visibleDateRange, rules),
    mode: state.calendar.mode,
    reservations: getReservations(state),
  };
};

const CalendarConnected = connect(mapStateToProps)(Calendar as any);

export default withRouter((props: CalendarConnectedProps & RouteComponentProps<any>) => (
  <CalendarConnected {...props} resourceId={props.match.params.resourceId} />
)) as any as React.FC<CalendarOwnProps>;
