import * as React from 'react';
import * as paths from './../routerPaths';
import './VarauksetFooter.scss';
const logoImg = require('./../images/fluxio-powered.png');

const VarauksetFooter: React.FC = () => {
  return (
    <footer className="v-footer">
      <div className="container">
        <div className="v-footer-container">
          <div id="v-links">
            <a href={paths.getPrivacyPolicyAbsoluteUrl()} target="_blank" rel="noreferrer">
              Tietosuojaseloste
            </a>{' '}
            <a href={paths.getTermsAbsoluteUrl()} target="_blank" rel="noreferrer">
              Käyttöehdot
            </a>
            <div id="v-version-tag">
              Versio:{' '}
              {`${process.env.REACT_APP_ENV}-${process.env.REACT_APP_BUILD_TIMESTAMP || 'dyn'}-${
                process.env.REACT_APP_BUILD_GIT_SHA || 'dev'
              }`}
            </div>
          </div>
          <div id="v-fluxio-powered">
            <a href="http://www.fluxioisannointi.fi/" target="_blank" rel="noreferrer">
              <img src={logoImg} alt="Fluxio Isännöinti" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default VarauksetFooter;
