import * as joda from 'js-joda';
import moment from 'moment';
import * as React from 'react';
import { CreateReservation, RepeatMode, Reservation, Resource } from '../../models/models';
import { jodaNow } from '../../utils';

interface ReservationSummaryProps {
  resource: Resource;
  reservation: Reservation | CreateReservation;
  now?: joda.LocalDateTime;
}

const ReservationSummary: React.FC<ReservationSummaryProps> = (props) => {
  const now = props.now || jodaNow();
  const reservation = props.reservation;
  const startDateTime = reservation.date.atTime(reservation.timeRange.start);
  const endDateTime = reservation.date.atTime(reservation.timeRange.end);
  const dateStr = moment.utc(reservation.date.toString()).format('dddd l');
  const startDuration = moment.duration(joda.Duration.between(now, startDateTime).toMillis());
  const timeUntilStr = now.isBefore(startDateTime)
    ? startDuration.humanize() + ' päästä'
    : startDuration.humanize(true);
  const startTimeStr = moment.utc(startDateTime.toString()).format('LT');
  const endTimeStr = moment.utc(endDateTime.toString()).format('LT');
  const timeRangeStr = `klo ${startTimeStr} - ${endTimeStr}`;

  if (!reservation.repeating) {
    return (
      <div>
        <strong>{props.resource.name}</strong>{' '}
        <span>{`${timeUntilStr}, ${dateStr} ${timeRangeStr}`}</span>
      </div>
    );
  } else {
    const repeatModeText =
      reservation.repeatMode === RepeatMode.Biweekly ? 'joka toinen viikko' : 'viikoittain';
    if (!reservation.repeatLastDate) {
      return (
        <div>
          <strong>{props.resource.name}</strong> <span>{repeatModeText}, toistaiseksi, </span>
          <span>{`alkaen ${timeUntilStr}, ${dateStr} -> ${timeRangeStr}`}</span>
        </div>
      );
    } else {
      const endDateStr = moment.utc(reservation.repeatLastDate.toString()).format('l');
      return (
        <div>
          <strong>{props.resource.name}</strong> <span>{repeatModeText}, </span>
          <span>{`alkaen ${timeUntilStr}, ${dateStr} - ${endDateStr}, ${timeRangeStr}`}</span>
        </div>
      );
    }
  }
};

export default ReservationSummary;
