import { faUserShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as joda from 'js-joda';
import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CreateReservation, ReservationMode, Resource } from '../../models/models';
import { ReservationCreationMode } from './create/AdminSelector';
import ReservationSummary from './ReservationSummary';

interface ReservationConfirmModalProps {
  isOpen: boolean;
  resource: Resource;
  reservation: CreateReservation;
  totalPrice: number;
  reservationMode: ReservationCreationMode;
  now?: joda.LocalDateTime;
  onCancel(): void;
  onConfirm(): void;
}

const ReservationConfirmModal: React.FC<ReservationConfirmModalProps> = (props) => {
  const adminReservation = props.reservationMode === ReservationCreationMode.Admin;

  function renderAdminPaymentNotification() {
    if (adminReservation && props.totalPrice > 0) {
      return (
        <div className="alert alert-warning mt-3" role="alert">
          Olet tekemässä varausta ylläpitäjän roolissa, jolloin varauksesta ei peritä maksua.
          Normaalisti tämän resurssin varaaminen on maksullista. Jos olet tekemässä henkilökohtaista
          varausta, valitse normaali varaustila.
        </div>
      );
    }
    return null;
  }

  function renderEmailConfirmationNeededNotification() {
    if (
      props.reservation.email &&
      props.resource.reservationMode === ReservationMode.EmailConfirmation
    ) {
      return (
        <div className="alert alert-warning mt-3" role="alert">
          Varaus täytyy vahvistaa sähköpostiosoitteeseesi <strong>{props.reservation.email}</strong>{' '}
          lähetettävän linkin avulla. Vahvistamaton varaus poistuu järjestelmästä 15 min kuluttua.
          Linkki lähetetään painettuasi "Varaa".
        </div>
      );
    }
  }

  function renderTotalPrice() {
    const priceStr = `${props.totalPrice.toFixed(2)} €`;
    if (props.totalPrice > 0 && !adminReservation) {
      return (
        <span className="badge badge-light" style={{ fontSize: '1rem' }}>
          {priceStr}
        </span>
      );
    }
    return null;
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.onCancel} backdrop="static">
      <ModalHeader toggle={props.onCancel}>{'Vahvista varaus'}</ModalHeader>
      <ModalBody>
        <ReservationSummary resource={props.resource} reservation={props.reservation} />
        {renderAdminPaymentNotification()}
        {renderEmailConfirmationNeededNotification()}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={props.onCancel} tabIndex={1}>
          Peruuta
        </Button>
        <Button color="primary" autoFocus={true} onClick={props.onConfirm} tabIndex={2}>
          {adminReservation && (
            <>
              <FontAwesomeIcon icon={faUserShield} />{' '}
            </>
          )}
          Varaa {renderTotalPrice()}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ReservationConfirmModal;
