import { faChevronLeft, faChevronRight, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as joda from 'js-joda';
import moment from 'moment';
import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { ButtonDropdown, DropdownToggle } from 'reactstrap';
import { CalendarMode, DateRange } from '../../models/models';
import { setCalendarVisibleDateRange } from '../../redux/calendar';
import {
  fetchReservationsAsync,
  receiveReservations,
  setPreReservation,
} from '../../redux/resource';
import { VarauksetState } from '../../redux/store';
import * as paths from '../../routerPaths';
import { isDateBetween, isUserAdmin, jodaNow, weekRangeForDate } from '../../utils';
import './Controls.scss';

function toWeekString(date: joda.LocalDate) {
  return `vko ${date.isoWeekOfWeekyear()}, ${moment(date.toString()).format('MMMM YYYY')}`;
}

interface ControlsRouterProps {
  organizationSlug: string;
  resourceId: string;
}

interface ControlsComponentProps {
  now?: joda.LocalDateTime;
  style?: React.CSSProperties;
}

interface ControlsProps extends ControlsRouterProps, ControlsComponentProps {}

export const Controls: React.FC<ControlsProps> = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const mode = useSelector((state: VarauksetState) => state.calendar.mode);
  const visibleDateRange = useSelector((state: VarauksetState) => state.calendar.visibleDateRange);
  const user = useSelector((state: VarauksetState) => state.user.activeUser);

  const resourceId = props.resourceId;
  const organizationSlug = props.organizationSlug;

  function toggleDropdownOpen() {
    setDropdownOpen(!dropdownOpen);
  }

  function changeVisibleDateRange(range: DateRange) {
    dispatch(receiveReservations([]));
    dispatch(setCalendarVisibleDateRange(range));
    dispatch(fetchReservationsAsync(range, resourceId));
    dispatch(setPreReservation(null));
  }

  function handleTodayClick() {
    if (mode === CalendarMode.Week) {
      changeVisibleDateRange(weekRangeForDate(jodaNow().toLocalDate()));
    } else {
      throw new Error('Not implemented');
    }
  }

  function handleNextClick() {
    handleRangeChange(1);
  }

  function handlePrevClick() {
    handleRangeChange(-1);
  }

  function handleRangeChange(step: number) {
    let newRange;
    if (mode === CalendarMode.Week) {
      newRange = weekRangeForDate(visibleDateRange.start.plusWeeks(step));
    } else {
      throw new Error('Not implemented');
    }
    changeVisibleDateRange(newRange);
  }

  function handleEditResourceClick(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    history.push(paths.getResourceEditUrl(organizationSlug, resourceId));
  }

  const nowDate = (props.now || jodaNow()).toLocalDate();
  const todayInRange = isDateBetween(nowDate, visibleDateRange);

  return (
    <div style={props.style} className="v-calendar-controls">
      <div className="v-control-btn-container">
        <div role="group" className="btn-group v-control-browse-btns-grp">
          <button
            type="button"
            className="btn btn-outline-primary"
            disabled={todayInRange}
            onClick={handleTodayClick}
          >
            Tänään
          </button>
        </div>

        <div role="group" className="btn-group v-control-drop-grp">
          <button type="button" className="btn btn-outline-primary" onClick={handlePrevClick}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <ButtonDropdown
            className="v-control-drop"
            size="lg"
            isOpen={dropdownOpen}
            toggle={toggleDropdownOpen}
          >
            <DropdownToggle color="primary" caret={false} className="btn-block">
              {toWeekString(visibleDateRange.start)}
            </DropdownToggle>
          </ButtonDropdown>
          <button type="button" className="btn btn-outline-primary" onClick={handleNextClick}>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>

        <div className="btn-group btn-group-sm v-control-edit-grp">
          {isUserAdmin(user, organizationSlug) && (
            <a
              href={paths.getResourceEditUrl(organizationSlug, resourceId)}
              className="btn btn-outline-secondary"
              onClick={handleEditResourceClick}
            >
              <FontAwesomeIcon icon={faUserShield} /> Muokkaa kalenteria
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter((props: ControlsComponentProps & RouteComponentProps<any>) => (
  <Controls
    resourceId={props.match.params.resourceId}
    organizationSlug={props.match.params.organizationSlug}
    now={props.now}
    style={props.style}
  />
));
