import { faPlus, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { VarauksetState } from '../../redux/store';
import * as paths from '../../routerPaths';

interface MyUserProps extends RouteComponentProps {}

const MyUser: React.FC<MyUserProps> = (props) => {
  const user = useSelector((state: VarauksetState) => state.user.activeUser);

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">Omat tiedot</h4>
        <div>{user?.email}</div>
        {user?.level === 1 && (
          <div>
            Superuser <FontAwesomeIcon icon={faUserShield} />{' '}
            <FontAwesomeIcon style={{ marginLeft: '-6px' }} icon={faPlus} /> (Ylläpito-oikeudet
            kaikkiin organisaatioihin)
          </div>
        )}
        {(user?.managedOrganizations.length || 0) > 0 && (
          <div>
            <h5 style={{ marginTop: '10px', fontSize: '1.1rem' }}>
              Olet <FontAwesomeIcon icon={faUserShield} /> ylläpitäjä:
            </h5>
            <ul>
              {user?.managedOrganizations.map((o) => (
                <li key={o.id}>
                  <Link to={paths.getOrganizationMainUrl(o.slug)}>{o.name}</Link>
                </li>
              ))}
            </ul>
            <a href={paths.getAdminInstructionsAbsoluteUrl()} target="_blank" rel="noreferrer">
              Ylläpitäjän ohjeet ja usein kysytyt kysymykset
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(MyUser);
