import * as React from 'react';
import * as joda from 'js-joda';
import moment from 'moment';
import './DayHeader.scss';

interface DayHeaderProps {
  remHeight: number;
  date: joda.LocalDate;
}

export const DayHeader: React.FC<DayHeaderProps> = (props) => {
  const now = joda.LocalDate.now();
  const past = props.date.isBefore(now);
  const today = props.date.equals(now);
  return (
    <div
      className="v-day-header"
      style={{
        height: `${props.remHeight}rem`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        color: past ? 'gray' : '',
        fontWeight: today ? 'bold' : 'normal',
      }}
    >
      <span style={{ textAlign: 'center' }}>
        <span className="v-day-number" style={{ fontSize: '1.4rem' }}>
          {moment.utc(props.date.toString()).format('D.')}
        </span>
        {/* tslint:disable-next-line:jsx-self-close */}
        <span className="hidden-sm-down"> </span>
        <br className="hidden-md-up" />
        <span>{moment.utc(props.date.toString()).format('dd')}</span>
      </span>
    </div>
  );
};

export default DayHeader;
