import * as joda from 'js-joda';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as uuid from 'uuid';
import { DateRange, OpenDateRange, Organization, ReportType, User } from '../../models/models';
import { downloadReportAsync, getManagedOrganizationsAsync } from '../../redux/paymentReport';
import { VarauksetState } from '../../redux/store';
import { jodaNow } from '../../utils';
import {
  squashValidationResults,
  validate,
  ValidationField,
  ValidationResult,
  validDateRange,
} from '../../validators';
import DateInput from '../DateInput';
import './ReportExport.scss';

const reportTypes: { key: ReportType; presentation: string }[] = [
  { key: ReportType.Payments, presentation: 'Maksuraportti' },
  { key: ReportType.Reservations, presentation: 'Varausraportti' },
];

function validateDateRange(dateRange: OpenDateRange): ValidationResult {
  return validate(dateRange, [validDateRange]);
}

interface ReportExportStateProps {
  managedOrganizations: Organization[];
  user?: User;
}

interface ReportExportDispatchProps {
  onGetManagedOrganizations: () => void;
  onDownloadReport: (dateRange: DateRange, organizationId: string, reportType: ReportType) => void;
}

interface ReportExportFormState {
  reportType: ReportType;
  organizationId: string;
  dateRange: ValidationField<OpenDateRange>;
}

interface ReportExportState {
  form: ReportExportFormState;
  userHasTouchedForm: boolean;
}

interface ReportExportProps
  extends ReportExportStateProps,
    ReportExportDispatchProps,
    RouteComponentProps {}

export class ReportExport extends React.Component<ReportExportProps, ReportExportState> {
  constructor(props: ReportExportProps) {
    super(props);

    this.state = {
      form: {
        reportType: ReportType.Reservations,
        organizationId: '',
        dateRange: {
          value: { start: undefined, end: undefined },
          validationResult: { valid: false },
        },
      },
      userHasTouchedForm: false,
    };

    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleReportTypeChange = this.handleReportTypeChange.bind(this);
    this.handleOrganizationChange = this.handleOrganizationChange.bind(this);
    this.handlePreviousNDaysPress = this.handlePreviousNDaysPress.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.onGetManagedOrganizations();
  }

  componentDidUpdate(prevProps: ReportExportProps) {
    if (!prevProps.user && this.props.user) {
      this.props.onGetManagedOrganizations();
    }
  }

  handleStartDateChange(
    date: joda.LocalDate | undefined,
    dateStrValidationResult: ValidationResult
  ) {
    this.setState((prevState: ReportExportState) => {
      const dateRangeValidated = this.createDateRangeValidated({
        start: date,
        end: prevState.form.dateRange.value.end,
      });
      dateRangeValidated.validationResult = squashValidationResults(
        dateStrValidationResult,
        dateRangeValidated.validationResult
      );
      return {
        form: {
          ...prevState.form,
          dateRange: { ...dateRangeValidated, touched: true },
        },
      };
    });
    this.setState({ userHasTouchedForm: true });
  }

  handleEndDateChange(date: joda.LocalDate | undefined, dateStrValidationResult: ValidationResult) {
    this.setState((prevState: ReportExportState) => {
      const dateRangeValidated = this.createDateRangeValidated({
        start: prevState.form.dateRange.value.start,
        end: date,
      });
      dateRangeValidated.validationResult = squashValidationResults(
        dateStrValidationResult,
        dateRangeValidated.validationResult
      );
      return {
        form: {
          ...prevState.form,
          dateRange: { ...dateRangeValidated, touched: true },
        },
      };
    });
    this.setState({ userHasTouchedForm: true });
  }

  createDateRangeValidated(dateRange: OpenDateRange): ValidationField<OpenDateRange> {
    return {
      value: dateRange,
      validationResult: validateDateRange(dateRange),
    };
  }

  handleReportTypeChange(event: React.FormEvent<HTMLSelectElement>) {
    const selectedType: ReportType = parseInt(event.currentTarget.value, 10);
    this.setState((prevState) => {
      return {
        form: {
          ...prevState.form,
          reportType: selectedType,
        },
      };
    });
    this.setState({ userHasTouchedForm: true });
  }

  handleOrganizationChange(event: React.FormEvent<HTMLSelectElement>) {
    const selectedOrgId = event.currentTarget.value;
    this.setState((prevState) => {
      return {
        form: {
          ...prevState.form,
          organizationId: selectedOrgId,
        },
      };
    });
    this.setState({ userHasTouchedForm: true });
  }

  handlePreviousNDaysPress(n: number) {
    const now = jodaNow().toLocalDate();
    this.handleStartDateChange(now.minusDays(n + 1), { valid: true });
    this.handleEndDateChange(now.minusDays(1), { valid: true });
  }

  handleSubmit(event: React.SyntheticEvent<any>) {
    event.preventDefault();
    const { dateRange, organizationId, reportType } = this.state.form;
    if (dateRange.value.start && dateRange.value.end) {
      this.props.onDownloadReport(dateRange.value as DateRange, organizationId, reportType);
    }
  }

  render() {
    const { form } = this.state;
    const datesValid = form.dateRange.validationResult.valid;
    const formValid = !!form.organizationId && datesValid;
    const datesErrorMessages = form.dateRange.validationResult.errorMessages || [];

    return (
      <div>
        <h2>Muodosta raportti</h2>
        <hr />

        <form className="v-payments-report-form" onSubmit={this.handleSubmit} noValidate={true}>
          <div>
            <div className="form-group">
              <label htmlFor="reportTypeSelect">Raportin tyyppi</label>
              <select
                id="reportTypeSelect"
                className="form-control"
                value={this.state.form.reportType}
                onChange={this.handleReportTypeChange}
              >
                {reportTypes.map((rt) => (
                  <option key={rt.key} value={rt.key}>
                    {rt.presentation}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="organizationSelect">Organisaatio</label>
              <select
                id="organizationSelect"
                className="form-control"
                value={this.state.form.organizationId}
                onChange={this.handleOrganizationChange}
              >
                {!this.state.form.organizationId && (
                  <option key={''} value={''}>
                    -
                  </option>
                )}
                {this.props.managedOrganizations.map((mo) => (
                  <option key={mo.id} value={mo.id}>
                    {mo.name}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-link"
                onClick={() => this.handlePreviousNDaysPress(30)}
              >
                Edellinen 30 vuorokautta
              </button>
            </div>
            <div className="v-date-inputs">
              <DateInput
                id="startDate"
                value={form.dateRange.value.start}
                valid={form.dateRange.touched ? datesValid : undefined}
                onChange={this.handleStartDateChange}
              />

              <div>{'-'}</div>

              <DateInput
                id="endDate"
                value={form.dateRange.value.end}
                valid={form.dateRange.touched ? form.dateRange.validationResult.valid : undefined}
                onChange={this.handleEndDateChange}
              />
            </div>
            {!datesValid && (
              <div className="invalid-feedback" style={{ display: 'block' }}>
                <ul>
                  {datesErrorMessages.map((msg) => (
                    <li key={uuid.v4()}>{msg}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>

          <hr />
          <div className="v-reservation-buttons">
            <button className="btn btn-secondary" type="button" onClick={this.props.history.goBack}>
              Takaisin
            </button>
            <button className="btn btn-primary" type="submit" disabled={!formValid}>
              Muodosta raportti
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state: VarauksetState): ReportExportStateProps => ({
  managedOrganizations: state.paymentReport.organizations,
  user: state.user.activeUser,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<VarauksetState, undefined, AnyAction>
): ReportExportDispatchProps => ({
  onGetManagedOrganizations: () => dispatch(getManagedOrganizationsAsync()),
  onDownloadReport: async (dateRange: DateRange, organizationId: string, reportType: ReportType) =>
    dispatch(downloadReportAsync(dateRange, organizationId, reportType)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportExport));
