import { createAction } from 'redux-actions';

// Some user actions in a separate file to avoid circular dependency.

export enum UserAction {
  RECEIVE_USER = 'varaukset/user/RECEIVE_USER',
  SET_USER_BOOKMARKED_ORGANIZATIONS = 'varaukset/user/SET_BOOKMARKED_ORGANIZATIONS',
  CLEAR_LOCAL_USER = 'varaukset/user/CLEAR_LOCAL_USER',
  START_LOGIN = 'varaukset/user/START_USER_FETCH',
  LOGOUT = 'varaukset/user/LOGOUT',
}

export const clearLocalUser = createAction(UserAction.CLEAR_LOCAL_USER);
