import * as joda from 'js-joda';
import * as React from 'react';
import { TimeRange } from '../../models/models';
import './DisabledTime.scss';
const grayImg = require('../../images/gray.png');

interface HourProps {
  dayStartTime: joda.LocalTime;
  hourRemHeight: number;
  timeRange: TimeRange;
  onClick: () => void;
}

export const DisabledTime: React.FC<HourProps> = (props) => {
  const hoursFromTop =
    joda.Duration.between(props.dayStartTime, props.timeRange.start).toMinutes() /
    joda.LocalTime.MINUTES_PER_HOUR;
  const hoursLength =
    joda.Duration.between(props.timeRange.start, props.timeRange.end).toMinutes() /
    joda.LocalTime.MINUTES_PER_HOUR;

  return (
    <div
      style={{
        position: 'absolute',
        height: `${hoursLength * props.hourRemHeight}rem`,
        top: `${hoursFromTop * props.hourRemHeight}rem`,
        left: 0,
        width: '100%',
      }}
      onClick={props.onClick}
      className="v-range-disabled"
    >
      {/* eslint-disable-next-line jsx-a11y/alt-text */}
      <img width="100%" height="100%" src={grayImg} />
    </div>
  );
};

export default DisabledTime;
