import * as React from 'react';
import './ContentContainer.scss';

interface ContentContainerProps {
  children: any;
  style?: React.CSSProperties;
  className?: string;
}

export const ContentContainer: React.FC<ContentContainerProps> = (props) => {
  const classNames = ['v-content-container'];
  if (props.className) {
    classNames.push(props.className);
  }
  return (
    <div style={props.style} className={classNames.join(' ')}>
      {props.children}
    </div>
  );
};
