import { faUserShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { connect } from 'react-redux';
import { match, matchPath, RouteComponentProps, withRouter } from 'react-router';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavbarToggler,
  UncontrolledDropdown,
} from 'reactstrap';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { LoadState, Organization, User } from '../models/models';
import { VarauksetState } from '../redux/store';
import { fetchCurrentUserAsync, logoutAsync } from '../redux/user';
import * as paths from '../routerPaths';
import { devEnv, isUserAdmin, stagingEnv } from '../utils';
import './VarauksetNavbar.scss';

const logoImg = require('./../images/varaukset-logo-medium.png');
const logoVImg = require('./../images/varaukset-logo-rect.png');

interface VarauksetNavbarComponentProps {
  style?: React.CSSProperties;
}

interface VarauksetNavbarStateProps {
  organization?: Organization | null;
  loginLoadState: LoadState;
  loggedIn: boolean;
  user?: User;
  visitedOrganization?: Organization;
}

interface VarauksetNavarDispatchProps {
  onFetchUser(): void;
  onLogout(): void;
}

interface VarauksetNavbarRouterProps extends RouteComponentProps {
  routeOrgSlug?: string;
}

interface VarauksetNavbarProps
  extends VarauksetNavbarComponentProps,
    VarauksetNavbarStateProps,
    VarauksetNavarDispatchProps,
    VarauksetNavbarRouterProps {}

interface VarauksetNavbarState {
  isOpen: boolean;
}

export class VarauksetNavbar extends React.Component<VarauksetNavbarProps, VarauksetNavbarState> {
  constructor(props: VarauksetNavbarProps) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggle = this.toggle.bind(this);
    this.handleMainPageClick = this.handleMainPageClick.bind(this);
    this.handleOrganizationClick = this.handleOrganizationClick.bind(this);
    this.handleLoginRegisterClick = this.handleLoginRegisterClick.bind(this);
    this.handleMyAccountNavigate = this.handleMyAccountNavigate.bind(this);
    this.handleAdminNavigate = this.handleAdminNavigate.bind(this);
  }

  componentDidMount() {
    this.fetchUserIfNeeded(this.props);
  }

  componentWillReceiveProps(newProps: VarauksetNavbarProps) {
    this.fetchUserIfNeeded(newProps);
  }

  fetchUserIfNeeded(props: VarauksetNavbarProps) {
    if (props.loggedIn && !props.user) {
      props.onFetchUser();
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  handleMainPageClick(event: React.SyntheticEvent<any>) {
    event.preventDefault();
    this.props.history.push('/');
  }

  handleOrganizationClick(event: React.SyntheticEvent<any>, org: Organization | undefined | null) {
    event.preventDefault();
    this.props.history.push(paths.getOrganizationMainUrl(org ? org.slug : ''));
  }

  handleLoginRegisterClick(event: React.SyntheticEvent<any>) {
    event.preventDefault();
    this.props.history.push(paths.loginUrl);
  }

  handleMyAccountNavigate(event: React.SyntheticEvent<any>) {
    event.preventDefault();
    this.props.history.push(paths.myAccountUrl);
  }

  handleAdminNavigate() {
    this.props.history.push(paths.adminUrl);
  }

  render() {
    let envStr = '';
    if (devEnv()) {
      envStr = 'DEVELOPMENT';
    } else if (stagingEnv()) {
      envStr = 'STAGING';
    }

    const { user, visitedOrganization } = this.props;

    let logoHref = '/';
    let logoClickHandler = this.handleMainPageClick;
    if (!user && visitedOrganization) {
      logoHref = paths.getOrganizationMainUrl(visitedOrganization.slug);
      logoClickHandler = (event: React.SyntheticEvent<any>) =>
        this.handleOrganizationClick(event, visitedOrganization);
    } else if (user && user.bookmarkedOrganizations.length === 1) {
      const org = user.bookmarkedOrganizations[0];
      logoHref = paths.getOrganizationMainUrl(org.slug);
      logoClickHandler = (event: React.SyntheticEvent<any>) =>
        this.handleOrganizationClick(event, org);
    } else if (user && user.bookmarkedOrganizations.length > 1) {
      logoHref = paths.myAccountUrl;
      logoClickHandler = (event: React.SyntheticEvent<any>) => this.handleMyAccountNavigate(event);
    }

    return (
      <nav className="navbar navbar-expand-sm navbar-light" style={{ backgroundColor: '#ffffff' }}>
        <div className="container" style={{ alignItems: 'center' }}>
          <div
            className="navbar-brand"
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flex: '1 1 auto',
            }}
          >
            <a
              className="navbar-brand"
              href={logoHref}
              onClick={logoClickHandler}
              style={{ flex: '0 1 auto' }}
            >
              <div style={{ position: 'relative' }}>
                <img src={logoImg} className="d-none d-lg-block v-brand-img" alt="Varaukset.fi" />
                <img src={logoVImg} className="d-lg-none v-brand-img" alt="Varaukset.fi" />
                {envStr ? (
                  <span
                    style={{
                      color: 'white',
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      fontSize: '0.8rem',
                      fontWeight: 'bold',
                      background: 'rgba(255,0,0,0.4)',
                    }}
                  >
                    {envStr}
                  </span>
                ) : null}
              </div>
            </a>{' '}
            {this.props.routeOrgSlug &&
            this.props.organization &&
            this.props.routeOrgSlug === this.props.organization.slug ? (
              <a
                className="navbar-brand v-org-name"
                style={{
                  flex: '1 1 auto',
                  display: this.props.organization ? '' : 'none',
                }}
                href={`/${this.props.organization ? this.props.organization.slug : '#'}`}
                onClick={(event) => this.handleOrganizationClick(event, this.props.organization)}
              >
                {this.props.organization.name}
              </a>
            ) : null}
          </div>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar={true}>
            <Nav navbar={true} className="ml-auto">
              {this.props.user ? (
                <UncontrolledDropdown>
                  <DropdownToggle
                    nav={true}
                    caret={true}
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <span className="v-dropdown-email">
                      {this.props.user.firstName || this.props.user.email}
                    </span>
                    {this.props.user.balance > 0 && (
                      <span className="ml-1"> {`(${this.props.user.balance.toFixed(2)} €)`}</span>
                    )}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={this.handleMyAccountNavigate}>Omat tiedot</DropdownItem>
                    {isUserAdmin(this.props.user) && (
                      <DropdownItem onClick={this.handleAdminNavigate}>
                        <FontAwesomeIcon icon={faUserShield} /> Ylläpitäjän toiminnot
                      </DropdownItem>
                    )}
                    <DropdownItem divider={true} />
                    <DropdownItem onClick={this.props.onLogout}>Kirjaudu ulos</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : (
                <a
                  href={paths.loginUrl}
                  onClick={this.handleLoginRegisterClick}
                  className="btn btn-outline-primary my-2 my-sm-0"
                >
                  Kirjaudu
                </a>
              )}
            </Nav>
          </Collapse>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state: VarauksetState): VarauksetNavbarStateProps => ({
  organization: state.organization.organization,
  loginLoadState: state.user.loginLoadState,
  loggedIn: !!state.token.token,
  user: state.user.activeUser,
  visitedOrganization: state.organization.lastVisitedOrganization,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<VarauksetState, undefined, AnyAction>
): VarauksetNavarDispatchProps => ({
  onFetchUser: () => dispatch(fetchCurrentUserAsync()),
  onLogout: () => dispatch(logoutAsync()),
});

const VarauksetNavbarConnected = connect(mapStateToProps, mapDispatchToProps)(VarauksetNavbar);

export default withRouter((props: VarauksetNavbarComponentProps & RouteComponentProps<any>) => {
  // For some reason, props.match.params is always an empty object.
  // Lets find parameters manually.
  const m = matchPath(props.location.pathname, {
    path: paths.organizationMainTemplate,
  }) as match<any> | null;
  return <VarauksetNavbarConnected {...props} routeOrgSlug={m && m.params.organizationSlug} />;
});
