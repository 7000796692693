import { useFormik } from 'formik';
import * as joda from 'js-joda';
import { noop } from 'lodash';
import * as React from 'react';
import { useEffect } from 'react';
import { classNames } from '../utils';

interface DurationInputProps {
  id?: string;
  classNames?: string;
  value: joda.Duration | undefined;
  onChange: (d: joda.Duration | undefined) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  showInvalid?: boolean;
  showDays?: boolean;
}

function robustFloor(value: number | '' | undefined): number {
  if (value === undefined || value === '') {
    return 0;
  }
  return Math.floor(value);
}

function stringIsSimpleWholeNumber(value: string): boolean {
  // Check if value contains decimal point, e, or E
  return !/[eE.]/.test(value);
}

const DurationInput: React.FC<DurationInputProps> = (props) => {
  function daysHoursMinutesToDuration(
    days: number | '' | undefined,
    hours: number | '' | undefined,
    minutes: number | '' | undefined
  ): joda.Duration | undefined {
    if (props.showDays) {
      if (days === undefined || hours === undefined || minutes === undefined) {
        return undefined;
      }
    } else {
      if (hours === undefined || minutes === undefined) {
        return undefined;
      }
    }

    const d = joda.Duration.ofDays(robustFloor(days))
      .plusHours(robustFloor(hours))
      .plusMinutes(robustFloor(minutes));
    return d;
  }

  let days: number | undefined;
  let hours: number | undefined;
  let minutes: number | undefined;
  if (props.value) {
    days = props.value.toDays();
    hours = props.value.minusDays(days).toHours();
    minutes = props.value.minusDays(days).minusHours(hours).toMinutes();

    if (!props.showDays) {
      hours = hours + days * 24;
      days = undefined;
    }
  }

  const formik = useFormik({
    initialValues: {
      days: days,
      hours: hours,
      minutes: minutes,
    },
    onSubmit: noop,
  });

  useEffect(() => {
    if (props.onChange) {
      const d = daysHoursMinutesToDuration(
        formik.values.days,
        formik.values.hours,
        formik.values.minutes
      );
      if (
        (props.value === undefined && d !== undefined) ||
        (props.value !== undefined && d === undefined) ||
        (props.value && d && props.value.toString() !== d.toString())
      ) {
        props.onChange(d);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, props]);

  return (
    <div className={classNames('form-row', props.classNames)}>
      {props.showDays && (
        <div className="col">
          <div className="input-group">
            <input
              className={classNames('form-control', {
                'is-invalid': !!props.showInvalid,
              })}
              type="number"
              min={0}
              step={1}
              id={props.id + '-days'}
              name="days"
              value={formik.values.days}
              onChange={(e) => {
                if (stringIsSimpleWholeNumber(e.target.value)) {
                  formik.handleChange(e);
                }
              }}
              onBlur={(e) => {
                formik.handleBlur(e);
                props.onBlur && props.onBlur(e);
              }}
            />
            <div className="input-group-append">
              <div className="input-group-text">vrk</div>
            </div>
          </div>
        </div>
      )}

      <div className="col">
        <div className="input-group">
          <input
            className={classNames('form-control', {
              'is-invalid': !!props.showInvalid,
            })}
            type="number"
            min={0}
            step={1}
            id={props.id + '-hours'}
            name="hours"
            value={formik.values.hours}
            onChange={(e) => {
              if (stringIsSimpleWholeNumber(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
              props.onBlur && props.onBlur(e);
            }}
          />
          <div className="input-group-append">
            <div className="input-group-text">h</div>
          </div>
        </div>
      </div>

      <div className="col">
        <div className="input-group">
          <input
            className={classNames('form-control', {
              'is-invalid': !!props.showInvalid,
            })}
            type="number"
            min={0}
            step={1}
            id={props.id + '-minutes'}
            name="minutes"
            value={formik.values.minutes}
            onChange={(e) => {
              if (stringIsSimpleWholeNumber(e.target.value)) {
                formik.handleChange(e);
              }
            }}
            onBlur={(e) => {
              formik.handleBlur(e);
              props.onBlur && props.onBlur(e);
            }}
          />
          <div className="input-group-append">
            <div className="input-group-text">min</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DurationInput;
