import * as React from 'react';
import { Modal, ModalBody } from 'reactstrap';

interface StaticModalNotificationProps {
  children?: any;
}

const StaticModalNotification: React.FC<StaticModalNotificationProps> = (props) => {
  return (
    <Modal isOpen={true} backdrop="static">
      <ModalBody>{props.children}</ModalBody>
    </Modal>
  );
};

export default StaticModalNotification;
