import * as React from 'react';
import * as joda from 'js-joda';
import { jodaNow, isTimeBetween } from '../../utils';
import { TimeRange } from '../../models/models';
import './NowMarker.scss';

interface NowMarkerProps {
  visibleTimeRange: TimeRange;
  hourRemHeight: number;
  now?: joda.LocalDateTime;
}

export const NowMarker: React.FC<NowMarkerProps> = (props) => {
  const now = props.now || jodaNow();

  if (!isTimeBetween(now.toLocalTime(), props.visibleTimeRange)) {
    return null;
  }

  const hoursFromTop =
    joda.Duration.between(props.visibleTimeRange.start, now.toLocalTime()).toMinutes() /
    joda.LocalTime.MINUTES_PER_HOUR;
  const markerHeightRem = 0.125;

  return (
    <div
      className="v-now-marker"
      style={{
        position: 'absolute',
        height: 0,
        border: `#ff7f6e solid ${markerHeightRem / 2}rem`,
        top: `${hoursFromTop * props.hourRemHeight - markerHeightRem / 2}rem`,
        left: 0,
        width: '100%',
      }}
    />
  );
};

export default NowMarker;
